// Describing the shape of the organization's slice of state
import {AsyncAction, AsyncActionStatus} from "../asyncAction/types";
import {Address} from "../../graphql/types";

export interface AdressesState {
  entities: Address[];
  loadStatus: AsyncActionStatus;
  error?: Error
}

export const LOAD_ADDRESS = 'LOAD_ADDRESS';
export const ADD_ADDRESS = 'ADD_ADDRESS';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';

type LoadAddressAction = AsyncAction<typeof LOAD_ADDRESS, Address>;

interface AddAddressAction {
  type: typeof ADD_ADDRESS;
  payload: Address
}

interface DeleteAddressAction {
  type: typeof DELETE_ADDRESS;
  id: string;
}

export type AddressActionTypes =
  LoadAddressAction |
  AddAddressAction |
  DeleteAddressAction;
