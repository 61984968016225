// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {IStackTokens, Stack} from "@fluentui/react";

/* Local */
import {
  Organization,
  useOrganizationDetailsWithDevicesQuery,
} from "../../graphql/types";
import Loading from "../../components/layout/loading";
import PageHeading from "../../components/layout/pageHeading";
import OrganizationCommandBar from "./organizationCommandBar";
import OrganizationProperties from "./organizationProperties";
import OrganizationDeviceList from "./organizationDeviceList";
import OrganizationLogoEdit from "./organizationLogoEdit";

// ----------------------------------------------------------------------------

interface IOrganizationDetailPageProps {
  organizationId?: string;
  history: any;
}

interface IPropsWithParameters extends RouteComponentProps<IOrganizationDetailPageProps> {
}

const stackTokens: IStackTokens = {
  childrenGap: 'm',
  padding: 's1'
};

const OrganizationDetailPage = (props: IPropsWithParameters) => {
  const {match} = props;
  const organizationId = match.params.organizationId!;
  const addPayment = match.path.endsWith("addpayment");
  const {loading, data, error, refetch} = useOrganizationDetailsWithDevicesQuery({
    variables: {
      id: organizationId
    }
  });

  if (loading) {
    return <Loading/>
  }

  if (error) {
    return <p>Error</p>
  }

  const organization = data?.organization as Organization;

  return <>
    <Stack tokens={stackTokens}>
      <PageHeading heading={organization.name}/>
      <OrganizationCommandBar organization={organization} refetch={refetch}  showAddPayment={addPayment}/>

        <Stack horizontal horizontalAlign={'space-between'} disableShrink={false}>
          <Stack.Item grow align={'start'}>
            <OrganizationProperties organization={organization}/>
          </Stack.Item>
          <Stack.Item align={'end'} >
              <OrganizationLogoEdit organization={organization} onLogoUpdated={() => refetch()}/>
          </Stack.Item>
        </Stack>

      <OrganizationDeviceList organization={organization}/>

    </Stack>
  </>
};

export default withRouter(OrganizationDetailPage);
