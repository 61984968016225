// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {Link, TextField} from "@fluentui/react";
import {useTranslation} from "react-i18next";
import { ITransitStopDataSourceConfiguration } from "pixon-component-core";

/* Local */
import DefaultDataSourceEditorGroup from "./defaultDataSourceEditorGroup";
import {IDataSourceEditorProps, useDataSourceConfiguration} from "./dataSourceEditor";

// ----------------------------------------------------------------------------

const TransitStopDataSourceEditorGroup = (props: IDataSourceEditorProps) => {
  const {dataSource, onChange} = props;
  const {t} = useTranslation();
  const [configuration, updateConfigurationProperty] = useDataSourceConfiguration<ITransitStopDataSourceConfiguration>(onChange, dataSource);

  return  <>

    <DefaultDataSourceEditorGroup
      showAssetsEditor={false}
      {...props}>

      <TextField
        label={t('component:transitStopDataSourceEditorGroup.stopIdLabel')}
        placeholder={t('component:transitStopDataSourceEditorGroup.stopIdPlaceholder')}
        autoFocus
        defaultValue={configuration ? configuration.stopId : undefined}
        onChange={(e, v) => {
          updateConfigurationProperty({stopId: v})
        }}/>

      <Link href='https://www.trafiklab.se/api/resrobot-reseplanerare/platsuppslag' target='_blank'>
        {t('component:transitStopDataSourceEditorGroup.stopIdDocumentation')} &#x3E;&#x3E;
      </Link>

      <TextField
        label={t('component:transitStopDataSourceEditorGroup.apiKeylLabel')}
        placeholder={t('component:transitStopDataSourceEditorGroup.apiKeylPlaceholder')}
        autoFocus
        defaultValue={configuration ? configuration.apiKey : undefined}
        onChange={(e, v) => {
          updateConfigurationProperty({apiKey: v})
        }}/>

      <Link href='https://www.trafiklab.se/api-nycklar' target='_blank'>
        {t('component:transitStopDataSourceEditorGroup.apiKeyDocumentation')} &#x3E;&#x3E;
      </Link>

    </DefaultDataSourceEditorGroup>

  </>
};

export default TransitStopDataSourceEditorGroup;
