// From https://www.carlrippon.com/fetch-with-async-await-and-typescript/
import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {
  ApolloClient,
  InMemoryCache
} from '@apollo/client'
import {getAccessToken, getApiBaseUrl} from "../../store/ui/reducers";
import { ApolloProvider } from "@apollo/react-hooks";

export interface PixonApiContextInterface {
  get(path: string): Promise<Response | undefined>;
  client: ApolloClient<InMemoryCache>;
}

interface PixonApiProviderOptions {
  children: React.ReactElement;
  baseUrl: string;
  client: ApolloClient<InMemoryCache>;
}

export const PixonApiContext = React.createContext<PixonApiContextInterface | null>(null);
export const usePixonApi = () => useContext(PixonApiContext)!;

export const PixonApiProvider = ({
                            children,
                            baseUrl,
                            client,
                            ...initOptions
                          }: PixonApiProviderOptions) => {

  // Hook into redux store information (important to load after useEffect)
  const accessToken = useSelector(getAccessToken);
  const apiBaseUrl = useSelector(getApiBaseUrl);

  const get = async (path: string): Promise<Response | undefined> => {
    try {
      return await fetch(apiBaseUrl + path, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    } catch (error) {
      console.error('Api get error', error);
    }
  };

  return (
      <PixonApiContext.Provider
        value={{
          get,
          client: client
        }}
      >
        <ApolloProvider client={client} >
          {children}
        </ApolloProvider>
      </PixonApiContext.Provider>
  );

};
