import {
  ADD_ORGANIZATION,
  DELETE_ORGANIZATION,
  LOAD_ORGANIZATIONS,
  OrganizationActionTypes,
  OrganizationsState, UPDATE_ORGANIZATION,
} from "./types";
import {AppState} from "../index";
import {AsyncActionStatus} from "../asyncAction/types";

const initialState: OrganizationsState = {
  entities: [],
  asyncStatus: AsyncActionStatus.UNSTARTED,
  error: undefined
};

// See https://dev.to/markusclaus/fetching-data-from-an-api-using-reactredux-55ao
export function organizationReducer(
  state = initialState,
  action: OrganizationActionTypes
): OrganizationsState {
  switch (action.type) {
    case LOAD_ORGANIZATIONS:
      switch (action.status) {
        case AsyncActionStatus.SUCCEEDED:
          return {
            ...state,
            asyncStatus: action.status,
            entities: action.payload
          };
        case AsyncActionStatus.FAILED:
          return {
            ...state,
            asyncStatus: action.status,
            error: action.payload
          };
        default:
          return {
            ...state,
            asyncStatus: action.status
          };
      }
    case ADD_ORGANIZATION:
      switch (action.status) {
        case AsyncActionStatus.SUCCEEDED:
          return {
            ...state,
            asyncStatus: action.status,
            entities: [...state.entities, action.payload]
          };
        case AsyncActionStatus.FAILED:
          return {
            ...state,
            asyncStatus: action.status,
            error: action.payload
          };
        default:
          return {
            ...state,
            asyncStatus: action.status
          };
      }
    case UPDATE_ORGANIZATION:
      switch (action.status) {
        case AsyncActionStatus.SUCCEEDED:
          return {
            ...state,
            asyncStatus: action.status,
            entities: [...state.entities.filter(o => o.id !== action.payload.id), action.payload]
          };
        case AsyncActionStatus.FAILED:
          return {
            ...state,
            asyncStatus: action.status,
            error: action.payload
          };
        default:
          return {
            ...state,
            asyncStatus: action.status
          };
      }
    case DELETE_ORGANIZATION:
      switch (action.status) {
        case AsyncActionStatus.SUCCEEDED:
          return {
            ...state,
            asyncStatus: action.status,
            entities: state.entities.filter(o => o.id !== action.payload.id)
          };
        case AsyncActionStatus.FAILED:
          return {
            ...state,
            asyncStatus: action.status,
            error: action.payload
          };
        default:
          return {
            ...state,
            asyncStatus: action.status
          };
      }
    default:
      return state;
  }
}

// Selectors
export const getOrganizations = (state:AppState) => state.organizations.entities;
export const getOrganizationsAsyncStatus = (state:AppState) => state.organizations.asyncStatus;
export const getOrganizationsError = (state:AppState) => state.organizations.error;
