import * as React from 'react';

import {
  BasePickerListBelow,
  IBasePickerProps,
  IPickerItemProps,
  ISuggestionItemProps,
  Label,
  DocumentCard,
  DocumentCardType,
  DocumentCardPreview,
  DocumentCardDetails,
  DocumentCardTitle, DocumentCardActions
} from "@fluentui/react";

import {useTranslation} from "react-i18next";
import {Asset, AssetType, useAssetsQuery} from "../graphql/types";
import {useState} from "react";
import {getAssetUrl} from "./api/util";

export interface IAssetPickerProps {
  onChange: (items?: Asset[]) => void;
  label?: string;
  allowDuplicateSelection?: boolean,
  assetTypes?: AssetType[],
  selectedItems?: Asset[] | null
}

export interface IAssetPickerInternalProps extends IBasePickerProps<Asset> {
}

class AssetPickerInternal extends BasePickerListBelow<Asset, IAssetPickerInternalProps> {
}

const AssetPicker = (props: IAssetPickerProps) => {
  const {
    onChange,
    label,
    selectedItems,
    allowDuplicateSelection = false,
    assetTypes = [AssetType.Image]
  } = props;
  const {t} = useTranslation();
  const [selectedAssets, setSelectedAssets] = useState<Asset[]>(selectedItems || []);

  const {loading, data, error} = useAssetsQuery({
    variables: {
      input: {
        assetTypes: assetTypes
      }
    }
  });

  let assets: Asset[] = data && !loading && !error
    ? data.assets as Asset[]
    : [];

  const RenderSuggestionsItem = (asset: Asset, itemProps: ISuggestionItemProps<any>) => {
    return <DocumentCard
      key={asset?.id || ''}
      type={DocumentCardType.compact}
      styles={{
        root: {
          width: '100%'
        }
      }}
    >
      <DocumentCardPreview previewImages={[{
        name: asset?.displayName || '',
        previewImageSrc: getAssetUrl(asset),
        width: 150
      }]}
      />
      <DocumentCardDetails>
        <DocumentCardTitle title={asset.displayName || ''} shouldTruncate={true}/>
      </DocumentCardDetails>
    </DocumentCard>
  };

  const RenderSelectedItem = (selectedItem: IPickerItemProps<Asset>) => {
    return <DocumentCard
      key={selectedItem?.item?.id || ''}
      type={DocumentCardType.compact}
      styles={{
      root: {
        marginBottom: 5
      }
      }}
    >
      <DocumentCardPreview previewImages={[{
        name: selectedItem?.item?.displayName || '',
        previewImageSrc: getAssetUrl(selectedItem.item),
        width: 144
      }]}
      />
      <DocumentCardDetails>
        <DocumentCardTitle title={selectedItem?.item?.displayName || ''} shouldTruncate={true}/>
        <DocumentCardActions
          actions={[
            {
              iconProps: { iconName: 'Delete' },
              onClick: () => { if (selectedItem.onRemoveItem) { selectedItem.onRemoveItem() } }
            }
          ]}
        />
      </DocumentCardDetails>
    </DocumentCard>
  };

  const onFilterChanged = (filterText: string, items?: Asset[]): Asset[] => {
    return filterText
      ? assets
        .filter(item => item && item?.displayName?.toLowerCase().includes(filterText.toLowerCase()))
        .filter(item => allowDuplicateSelection || !selectedAssets.includes(item))
      : [];
  };

  const getTextFromItem = (item: Asset) => item.displayName || '';

  return <>
    {label &&
    <Label>{label}</Label>
    }

    <AssetPickerInternal
      onRenderSuggestionsItem={RenderSuggestionsItem}
      onRenderItem={RenderSelectedItem}
      getTextFromItem={getTextFromItem}
      onResolveSuggestions={onFilterChanged}
      pickerSuggestionsProps={{
        suggestionsHeaderText: t('component:assetPicker.suggestionsHeaderText'),
        noResultsFoundText: t('component:assetPicker.noResultsFoundText')
      }}
      onChange={items => {
        setSelectedAssets(items || []);
        onChange(items);
      }}
      selectedItems={selectedAssets}
    />
  </>
};

export default AssetPicker;
