// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useTranslation} from "react-i18next";
import { IBarcodeReaderComponentConfiguration } from "pixon-component-core";

/* Local */
import {IComponentPropertyEditorProps, useComponentConfiguration} from "./propertyEditor";
import DefaultComponentEditorGroup from "./defaultComponentEditorGroup";
import {DataSourceTypeEnum} from "../../../graphql/types";
import {Checkbox, Link, TextField} from "@fluentui/react";

// ----------------------------------------------------------------------------

const BarcodeReaderComponentEditorGroup = (props: IComponentPropertyEditorProps) => {
  const {pageComponent, onChange} = props;
  const component = pageComponent.component!;
  const {t} = useTranslation();
  const [configuration, updateConfigurationProperty] = useComponentConfiguration<IBarcodeReaderComponentConfiguration>(onChange, component);

  return <>

    <DefaultComponentEditorGroup
      showDataSourceEditor={true}
      dataSourceTypes={[DataSourceTypeEnum.DeviceEvent]}
      showCssEditor={false}
      showTemplateAssetEditor={false}
      heading={t('component:barcodeReaderComponentEditorGroup.heading')}
      {...props}>

      <TextField
        label={t('component:barcodeReaderComponentEditorGroup.readersLabel')}
        placeholder={t('component:barcodeReaderComponentEditorGroup.readersPlaceholder')}
        value={configuration && configuration.readers ? configuration.readers.join(', ') : 'code_128_reader'}
        onChange={(e, v) => updateConfigurationProperty({
          readers: v?.split(',')
        })}
      />

      <Link href='https://serratus.github.io/quaggaJS/' target='_blank'>
        {t('component:barcodeReaderComponentEditorGroup.readersReadMore')} &#x3E;&#x3E;
      </Link>

      <Checkbox
        label={t('component:barcodeReaderComponentEditorGroup.multipleLabel')}
        onChange={(ev: any, value?: boolean) => updateConfigurationProperty({multiple: value})}
      />

      <Checkbox
        label={t('component:barcodeReaderComponentEditorGroup.drawBoundingBoxLabel')}
        onChange={(ev: any, value?: boolean) => updateConfigurationProperty({drawBoundingBox: value})}
      />

      <Checkbox
        label={t('component:barcodeReaderComponentEditorGroup.drawScanlineLabel')}
        onChange={(ev: any, value?: boolean) => updateConfigurationProperty({drawScanline: value})}
      />

      <Checkbox
        label={t('component:barcodeReaderComponentEditorGroup.showFrequencyLabel')}
        onChange={(ev: any, value?: boolean) => updateConfigurationProperty({showFrequency: value})}
      />

      <Checkbox
        label={t('component:barcodeReaderComponentEditorGroup.showPatternLabel')}
        onChange={(ev: any, value?: boolean) => updateConfigurationProperty({showPattern: value})}
      />

    </DefaultComponentEditorGroup>

  </>
};

export default BarcodeReaderComponentEditorGroup;
