import * as React from "react";
import {withRouter} from 'react-router-dom';
import {Nav, Customizer, Text} from "@fluentui/react";
import { createTheme, ITheme } from '@uifabric/styling';
import {useTranslation} from 'react-i18next';

export const navBarTheme: ITheme = createTheme({
  palette: {
    themePrimary: '#ffffff',
    themeDarker: '#aad6ed',
    neutralLighter: '#5fb0dc',
    neutralPrimary: '#ffffff',
    themeDarkAlt: '#ffffff',
    neutralLighterAlt: '#2190cb',
    neutralLight: '#2190cb',
    white: '#1a71b8',
  }
});

const styles = {
  root: [
    {
      color: '#ffffff'
    }
  ]
};

const NavBar = withRouter(({history}) => {
  const {t } = useTranslation();
  const _linkClickHandler = (event: any, element: any) => {
    if (element.url.startsWith('/')) {
      event.preventDefault();
      history.push(element.url);
    }
  };

  const NavBarItems = [
    {
      name: t('common:navbar.group.home'),
      links: [
        {
          key: "Home",
          name: t('common:navbar.content.home'),
          url: "/home",
          icon: "Home"
        }
      ]
    },
    {
      name: t('common:navbar.group.devices'),
      links: [
        {
          key: "ManageDevices",
          name: t('common:navbar.devices.managedevices'),
          url: "/devices",
          icon: "ScreenCast"
        }

      ]
    },
    {
      name: t('common:navbar.group.content'),
      links: [
        {
          key: "Assets",
          name: t('common:navbar.content.assets'),
          url: "/assets",
          icon: "FileImage"
        },
        {
          key: "DataSources",
          name: t('common:navbar.content.dataSources'),
          url: "/datasources",
          icon: "Database"
        }
      ]
    },
    {
      name: t('common:navbar.group.organizations'),
      links: [
        {
          key: "ManageOrganizations",
          name: t('common:navbar.organizations.manageorganizations'),
          url: "/organizations",
          icon: "Org"
        }
      ]
    },
    {
      name: t('common:navbar.group.about'),
      links: [
        {
          key: "Documentation",
          name: t('common:navbar.about.documentation'),
          url: "https://docs.pixon.app",
          href: "https://docs.pixon.app",
          target: '_blank',
          icon: "DocumentSearch"
        },
        {
          key: "Support",
          name: t('common:navbar.about.support'),
          url: "mailto:info@rduce.net",
          icon: "UnknownSolid",
          href: "mailto:support@rduce.net"
        }
      ]
    }
  ];

  return (
    <Customizer settings={{theme: navBarTheme}}>
      <Nav onLinkClick={_linkClickHandler} groups={NavBarItems} styles={{
        linkText: {
          color: 'white'
        }
      }} />
      <Text block styles={styles}>Copyright &copy; Rduce Complexity AB</Text>
    </Customizer>
  )

});

export default NavBar;
