import {ValidationSchema} from "class-validator";
export let LinkDeviceValidationSchema: ValidationSchema = {
  name: "linkDeviceValidationSchema",
  properties: {
    displayName: [{
      type: "isNotEmpty",
      message: 'devices:validation.displaynameisnotempty',
    }],
    linkCode: [{
      type: "isNotEmpty",
      message: 'devices:validation.linkcodeisnotempty',
    }],
    organizationId: [{
      type: "isNotEmpty",
      message: 'devices:validation.organizationidisnotempty',
    }]
  }
};
