import {Clipboard} from 'ts-clipboard';
import React from "react";
import {IconButton} from "@fluentui/react";

interface ClipBoardButtonProps {
  value: string, // Change the required prop to an optional prop.
}

const ClipBoardButton = (props: ClipBoardButtonProps) =>
  <IconButton onClick={() => Clipboard.copy(props.value)} iconProps={{ iconName: 'Copy' }} title="Copy to clipboard" ariaLabel="Copy to clipboard" />;

export default  ClipBoardButton;
