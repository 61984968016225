// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";

import logo from "../../assets/img/logo-text.svg";

  // ----------------------------------------------------------------------------

// Layout sections
import {Image, MessageBar, MessageBarButton, MessageBarType} from "@fluentui/react";
import {RouteComponentProps} from "react-router";
import {withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface IOauthIndexProps extends RouteComponentProps {
}

// Images
const OauthIndexPage = (props: IOauthIndexProps) => {
  const {location} = props;
  const {t} = useTranslation();
  const resultIsSuccess = new URLSearchParams(location.search).get("result") === "success";
  const messageBarType = resultIsSuccess ? MessageBarType.success : MessageBarType.error;

  return <div className='center'>
    <Image src={logo} />
    <div style={{
        textAlign: "center",
        padding: "2em"
    }}>
      <MessageBar
        actions={
          <div>
            <MessageBarButton onClick={() => window.close()}>{t('common:oauthConnectionCloseWindowButton')}</MessageBarButton>
          </div>
        }
        messageBarType={messageBarType}
        isMultiline={true}
      >

        {resultIsSuccess ? t('common:oauthConnectionSuccessMessage') : t('common:oauthConnectionErrorMessage')}
      </MessageBar>
    </div>
  </div>
};

export default  withRouter(OauthIndexPage);
