import {ValidationSchema} from "class-validator";

export let UpdateDeviceValidationSchema: ValidationSchema = {
  name: "updateDeviceValidationSchema",
  properties:  {
    displayName: [{
      type: 'isNotEmpty',
      message: 'devices:validation.displaynameisnotempty',
    }]
  }
};
