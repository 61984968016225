import * as React from 'react';

import {
  ComboBox,
  IComboBoxOption, SelectableOptionMenuItemType,
} from "@fluentui/react";
import {getKeyByValue} from "./api/util";
import {useTranslation} from "react-i18next";

export interface IFontComboboxProps {
  label?: string,
  defaultSelectedFont?: string,
  onChange: (fontFamily: string) => void;
}

const FontCombobox = (props: IFontComboboxProps) => {
  const _fontMapping: { [fontName: string]: string } = {
    'Arial': 'Arial, Helvetica, sans-serif',
    'Arial Black': '"Arial Black", Gadget, sans-serif',

    'Bookman': '"Bookman", serif',

    'Candara': '"Candara", sans-serif',
    'Calibri': 'Calibri, Calibri_MSFontService, sans-serif',
    'Comic Sans MS': '"Comic Sans MS", cursive, sans-serif',
    'Courier': '"Courier New", Courier, monospace',

    'Georgia': '"Georgia", serif',
    'Garamond': '"Garamond", serif',

    'Impact': 'Impact, Charcoal, sans-serif',

    'Lucida': '"Lucida Sans Unicode", "Lucida Grande", sans-serif',
    'Lucida Console': '"Lucida Console", Monaco, monospace',

    'Palatino': '"Palatino Linotype", "Book Antiqua", Palatino, serif',

    'Roboto': '"Roboto", sans-serif',

    'Tahoma': 'Tahoma, Geneva, sans-serif',
    'Times': 'Times, "Times New Roman", serif',
    'Times New Roman': '"Times New Roman", Times, serif',

    'Verdana': 'Verdana, Geneva, sans-serif',
  };

  const fonts = Object.keys(_fontMapping);

  const {
    label,
    defaultSelectedFont = _fontMapping['Calibri'],
    onChange
  } = props;
  const {t} = useTranslation();
  let defaultFontKey = getKeyByValue(_fontMapping, defaultSelectedFont);

  const _optionsForCustomRender = [
    // Default font options are listed under this heading
    { key: 'header1', text: t('common:fontCombobox.builtInHeader'), itemType: SelectableOptionMenuItemType.Header },
    ...fonts.map((fontName: string) => ({ key: fontName, text: fontName }))
  ];

  const _onRenderOption = (item?: IComboBoxOption) => {
    if (!item)
      return null;

    if (item.itemType === SelectableOptionMenuItemType.Header || item.itemType === SelectableOptionMenuItemType.Divider) {
      return <span>{item.text}</span>;
    }

    let fontFamily = _fontMapping[item.text];

    if (!fontFamily) {
      // This is a new user-entered font. Add a font family definition for it.
      const newFontName = item.text;
      fontFamily = _fontMapping[newFontName] = `"${newFontName}","Segoe UI",Tahoma,Sans-Serif`;
    }

    return <span style={{ fontFamily: fontFamily }}>{item.text}</span>;
  };

  return <ComboBox
    selectedKey={defaultFontKey}
    label={label}
    allowFreeform={false}
    autoComplete="on"
    options={_optionsForCustomRender}
    onRenderOption={_onRenderOption}
    onChange={(event, option) => {
      onChange(_fontMapping[option?.key?.toString() || ''])
    }}
  />;
};

export default FontCombobox;
