import {Component, PageComponent} from "../../../graphql/types";
import {Dispatch, SetStateAction, useEffect, useState} from "react";

export interface IComponentPropertyEditorProps {
  pageComponent: PageComponent,
  onChange: (partiallyUpdatedComponent: Partial<Component>) => void
  onPageComponentChange: (partiallyUpdatedComponent: Partial<PageComponent>) => void
}

type PartialUpdateAction<T> = (update: Partial<T>) => void;
type PartialComponentUpdateAction = PartialUpdateAction<Component>;

export function useComponentConfiguration<T>(onChange: PartialComponentUpdateAction, component?: Component | null )
  : [T, PartialUpdateAction<T>, Dispatch<SetStateAction<T>>] {
  const [configuration, setConfiguration] = useState<T>(
    component && component.configuration ? JSON.parse(component.configuration) : undefined);

  useEffect(() => {
    if (!configuration)
      return;

    const serialized = JSON.stringify(configuration, null, 2);
    onChange({
      configuration: serialized
    });
  }, [onChange, configuration]);

  const updateConfigurationProperty = (partialUpdate: Partial<T>) => {
    setConfiguration((prevState:T) => {
      return {
        ...prevState,
        ...partialUpdate
      }
    });
  };

  return [configuration, updateConfigurationProperty, setConfiguration];
}
