// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {Asset} from "../../../graphql/types";
import AssetsDropDown from "../../../components/assetsDropDown";
import DefaultComponentEditorGroup from "./defaultComponentEditorGroup";
import {IComponentPropertyEditorProps} from "./propertyEditor";
import {useTranslation} from "react-i18next";

/* Local */

// ----------------------------------------------------------------------------

const ImageComponentEditorGroup = (props: IComponentPropertyEditorProps) => {
  const {pageComponent, onChange } = props;
  const component = pageComponent.component!;
  const {t} = useTranslation();

  return <>

    <DefaultComponentEditorGroup
      showDataSourceEditor={false}
      showCssEditor={false}
      heading={t('component:ImageComponentEditorGroup.heading')}
      {...props}>

      <AssetsDropDown
        label={t('component:ImageComponentEditorGroup.assetLabel')}
        onChange={(a) => {
          onChange({ assets: [a] })
        }}
        assetTypes={['IMAGE']}
        selected={component.assets ? component.assets[0] as Asset : undefined}
      />
    </DefaultComponentEditorGroup>


  </>
};

export default ImageComponentEditorGroup;
