import {
  ApolloClient,
  InMemoryCache
} from '@apollo/client';

export interface AuthConfig {
  baseUrl: string,
  realm: string,
  clientId: string
}

export interface AuthSession {
  userId: string,
  accessToken: string,
  scope: string,
  permissions: string[]
}

export interface GlobalUiState {
  language: string,
  apiBaseUrl: string,
  applicationInsightsKey: string,
  graphQlClient?: ApolloClient<InMemoryCache>,
  auth: {
      config: AuthConfig,
      session: AuthSession
  }
}

export interface UiState {
  global: GlobalUiState
}

// Describing the different ACTION NAMES available
export const SET_UI_LANGUAGE = "SET_UI_LANGUAGE";
export const SET_UI_CONFIG = "SET_UI_CONFIG";
export const SET_UI_AUTH_CONFIG = "SET_UI_AUTH_CONFIG";
export const SET_UI_AUTH_SESSION = "SET_UI_AUTH_SESSION";
export const SET_UI_GRAPHQL_CLIENT = "SET_UI_GRAPHQL_CLIENT";

export interface SetUiAction {
  type: typeof SET_UI_LANGUAGE;
  payload: string;
}

export interface SetUiConfigAction {
  type: typeof SET_UI_CONFIG;
  payload: {
    apiBaseUrl: string,
    applicationInsightsKey: string
  };
}

export interface SetUiAuthConfigAction {
  type: typeof SET_UI_AUTH_CONFIG;
  payload: AuthConfig;
}

export interface SetUiAuthSessionAction {
  type: typeof SET_UI_AUTH_SESSION;
  payload: AuthSession;
}

export interface SetUiGraphQlAction {
  type: typeof SET_UI_GRAPHQL_CLIENT;
  payload: ApolloClient<InMemoryCache>;
}

export type UiActionTypes =
  SetUiAction |
  SetUiConfigAction |
  SetUiAuthConfigAction |
  SetUiAuthSessionAction |
  SetUiGraphQlAction;
