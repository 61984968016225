// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {Dropdown} from "@fluentui/react";
import {useTranslation} from "react-i18next";
import { IClockDataSourceConfiguration } from "pixon-component-core";
import moment from 'moment-timezone';

/* Local */
import DefaultDataSourceEditorGroup from "./defaultDataSourceEditorGroup";
import {IDataSourceEditorProps, useDataSourceConfiguration} from "./dataSourceEditor";

// ----------------------------------------------------------------------------

const timeZoneOptions = moment.tz.names().map(name => {
  return {
  key: name,
  text: name
}});

const ClockDataSourceEditorGroup = (props: IDataSourceEditorProps) => {
  const {dataSource, onChange} = props;
  const {t} = useTranslation();
  const [configuration, updateConfigurationProperty] = useDataSourceConfiguration<IClockDataSourceConfiguration>(onChange, dataSource);

  return  <>

    <DefaultDataSourceEditorGroup
      showAssetsEditor={false}
      {...props}>

    <Dropdown
      label={t('dataSources:clockDataSourceEditorGroup.timeZoneLabel')}
      placeholder={t('dataSources:clockDataSourceEditorGroup.timeZonePlaceHolder')}
      options={timeZoneOptions}
      selectedKey={configuration ? configuration.timeZone : undefined}
      onChange={(e, o) =>  updateConfigurationProperty({
        timeZone: o?.key as string
          })
      } />


    </DefaultDataSourceEditorGroup>

  </>
};

export default ClockDataSourceEditorGroup;
