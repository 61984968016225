// Should be in sync with
// Permissions in Auth0 app Pixon Api - https://manage.auth0.com/dashboard/eu/rduce/apis/5c87fc74c371714c89fdd18e/permissions
// Permissions in Auth0 app Pixon Test Api- https://manage.auth0.com/dashboard/eu/rduce/apis/5d14ced174c1fc067f4e3a9b/permissions
// Constants in Api (Permissions.cs) - https://dev.azure.com/rduce/RducePixon/_git/PixonApi?path=%2FApplication%2FRduce.Pixon.Api%2FAuth%2FPermissions.cs&version=GBmaster

export enum Permission {
  Profile = "profile",
  Email = "email",
  ReadApiConfiguration = "read:apiconfiguration",
  ReadOrganizations = "read:organizations",
  ReadUsers = "read:users",
  ViewDebugPage = "view:debugpage",
  ReadAuditEntries = "read:audit"
}

export default Permission;
