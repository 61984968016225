// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {
  ChoiceGroup
} from "@fluentui/react";

/* Local */
import {IComponentPropertyEditorProps, useComponentConfiguration} from "./propertyEditor";
import DefaultComponentEditorGroup from "./defaultComponentEditorGroup";
import {DataSourceTypeEnum} from "../../../graphql/types";

// ----------------------------------------------------------------------------

// Defaults
interface INameOfDayConfiguration {
  displayType: DisplayType
}

enum DisplayType {
  List = "List",
  String = "String"
}


const NameOfDayComponentEditorGroup = (props: IComponentPropertyEditorProps) => {
  const {pageComponent, onChange} = props;
  const component = pageComponent.component!;
  const [configuration, updateConfigurationProperty] = useComponentConfiguration<INameOfDayConfiguration>(onChange, component);
  return <>

    <DefaultComponentEditorGroup
      showDataSourceEditor={true}
      dataSourceTypes={[DataSourceTypeEnum.NameOfDay]}
      showCssEditor={true}
      showTemplateAssetEditor={true}
      {...props}>

      <ChoiceGroup
        label="Visa som"
        defaultSelectedKey={configuration?.displayType ?? DisplayType.List}
        onChange={(ev, option) => updateConfigurationProperty({displayType: option?.key as DisplayType})}
        options={[
          {
            key: DisplayType.List,
            iconProps: { iconName: 'BulletedList' },
            text: 'List'
          },
          {
            key: DisplayType.String,
            iconProps: { iconName: 'TextOverflow' },
            text: 'Comma separated'
          }
        ]}
      />

    </DefaultComponentEditorGroup>

  </>
};

export default NameOfDayComponentEditorGroup;
