// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {
  Checkbox,
  Slider
} from "@fluentui/react";
import {useTranslation} from "react-i18next";
import {ISnowFlakeEffectComponentConfiguration} from 'pixon-component-core';

/* Local */
import {IComponentPropertyEditorProps, useComponentConfiguration} from "./propertyEditor";
import DefaultComponentEditorGroup from "./defaultComponentEditorGroup";
import ColorPickerButton from "../../../components/colorPickerButton";

// ----------------------------------------------------------------------------

const SnowFlakeEffectComponentEditorGroup = (props: IComponentPropertyEditorProps) => {
  const {pageComponent, onChange} = props;
  const component = pageComponent.component!;
  const {t} = useTranslation();
  const [configuration, updateConfigurationProperty] = useComponentConfiguration<ISnowFlakeEffectComponentConfiguration>(onChange, component);

  return <>

    <DefaultComponentEditorGroup
      showDataSourceEditor={false}
      showCssEditor={false}
      showPositionEditor={false}
      showTemplateAssetEditor={false}
      heading={t('component:snowFlakeEffectComponentEditorGroup.heading')}
      {...props}>


      <ColorPickerButton
        label={t('component:snowFlakeEffectComponentEditorGroup.colorLabel')}
        color={configuration?.color ?? 'white'}
        onChange={c => updateConfigurationProperty( {
          color: c.str
        })}
      />

      <Slider
        label={t('component:snowFlakeEffectComponentEditorGroup.count')}
        min={1}
        max={200}
        step={1}
        defaultValue={configuration?.count ?? 50}
        showValue={true}
        valueFormat={(value: number) => `${value}`}
        onChange={(value: number) => updateConfigurationProperty({count: value})}
      />

      <Slider
        label={t('component:snowFlakeEffectComponentEditorGroup.maxSize')}
        min={1}
        max={200}
        step={1}
        defaultValue={configuration?.maxSize ?? 18}
        showValue={true}
        valueFormat={(value: number) => `${value}`}
        onChange={(value: number) => updateConfigurationProperty({maxSize: value})}
      />

      <Slider
        label={t('component:snowFlakeEffectComponentEditorGroup.minSize')}
        min={1}
        max={200}
        step={1}
        defaultValue={configuration?.minSize ?? 8}
        showValue={true}
        valueFormat={(value: number) => `${value}`}
        onChange={(value: number) => updateConfigurationProperty({minSize: value})}
      />

      <Slider
        label={t('component:snowFlakeEffectComponentEditorGroup.speed')}
        min={0.1}
        max={5}
        step={0.1}
        defaultValue={configuration?.speed ?? 1}
        showValue={true}
        valueFormat={(value: number) => `${value}`}
        onChange={(value: number) => updateConfigurationProperty({speed: value})}
      />

      <Checkbox
        label={t('component:snowFlakeEffectComponentEditorGroup.rotation')}
        onChange={(ev, checked) => updateConfigurationProperty( {
          rotation: checked
        })}
      />

      <Checkbox
        label={t('component:snowFlakeEffectComponentEditorGroup.wind')}
        onChange={(ev, checked) => updateConfigurationProperty( {
          wind: checked
        })}
      />

    </DefaultComponentEditorGroup>

  </>
};

export default SnowFlakeEffectComponentEditorGroup;
