import * as React from 'react';
import {
  DocumentCard,
  DocumentCardActivity,
  DocumentCardTitle,
  DocumentCardDetails,
  DocumentCardImage,
  IDocumentCardStyles,
  IDocumentCardActivityPerson
} from '@fluentui/react/lib/DocumentCard';
import { ImageFit } from '@fluentui/react/lib/Image';
import { TestImages } from '@fluentui/react/lib/common/TestImages';
import PageHeading from "../../components/layout/pageHeading";
import {Stack} from '@fluentui/react';

const people: IDocumentCardActivityPerson[] = [
  { name: 'Annie Lindqvist', profileImageSrc: TestImages.personaFemale },
  { name: 'Roko Kolar', profileImageSrc: '', initials: 'RK' },
  { name: 'Aaron Reid', profileImageSrc: TestImages.personaMale },
  { name: 'Christian Bergqvist', profileImageSrc: '', initials: 'CB' }
];

const tokens = {
  tenGapStack: {
    childrenGap: 10
  }
};


export const ExtensionsPageIndex: React.StatelessComponent = () => {
  const cardStyles: IDocumentCardStyles = {
    root: { display: 'inline-block', marginRight: 20, marginBottom: 20, width: 320 }
  };

  return (
      <div>
        <PageHeading heading="Integrations"/>

        <Stack horizontal padding={20} tokens={tokens.tenGapStack}>

        <DocumentCard styles={cardStyles} >
          <DocumentCardImage height={150} imageFit={ImageFit.cover} imageSrc={TestImages.documentPreviewTwo} />
          <DocumentCardDetails>
            <DocumentCardTitle title="BokaMera Booking System Integration" shouldTruncate />
          </DocumentCardDetails>
          <DocumentCardActivity activity="Added January 1, 2019" people={[people[3]]} />
        </DocumentCard>

        <DocumentCard styles={cardStyles} >
          <DocumentCardImage
              height={150}
              imageFit={ImageFit.cover}
              iconProps={{
                iconName: 'OneNoteLogo',
                styles: { root: { color: '#813a7c', fontSize: '120px', width: '120px', height: '120px' } }
              }}
          />
          <DocumentCardDetails>
            <DocumentCardTitle title="RCO Lock Integration" shouldTruncate />
          </DocumentCardDetails>
          <DocumentCardActivity activity="Added January 1, 2019" people={[people[3]]} />
        </DocumentCard>

        </Stack>
      </div>
  );
};

export default ExtensionsPageIndex;
