// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {
  Image,
  ImageFit,
} from "@fluentui/react";
import {Asset, Organization, useUpdateOrganizationMutation} from "../../graphql/types";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import UploadAssetComponent, {UploadAssetHandles} from "../../components/uploadAssetComponent";
import {getAssetUrl} from "../../components/api/util";

/* Local */

// ----------------------------------------------------------------------------

interface IOrganizationLogoEditProps {
  organization: Organization,
  onLogoUpdated?: (logo: Asset) => void
}

const OrganizationLogoEdit = (props: IOrganizationLogoEditProps) => {
  const {organization, onLogoUpdated} = props;
  const {t} = useTranslation();
  const [logoAsset, setLogoAsset] = useState<Asset>();
  const [url, setUrl] = useState<string>();
  const [updateOrganization] = useUpdateOrganizationMutation();
  const uploadRef = useRef<UploadAssetHandles>(null);

  useEffect(() => {
    if (organization.logotype) {
      setLogoAsset(organization.logotype);
    }
  }, [organization]);

  const setOrganizationLogo = async (logo:Asset) => {
    const options = {
      variables: {
        input: {
          id: organization.id,
          name: organization.name,
          vatNumber: organization.vatNumber,
          organizationNumber: organization.organizationNumber,
          billingEmail: organization.billingEmail,
          contactEmail: organization.contactEmail,
          phoneNumber: organization.phoneNumber,
          url: organization.url,
          logotype: {
            id: logo.id,
            filename: logo.filename,
            size: logo.size,
            mimeType: logo.mimeType
          }
        }
      }
    };

    await updateOrganization(options);
    setLogoAsset(logo);

    if (onLogoUpdated)
      onLogoUpdated(logo);
  };

  useEffect(() => {
    if (logoAsset) {
      // Trigger reload of logo
      const hash = '#' + Date.now();
      setUrl(getAssetUrl(logoAsset) + hash);
    }
  }, [logoAsset, onLogoUpdated]);

  const width = 160;
  const height = 120;

  return <div>
    {logoAsset &&
      <Image
          width={width}
          height={height}
          imageFit={ImageFit.centerContain}
          src={url}
          onClick={() => {
            // upload new logo
            console.log('dropzoneref', uploadRef);
            if (uploadRef.current)
              uploadRef.current.open();
          }}/>
    }

    <UploadAssetComponent
      organizationId={organization.id!}
      acceptedMimeTypes={'image/jpeg, image/png, image/svg+xml'}
      acceptMessage={t('organizations:organizationLogoEdit.acceptedfiles')}
      message={t('organizations:organizationLogoEdit.message')}
      uploadFinishedCallback={asset => setOrganizationLogo(asset)}
      ref={uploadRef}
      display={logoAsset ? 'none' : 'flex'}
    />
  </div>
};

export default OrganizationLogoEdit;
