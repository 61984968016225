// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {
  Image,
  Text,
  Stack,
  SearchBox,
  Nav, Callout, DirectionalHint, IStackTokens, INavLink, INavLinkGroup,
} from "@fluentui/react";

import {
  Component, TagType,
  useComponentPickerComponentsQuery,
  useComponentPickerTagsQuery
} from "../../graphql/types";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getAssetUrl, isEmpty } from "../../components/api/util";

/* Local */

// ----------------------------------------------------------------------------

interface IPageComponentPickerProps {
  height?: number
  addComponent: (component: Component) => void;
}

const calloutTokens: IStackTokens = {
  childrenGap: 'm',
  padding: 's1'
};

const ComponentTreePicker = (props: IPageComponentPickerProps) => {
  const { height, addComponent } = props;
  const { t } = useTranslation();

  const { loading, data, error } = useComponentPickerTagsQuery({
    variables: {
      input: {
        tagTypes: [TagType.ComponentCategory]
      }
    }
  });

  const componentsQuery = useComponentPickerComponentsQuery({
    variables: {
      input: {
        isTemplate: true
      }
    }
  });

  const [isCalloutVisible, setIsCalloutVisible] = useState(false);
  const [calloutTargetElement, setCalloutTargetElement] = useState<any>();
  const [hovered, setHovered] = useState<any>();
  const [filter, setFilter] = useState<any>();

  const [filteredNodes, setFilteredNodes] = useState<INavLinkGroup[] | null | undefined>();
  const [treeNodes, setTreeNodes] = useState<any>();

  useEffect(() => {
    if (!treeNodes)
      return;

    const filterNavlist = (values: any[]): any[] => {
      if (!values) {
        return [];
      }

      return values
        .filter(v => {
          const filteredLinks = filterNavlist(v.links);
          return !filter
            || (filteredLinks && filteredLinks.length > 0)
            || (v.name && v.name.toLowerCase().indexOf(filter) >= 0)
        }).map(v => {
          const filteredLinks = filterNavlist(v.links);
          return filteredLinks && filteredLinks.length > 0
            ? {
              ...v,
              links: filteredLinks
            } : v;
        });
    };

    setFilteredNodes(filterNavlist(treeNodes));
  }, [filter, treeNodes]);

  useEffect(() => {
    if (!data || !componentsQuery.data)
      return;

    const tags = data.tags;
    const components = componentsQuery.data.components;

    const nameComparer = (a: any, b: any) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    };

    const getComponents = (parentTag: any): any[] => {
      return components
        .filter(c => {
          return c?.tags?.some(tag => tag?.id === parentTag.id);
        })
        .map(c => {
          return {
            name: t(c?.nameTranslationKey!),
            icon: c?.iconName,
            isExpanded: true,
            dataObj: c
          }
        })
        .sort(nameComparer);
    };

    const getLinks = (parentTag: any): any[] => {
      return tags
        .filter(tag => {
          return tag?.parentId === parentTag.id;
        })
        .map(tag => {

          if (!tag)
            return null;

          const tagComponents = getComponents(tag);
          const links = getLinks(tag);
          const subTagsAndComponents = links.concat(tagComponents);
          const translation = t(tag.nameTranslationKey as string);

          return {
            name: translation,
            links: subTagsAndComponents,
            isExpanded: true,
            dataObj: tag
          }
        })
        .sort(nameComparer);
    };


    const rootLinks = [
      {
        links: tags
          .filter(tag => {
            return isEmpty(tag?.parentId);
          })
          .map(tag => {
            if (!tag) {
              return null;
            }

            return {
              name: t(tag.nameTranslationKey as string),
              links: getLinks(tag),
              isExpanded: true,
              dataObj: tag
            };
          })
          .sort(nameComparer)
      }
    ];

    setTreeNodes(rootLinks);
  }, [t, data, componentsQuery.data]);

  const renderLink = (link?: INavLink, defaultRender?: any) => {
    return <div
      onMouseEnter={event => {
        setHovered(link?.dataObj);
        setCalloutTargetElement(event.target);
        setIsCalloutVisible(true)
      }}
      onMouseLeave={() => {
        setCalloutTargetElement(undefined);
        setIsCalloutVisible(false)
      }}
    >
      {defaultRender(link)}
    </div>;
  };

  if (loading || error || !data)
    return null;

  return <div>
    <SearchBox
      placeholder="Sök komponent"
      underlined={true}
      onChange={(e, v) => setFilter(v ? v.toLowerCase() : undefined)}
    />

    <Nav
      groups={filteredNodes ?? null}
      onLinkClick={(ev, item) => {
        if (!item || !item.dataObj || !item.dataObj.componentType)
          return;
        addComponent(item.dataObj as Component);
      }}
      onRenderLink={renderLink}
      styles={{
        root: {
          width: 250,
          height: height
        }
      }}

    />

    <Callout
      gapSpace={0}
      target={calloutTargetElement}
      onDismiss={() => setIsCalloutVisible(false)}
      setInitialFocus={true}
      hidden={!isCalloutVisible}
      directionalHint={DirectionalHint.rightCenter}
      calloutMaxWidth={400}
    >
      <Stack tokens={calloutTokens} horizontalAlign={"center"}>
        {hovered && hovered.previewImage &&
          <Image src={getAssetUrl(hovered.previewImage)} />
        }
        <Text>{hovered && hovered.descriptionTranslationKey ? t(hovered.descriptionTranslationKey) : 'no description'}</Text>
      </Stack>
    </Callout>

  </div>

};

export default ComponentTreePicker;
