import * as React from 'react';

import {
  TextField,
  Stack,
  StackItem
} from "@fluentui/react";

import {useTranslation} from "react-i18next";
import ClipBoardButton from "./layout/clipBoardButton";

export interface IIdfield {
  id?: string | null
  label?: string
}

const IdField = (props: IIdfield) => {
  const { t } = useTranslation();
  const {
    id,
    label = t('common:idField.label'),
  } = props;

  return <Stack horizontal verticalAlign={"end"} horizontalAlign={"space-between"} >
    <StackItem align={"stretch"} grow>
      <TextField
        label={label}
        readOnly={true}
        value={id ?? undefined}
      />
    </StackItem>

    <StackItem>
      <ClipBoardButton value={id ?? ''}/>
    </StackItem>
  </Stack>
};

export default IdField;
