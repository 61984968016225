import * as React from 'react';

import {
  IBasePickerSuggestionsProps, IPersonaProps, NormalPeoplePicker, ValidationState
} from "@fluentui/react";

import {useTranslation} from "react-i18next";
import {User, useUsersQuery} from "../graphql/types";
import {useEffect, useState} from "react";

export interface IUserPickerProps {
  onChange: (items: User[]) => void;
}



const UserPicker = (props: IUserPickerProps) => {
  const {
    onChange
  } = props;

  const {t} = useTranslation();
  const {loading, data, error} = useUsersQuery();

  let users = [] as User[];
  if (!loading && !error && data) {
    users = (data.users as User[]);
  }

  const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: t('common:userPicker.suggestionsHeaderText'), //'Suggested People'
    mostRecentlyUsedHeaderText: t('common:userPicker.mostRecentlyUsedHeaderText'), // 'Suggested Contacts',
    noResultsFoundText: t('common:userPicker.noResultsFoundText'), //'No results found',
    loadingText: t('common:userPicker.loadingText'), //'Loading',
    showRemoveButtons: true,
    suggestionsAvailableAlertText: t('common:userPicker.suggestionsAvailableAlertText'), //'People Picker Suggestions available',
    suggestionsContainerAriaLabel: t('common:userPicker.suggestionsContainerAriaLabel'), //'Suggested contacts'
  };

  const usersToPersonas = (userList:User[]): IPersonaProps[] =>
    userList.map(u => {
      return {
        imageUrl: u.picture,
        isValid: true,
        key: u.externalUserId,
        //presence: 2,
        optionalText: u.email,
        secondaryText: `${u.email} (${u.externalUserId})`,
        //tertiaryText: "In a meeting",
        text: u.fullName
      } as IPersonaProps
    });

  const personasToUsers = (personas: any[]): User[] =>
    personas.map(p => users.find(u => u.externalUserId === p.key!)!);

  const [peopleList, setPeopleList] = useState<IPersonaProps[]>(usersToPersonas(users));
  const [mostRecentlyUsed, setMostRecentlyUsed] = useState<IPersonaProps[]>([]);

  useEffect(() => {
    if (!loading && !error) {
      setPeopleList(usersToPersonas(data?.users as User[]))
    }

  }, [data, loading, error]);

  const onRemoveSuggestion = (item: IPersonaProps): void => {
    const indexPeopleList: number = peopleList.indexOf(item);
    const indexMostRecentlyUsed: number = mostRecentlyUsed.indexOf(item);

    if (indexPeopleList >= 0) {
      const newPeople: IPersonaProps[] = peopleList.slice(0, indexPeopleList).concat(peopleList.slice(indexPeopleList + 1));
      setPeopleList(newPeople);
    }

    if (indexMostRecentlyUsed >= 0) {
      const newSuggestedPeople: IPersonaProps[] = mostRecentlyUsed
        .slice(0, indexMostRecentlyUsed)
        .concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1));
      setMostRecentlyUsed(newSuggestedPeople);
    }
  };

  const onFilterChanged = (
    filterText: string,
    currentPersonas?: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText);

      filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas!);
      filteredPersonas = limitResults ? filteredPersonas.splice(0, limitResults) : filteredPersonas;
      return filterPromise(filteredPersonas);
    } else {
      return [];
    }
  };

  const returnMostRecentlyUsed = (currentPersonas?: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
    const mru = removeDuplicates(mostRecentlyUsed, currentPersonas!);
    return filterPromise(mru);
  };

  const filterPromise = (personasToReturn: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return personasToReturn;
  };

  const listContainsPersona = (persona: IPersonaProps, personas: IPersonaProps[]) => {
    if (!personas || !personas.length || personas.length === 0) {
      return false;
    }
    return personas.filter(item => item.text === persona.text).length > 0;
  };

  const filterPersonasByText = (filterText: string): IPersonaProps[] => {
    return peopleList.filter(item => doesTextStartWith(item.text as string, filterText));
  };

  const doesTextStartWith = (text: string, filterText: string): boolean => {
    return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
  };

  const getTextFromItem = (persona: IPersonaProps): string => {
    return persona.text  as string;
  };

  const removeDuplicates = (personas: IPersonaProps[], possibleDupes: IPersonaProps[]) => {
    return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
  };

  const validateInput = (input: string): ValidationState => {
    if (input.indexOf('@') !== -1) {
      return ValidationState.valid;
    } else if (input.length > 1) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  };

  const onInputChange = (input: string): string => {
    const outlookRegEx = /<.*>/g;
    const emailAddress = outlookRegEx.exec(input);

    if (emailAddress && emailAddress[0]) {
      return emailAddress[0].substring(1, emailAddress[0].length - 1);
    }

    return input;
  };

  return <NormalPeoplePicker
    onResolveSuggestions={onFilterChanged}
    onEmptyInputFocus={returnMostRecentlyUsed}
    getTextFromItem={getTextFromItem}
    pickerSuggestionsProps={suggestionProps}
    onRemoveSuggestion={onRemoveSuggestion}
    onValidateInput={validateInput}
    onInputChange={onInputChange}
    resolveDelay={300}
    onChange={items => {
      onChange(personasToUsers(items || []));
    }}
  />

};

export default UserPicker;
