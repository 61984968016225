import {
  AuthConfig,
  AuthSession,
  SET_UI_AUTH_CONFIG,
  SET_UI_AUTH_SESSION,
  SET_UI_CONFIG,
  SET_UI_GRAPHQL_CLIENT,
  SET_UI_LANGUAGE,
  SetUiConfigAction,
  SetUiAuthConfigAction,
  SetUiAuthSessionAction,
  SetUiGraphQlAction
} from "./types";
import {
  ApolloClient,
  InMemoryCache
} from '@apollo/client';

export function setUiLanguage(language: string) {
  return {
    type: SET_UI_LANGUAGE,
    payload: language
  };
}

export function setUiConfig(apiBaseUrl: string, applicationInsightsKey: string) : SetUiConfigAction {
  return {
    type: SET_UI_CONFIG,
    payload: {
      apiBaseUrl: apiBaseUrl,
      applicationInsightsKey: applicationInsightsKey
    }
  };
}

export function setUiAuthConfig(config: AuthConfig) : SetUiAuthConfigAction {
  return {
    type: SET_UI_AUTH_CONFIG,
    payload: config
  };
}

export function setUiSession(session: AuthSession) : SetUiAuthSessionAction {
  return {
    type: SET_UI_AUTH_SESSION,
    payload: session
  };
}

export function setUiGraphQlClient(client: ApolloClient<InMemoryCache>): SetUiGraphQlAction {
  return {
    type: SET_UI_GRAPHQL_CLIENT,
    payload: client
  };
}
