// NPM
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Dropdown, TextField} from "@fluentui/react";

// Local
import MutatePanel from "../../components/mutatePanel";
import SubHeading from "../../components/layout/subHeading";
import AddressField from "../../components/addressField";

import {
  Device,
  DeviceUpdateInput,
  useDeviceQuery,
  useAssignDeviceControllerMutation
} from "../../graphql/types";

import {updateDevice} from "../../store/device/actions";
import {getDevicesAsyncStatus, getDevicesError} from "../../store/device/reducers";
import Loading from "../../components/layout/loading";
import {UpdateDeviceValidationSchema} from "../../data/validation/updateDeviceValidationSchema";
import ControllerDropDown from "../../components/controllerDropDown";
import { useKeycloak } from '@react-keycloak/web'
import moment from "moment-timezone";

interface EditDevicePanelProps {
  isOpen: boolean;
  onDismiss: () => void;
  deviceId?: string;
}

const timeZoneOptions = moment.tz.names().map(name => {
  return {
    key: name,
    text: name
  }});


const EditDevicePanel = (props: EditDevicePanelProps) => {
  const {deviceId, isOpen, onDismiss} = props;
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  // Validation and api call status objects
  const [input, setInput] = useState<DeviceUpdateInput>({} as DeviceUpdateInput);
  const { loading, error, data }= useDeviceQuery({
    variables: {
      id: deviceId!
    }
  });
  const [assignDeviceControllerMutation] = useAssignDeviceControllerMutation();

 //Whenever the selected value changes, update form values
  useEffect(() => {
    if (!data || !data.device)
      return;

    const d = data.device as Device;

    function stripTypenames(obj: any, propToDelete: string) {
      for (const property in obj) {
        if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
          delete obj.property;
          const newData = stripTypenames(obj[property], propToDelete);
          obj[property] = newData;
        } else {
          if (property === propToDelete) {
            delete obj[property];
          }
        }
      }
      return obj;
    }

    setInput({
      id: deviceId!,
      displayName: d.displayName,
      model: d.model || undefined,
      timeZoneId: d.timeZoneId || undefined,
      placement: d.placement || undefined,
      address: stripTypenames(d.address, '__typename')
    });
  }, [data, deviceId]);

  if (loading || error) {
    return <Loading/>;
  }

  if (!deviceId)
    return null;

  const device = data?.device as Device;


  return (
    <MutatePanel
      headerText={t('devices:editdevicepanel.headertext')}
      isOpen={isOpen}
      onDismiss={onDismiss}
      input={input}
      objectId={deviceId as any}
      updateAction={updateDevice}
      validationSchema={UpdateDeviceValidationSchema}
      errorSelector={getDevicesError}
      statusSelector={getDevicesAsyncStatus}
    >

      <SubHeading heading={t('devices:editdevicepanel.subheadingname')} />
      <TextField required
                 autoFocus={true}
                 label={t('devices:fields.displaynamelabel')}
                 placeholder={t('devices:fields.displaynameplaceholder')}
                 value={input.displayName}
                 onChange={(e,v) => setInput({
                   ...input,
                   displayName: v || ''
                 })}
      />
      <AddressField label={t('devices:fields.addresslabel')}
                    initialValue={input.address as any}
                    onAddressSelected={(address) => setInput({
                      ...input,
                      address: address
                    })} />
      <TextField label={t('devices:fields.modellabel')}
                 placeholder={t('devices:fields.modelplaceholder')}
                 value={input.model as any}
                 onChange={(e,v) => setInput({
                   ...input,
                   model: v
                 })}
      />
      <TextField label={t('devices:fields.placementlabel')}
                 placeholder={t('devices:fields.placementplaceholder')}
                 value={input.placement as any}
                 onChange={(e,v) => setInput({
                   ...input,
                   placement: v
                 })}
      />
      <Dropdown
        label={t('dataSources:clockDataSourceEditorGroup.timeZoneLabel')}
        placeholder={t('dataSources:clockDataSourceEditorGroup.timeZonePlaceHolder')}
        options={timeZoneOptions}
        selectedKey={input?.timeZoneId}
        onChange={(e, o) =>  setInput({
          ...input,
          timeZoneId: o?.key as string
        })
        } />


      {keycloak.hasRealmRole('developer') &&
        <>
        <SubHeading heading="Developer tools" />
        <ControllerDropDown
          onChange={async (c) => {
            await assignDeviceControllerMutation({
              variables: {
                input: {
                  controllerId: c.id!,
                  deviceId: deviceId
                }
              }
            })
          }}
          selected={device.controller ? device.controller : undefined}
        />
        </>
      }

    </MutatePanel>
  )
};

export default EditDevicePanel;
