// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {IDataSourceEditorProps} from "./dataSourceEditor";
import DefaultDataSourceEditorGroup from "./defaultDataSourceEditorGroup";

/* Local */

// ----------------------------------------------------------------------------

const EditDeviceMessageDataSourceForm = (props: IDataSourceEditorProps) => {
  return <DefaultDataSourceEditorGroup
    showAssetsEditor={false}
    {...props} />
};

export default EditDeviceMessageDataSourceForm;
