// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useTranslation} from "react-i18next";
import { IIframeConfiguration } from "pixon-component-core";

/* Local */
import {IComponentPropertyEditorProps, useComponentConfiguration} from "./propertyEditor";
import DefaultComponentEditorGroup from "./defaultComponentEditorGroup";
import {ComboBox, IComboBoxOption, SelectableOptionMenuItemType, TextField} from "@fluentui/react";
import {useState} from "react";

// ----------------------------------------------------------------------------

const sandboxOptions: IComboBoxOption[] = [
  { key: 'headerSandbox', text: 'Sandbox', itemType: SelectableOptionMenuItemType.Header },
  { key: 'allow-forms', text: 'allow-forms' },
  { key: 'allow-modals', text: 'allow-modals' },
  { key: 'allow-orientation-lock', text: 'allow-orientation-lock' },
  { key: 'allow-pointer-lock', text: 'allow-pointer-lock' },
  { key: 'allow-popups', text: 'allow-popups' },
  { key: 'allow-popups-to-escape-sandbox', text: 'allow-popups-to-escape-sandbox' },
  { key: 'allow-presentation', text: 'allow-presentation' },
  { key: 'allow-same-origin', text: 'allow-same-origin' },
  { key: 'allow-scripts', text: 'allow-scripts' },
  { key: 'allow-top-navigation', text: 'allow-top-navigation' },
  { key: 'allow-top-navigation-by-user-activation', text: 'allow-top-navigation-by-user-activation' }
];


const IframeComponentEditorGroup = (props: IComponentPropertyEditorProps) => {
  const {pageComponent, onChange} = props;
  const component = pageComponent.component!;
  const {t} = useTranslation();
  const [configuration, updateConfigurationProperty] = useComponentConfiguration<IIframeConfiguration>(onChange, component);
  const [selectedSandboxValues, setSelectedSandboxValues] = useState(configuration?.sandbox || []);
  return <>

    <DefaultComponentEditorGroup
      showDataSourceEditor={false}
      showCssEditor={true}
      showTemplateAssetEditor={false}
      heading={t('component:iframeComponentEditorGroup.heading')}
      {...props}>

      <TextField
        label={t('component:iframeComponentEditorGroup.iframeNameUrlLabel')}
        placeholder={t('component:iframeComponentEditorGroup.iframeNamePlaceholder')}
        value={configuration ? configuration.name : ''}
        onChange={(e, v) => updateConfigurationProperty({
          name: v
        })}
      />

      <TextField
        label={t('component:iframeComponentEditorGroup.sourceUrlLabel')}
        placeholder={t('component:iframeComponentEditorGroup.sourceUrlPlaceholder')}
        value={configuration ? configuration.src : ''}
        onChange={(e, v) => updateConfigurationProperty({
          src: v
        })}
      />


      <ComboBox
        multiSelect
        selectedKey={configuration?.sandbox}
        label={t('component:iframeComponentEditorGroup.sandboxLabel')}
        allowFreeform={false}
        autoComplete="on"
        options={sandboxOptions}
        onChange={(event, option) =>
        {
          const selectedKeys = [...selectedSandboxValues] || []; // modify a copy
          const index = selectedKeys.indexOf(option?.key as string);

          if (option?.selected && index < 0) {
            selectedKeys.push(option.key as string);
          } else {
            selectedKeys.splice(index, 1);
          }

          console.log('newSandbox', selectedKeys);
          updateConfigurationProperty({ sandbox: selectedKeys });
          setSelectedSandboxValues(selectedKeys);
        }}
        text={configuration?.sandbox?.join(', ')}
      />

    </DefaultComponentEditorGroup>

  </>
};

export default IframeComponentEditorGroup;
