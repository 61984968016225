// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useEffect, useState} from "react";

/* Local */
import AssetList from "./assetList";
import {
  IStackTokens,
  MessageBar,
  MessageBarType,
  Selection,
  Stack,
} from "@fluentui/react";
import {useTranslation} from "react-i18next";
import EditTextAssetPanel, { EditorType } from "./editTextAssetPanel";
import AssetsCommandBar from "./assetsCommandBar";
import {
  Asset,
  AssetQueryInput,
  useRemoveAssetMutation,
  useUpdateAssetMutation
} from "../../graphql/types";
import NewAssetPanel from "./newAssetPanel";
import EditAssetPanel from "./editAssetPanel";
import AssetsFilter from "./assetsFilter";
import {RouteComponentProps} from "react-router";

// ----------------------------------------------------------------------------
const stackTokens: IStackTokens = {
  childrenGap: 'm',
  padding: 's1'
};

interface IAssetsPageProps {
  assetId?: string;
}

interface IPropsWithParameters extends RouteComponentProps<IAssetsPageProps> {
}

const AssetsPage = (props: IPropsWithParameters) => {
  const {match} = props;
  const {t} = useTranslation();

  // Query parameters
  const assetId = match.params.assetId!;
  const shouldEditContent = match.path.endsWith('/editcontent');

  // State
  const [errorReason, setErrorReason] = useState<string | undefined>();
  const [selectedAsset, setSelectedAsset] = useState<Asset>();
  const [lastUpdated, setLastUpdated] = useState(new Date());

  const [showEditAssetPanel, toggleShowEditAssetPanel] = useState(false);
  const [editContentAssetId, setEditContentAssetId] = useState<string>('');
  const [showNewAssetPanel, toggleShowNewAssetPanel] = useState(false);
  const [editorType, setEditorType] = useState<EditorType>();
  const [filter, setFilter] = useState<AssetQueryInput>( );

  const [selection] = useState<Selection>(new Selection({
    onSelectionChanged: () => {
      setSelectedAsset(selection.getSelection()[0] as Asset)
    }
  }));

  // Mutations
  const [removeAssetMutation] = useRemoveAssetMutation();
  const [updateAssetMutation] = useUpdateAssetMutation();

  // Actions
  const refresh = () => {
    setLastUpdated(new Date())
  };

  useEffect(() => {
    if (assetId && shouldEditContent)
    {
      setEditContentAssetId(assetId);
    }

  }, [assetId, shouldEditContent]);

  const removeSelectedAssets = async () => {
    for (const item of selection.getSelection()) {
      await removeAssetMutation({
        variables: {
          input: (item as Asset).id!
        }
      }).catch(reason => setErrorReason(reason && reason.toString()));
    }
    refresh();
  };

  const updateAsset = async (editedAsset: Asset) => {
    await updateAssetMutation({
      variables: {
        input: {
          id: editedAsset.id,
          displayName: editedAsset.displayName,
          filename: editedAsset.filename,
        }
      }
    }).catch(reason => setErrorReason(reason && reason.toString()));
    refresh();
  };

  return <Stack tokens={stackTokens}>
    <AssetsCommandBar
      selectedAsset={selectedAsset}
      onAddNewAsset={() => toggleShowNewAssetPanel(true)}
      onEditAsset={() => {
        if (selectedAsset) toggleShowEditAssetPanel(true);
      }}
      onEditAssetContent={(et) => {
        setEditorType(et);
        if (selectedAsset) setEditContentAssetId(selectedAsset.id ?? '');
      }}
      onRemoveAsset={removeSelectedAssets}
    />

    <AssetsFilter onFilterUpdated={(f) => setFilter(f)}/>

    {errorReason &&
    <MessageBar messageBarType={MessageBarType.error}>
      {t('common:error.api')}: {errorReason.toString()}
    </MessageBar>
    }

    <AssetList
      filter={filter}
      lastUpdated={lastUpdated}
      selection={selection}
      onRemoveAsset={removeSelectedAssets}
      onEditAsset={(asset) => {
        setSelectedAsset(asset);
        toggleShowEditAssetPanel(true)
      }}
      onEditAssetContent={(asset, et) => {
        setEditorType(et);
        setSelectedAsset(asset);
        setEditContentAssetId(asset.id!);
      }}
    />

    <NewAssetPanel
      isOpen={showNewAssetPanel}
      onCreateAndDismiss={async (newAsset) => {
        toggleShowNewAssetPanel(false);
        await updateAsset(newAsset);
      }}
      onDismiss={async () => {
        toggleShowNewAssetPanel(false);
        refresh();
      }}/>

    {selectedAsset && showEditAssetPanel &&
    <EditAssetPanel
        asset={selectedAsset}
        isOpen={showEditAssetPanel}
        onDismiss={() => {
          toggleShowEditAssetPanel(false);
        }}
        onUpdateAndDismiss={async (updated) => {
          toggleShowEditAssetPanel(false);
          await updateAsset(updated)
        }}/>
    }

    {editContentAssetId !== '' &&
    <EditTextAssetPanel
            isOpen={editContentAssetId !== ''}
            editorType={editorType}
            onDismiss={() => setEditContentAssetId('')}
            onCancel={() => setEditContentAssetId('')}
            selectedId={editContentAssetId}/>
    }
  </Stack>
};

export default AssetsPage;
