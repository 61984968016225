// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {
  Link,
  TextField
} from "@fluentui/react";
import {useTranslation} from "react-i18next";

/* Local */
import {IComponentPropertyEditorProps, useComponentConfiguration} from "./propertyEditor";
import DefaultComponentEditorGroup from "./defaultComponentEditorGroup";
import { DataSourceTypeEnum} from "../../../graphql/types";

// ----------------------------------------------------------------------------

interface IClockConfiguration {
  formatString: string
}

const ClockComponentEditorGroup = (props: IComponentPropertyEditorProps) => {
  const {pageComponent, onChange} = props;
  const component = pageComponent.component!;
  const {t} = useTranslation();
  const [configuration, updateConfigurationProperty] = useComponentConfiguration<IClockConfiguration>(onChange, component);

  return <>

    <DefaultComponentEditorGroup
      showDataSourceEditor={true}
      dataSourceTypes={[DataSourceTypeEnum.Clock]}
      showCssEditor={true}
      heading={t('component:clockComponentEditorGroup.heading')}
      {...props}>

      <TextField
        label={t('component:clockComponentEditorGroup.formatStringLabel')}
        placeholder={t('component:clockComponentEditorGroup.formatStringPlaceholder')}
        value={configuration?.formatString ?? 'dddd, MMMM Do YYYY, h:mm:ss a'}
        onChange={(e, v) => updateConfigurationProperty( {
          formatString: v
        })}
      />
      <Link href='https://momentjs.com/docs/#/displaying/format/' target='_blank'>
        {t('component:clockComponentEditorGroup.formatStringReadMore')} &#x3E;&#x3E;
      </Link>

    </DefaultComponentEditorGroup>

  </>
};

export default ClockComponentEditorGroup;
