import {
  ADD_ORGANIZATION,
  DELETE_ORGANIZATION,
  LOAD_ORGANIZATIONS, UPDATE_ORGANIZATION,
} from "./types";
import {async} from "../asyncAction/actions";
import {
  CreateOrganizationDocument,
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables,
  OrganizationInput,
  OrganizationsWithOfficeAddressDocument,
  OrganizationsWithOfficeAddressQuery,
  OrganizationsWithOfficeAddressQueryVariables,
  OrganizationUpdateInput,
  RemoveOrganizationDocument,
  RemoveOrganizationMutation,
  RemoveOrganizationMutationVariables,
  UpdateOrganizationDocument,
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables,
} from "../../graphql/types";

import {notEmpty} from "../../typeFilters";
import {
  ApolloClient,
  InMemoryCache
} from '@apollo/client';

export function loadOrganizationsAction() {
  return async(LOAD_ORGANIZATIONS, async (client: ApolloClient<InMemoryCache>) => {
    var result = await client.query<OrganizationsWithOfficeAddressQuery, OrganizationsWithOfficeAddressQueryVariables>({
      query: OrganizationsWithOfficeAddressDocument
    });

    const data = result.data && result.data.organizations;
    const organizations =  data.filter(notEmpty);

    return Promise.resolve(organizations);
  });
}

export function  addOrganization(newOrganization: OrganizationInput) {
  return async(ADD_ORGANIZATION, async (client: ApolloClient<InMemoryCache>) => {
    var result = await client.mutate<CreateOrganizationMutation, CreateOrganizationMutationVariables>({
      mutation: CreateOrganizationDocument,
      variables: {
        input: newOrganization
      }
    });
    return Promise.resolve(result.data!.createOrganization!.organization);
  });
}

export function deleteOrganization(id: string) {
  return async(DELETE_ORGANIZATION, async (client: ApolloClient<InMemoryCache>) => {
    var result = await client.mutate<RemoveOrganizationMutation, RemoveOrganizationMutationVariables>({
      mutation: RemoveOrganizationDocument,
      variables: {
        input: id
      }
    });
    return Promise.resolve(result.data!.removeOrganization!.organization);
  });
}

export function updateOrganization(updatedOrganization: OrganizationUpdateInput) {
  return async(UPDATE_ORGANIZATION, async (client: ApolloClient<InMemoryCache>) => {
    var result = await client.mutate<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>({
      mutation: UpdateOrganizationDocument,
      variables: {
        input: updatedOrganization
      }
    });
    return Promise.resolve(result.data!.updateOrganization!.organization);
  });
}
