import React from "react";
import Header from "./header";
import NavBar from "./navBar";
import {Icon, Stack} from "@fluentui/react";
import Logo from "./logo";

const sideBarColumnStyles = {
  root: [
    {
      background: '#1a71b8'
    }
  ]
};

const mainColumnStyles = {
  root: [
    {
      background: '#ffffff',
      padding: 20
    }
  ]
};


const tokens = {
  mainColumnStack: {
    childrenGap: 0
  },
  maiRowStack: {
    childrenGap: 20
  },
};


export interface IMainLayoutState {
  collapsed: boolean
}

const MainLayout = (PassedComponent: any) => {
  return class extends React.Component<{}, IMainLayoutState> {
    constructor(props: any) {
      super(props);
      this.state = {
        collapsed: false
      };
    }

    render() {
      return (
        <Stack horizontal verticalFill id="mainColumnStack" tokens={tokens.mainColumnStack}
               styles={sideBarColumnStyles}>
          <Stack.Item className="sideBar">

            {this.state.collapsed &&
            <Icon
                iconName='CollapseMenu'
                style={{
                  color: 'white',
                  fontSize: 20,
                  display: "block"

                }}
                onClick={() => this.setState({collapsed: !this.state.collapsed})}
            />
            }

            {!this.state.collapsed &&
            <>
                <Logo/>
                <Icon
                    iconName='CollapseMenu'
                    style={{
                      color: 'white',
                      fontSize: 20,
                      marginLeft: 30
                    }}
                    onClick={() => this.setState({collapsed: !this.state.collapsed})}
                />
                <NavBar/>
            </>
            }

          </Stack.Item>

          <Stack.Item grow>

            <Stack verticalFill id="maiRowStack" tokens={tokens.mainColumnStack} styles={mainColumnStyles}>
              <Header/>
              <PassedComponent {...this.props} />
            </Stack>

          </Stack.Item>
        </Stack>
      )
    }
  }
};

export default MainLayout;
