// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {Slider } from "@fluentui/react";
import {useTranslation} from "react-i18next";
import { IWeatherDataSourceConfiguration } from "pixon-component-core";

/* Local */
import DefaultDataSourceEditorGroup from "./defaultDataSourceEditorGroup";
import {IDataSourceEditorProps, useDataSourceConfiguration} from "./dataSourceEditor";
import AddressField from "../../../components/addressField";
import {addressString, isEmpty} from "../../../components/api/util";

// ----------------------------------------------------------------------------

const WeatherDataSourceEditorGroup = (props: IDataSourceEditorProps) => {
  const {dataSource, onChange} = props;
  const {t} = useTranslation();
  const [configuration, updateConfigurationProperty] = useDataSourceConfiguration<IWeatherDataSourceConfiguration>(onChange, dataSource);

  return  <>

    <DefaultDataSourceEditorGroup
      showAssetsEditor={false}
      {...props}>

      <AddressField
        label={t('component:editSmhiWeatherComponent.smhiDataUrl')}
        initialValueString={configuration && !isEmpty(configuration.locationString) ? configuration.locationString : undefined}
        onAddressSelected={(address) => {
          if (!address || !address.location)
            return;

          const lon = parseFloat(address.location.longitude.toString()).toFixed(5);
          const lat = parseFloat(address.location.latitude.toString()).toFixed(5);

          updateConfigurationProperty({
            forecastLocationLongitude: lon,
            forecastLocationLatitude: lat,
            locationString: addressString(address)
          })
        }}
      />

      <Slider
        label={t('component:editSmhiWeatherComponent.refreshIntervalInMinutes')}
        min={5}
        max={360}
        step={1}
        defaultValue={configuration ? configuration.refreshIntervalInMinutes : undefined}
        showValue={true}
        valueFormat={(value: number) => `${value} min`}
        onChange={(value: number) => updateConfigurationProperty({refreshIntervalInMinutes:value})}
      />

    </DefaultDataSourceEditorGroup>

  </>
};

export default WeatherDataSourceEditorGroup;
