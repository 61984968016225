// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
/* Local */
import {IComponentPropertyEditorProps} from "./propertyEditor";
import DefaultComponentEditorGroup from "./defaultComponentEditorGroup";
import {DataSourceTypeEnum} from "../../../graphql/types";
import {useTranslation} from "react-i18next";

// ----------------------------------------------------------------------------

// Defaults


const DexComComponentEditorGroup = (props: IComponentPropertyEditorProps) => {
  const {t} = useTranslation();

  return <>

      <DefaultComponentEditorGroup
        showDataSourceEditor={true}
        dataSourceTypes={[DataSourceTypeEnum.Dexcom]}
        showCssEditor={true}
        showTemplateAssetEditor={true}
        heading={t('component:dexcomEditorGroup.heading')}
        {...props}>



    </DefaultComponentEditorGroup>

  </>
};

export default DexComComponentEditorGroup;
