import * as React from 'react';

import {
  Dropdown, IDropdownOption
} from "@fluentui/react";

import {useTranslation} from "react-i18next";
import {Asset, useAssetsQuery} from "../graphql/types";
import {useEffect, useState} from "react";
import {getAssetDisplayText} from "./api/util";

export interface IAssetsDropDownProps {
  onChange: (selected:Asset) => void;
  assetTypes?: string[],
  required?: boolean,
  selected?: Asset,
  selectFirstIfAvailable?: boolean,
  label?: string
}

const AssetsDropDown = (props: IAssetsDropDownProps) => {
  const { t } = useTranslation();
  const {
    onChange,
    selected,
    assetTypes = ['IMAGE', 'DATA'],
    label = t('common:assetsDropdown.label'),
    selectFirstIfAvailable = false
  } = props;
  const {loading, data, error } = useAssetsQuery();
  let assets = [] as Asset[];
  const [selectedOption, setSelectedOption] = useState<IDropdownOption>();

  if (!loading && !error && data)
  {
    assets = (data.assets as Asset[])
      .filter(a => assetTypes.includes(a.assetType!));
  }

  const options = assets
    .map<IDropdownOption>(a => ({
      key: a.id!,
      text: getAssetDisplayText(a)
    }));

  useEffect(() => {
    // If we have assets in the list but nothing yet defaultSelected
    if (options && options.length > 0 && !selectedOption)
    {
      if (selected)
      {
        // If we have a pre defaultSelected value in props (i.e. opening for edit, mark the current value)
        const foundAsset = options.filter(o => o.key === selected.id!)[0];
        setSelectedOption(foundAsset);
      } else if (selectFirstIfAvailable)  {
        // No defaultSelected value, but the option is to preselect the first one
        const first = options[0];
        setSelectedOption(first);
      }
    }
  }, [options, onChange, selected, selectedOption, selectFirstIfAvailable]);

  return <Dropdown
    required={props.required}
    label={label}
    placeholder={t('common:assetsDropdown.placeholder')}
    options={options}
    selectedKey={selectedOption ? selectedOption.key : undefined}
    onChange={(e, option) => {
      setSelectedOption(option);
      const found = assets.filter(a => a.id === option?.key)[0];
      props.onChange(found)
    }}
  />

};

export default AssetsDropDown;
