import {
  DefaultButton,
  PrimaryButton,
  Panel,
  PanelType,
  Stack,
  TextField, MessageBar, MessageBarType
} from "@fluentui/react";
import * as React from "react";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

interface DeletePanelProps {
  isOpen: boolean;
  headerText: string;
  label: string;
  validationText: string;
  deleteAction: any;
  validate: (input: string) => boolean;
  onDismiss: any;
}

const tokens = {
  tenGapStack: {
    childrenGap: 10
  }
};

const DeletePanel = (props: DeletePanelProps) => {
  const [validationText, setValidationText] = useState('');
  const [isOkToDelete, setIsOkToDelete] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const deleteAction = async () => {
    if (!isOkToDelete)
      return;

    dispatch(props.deleteAction);
    props.onDismiss();
  };

  useEffect(() => {
    const validationOk = props.validate(validationText);
    setIsOkToDelete(validationOk);
  }, [validationText, props]);

  return <>
    <Panel
      type={PanelType.medium}
      headerText={props.headerText}
      closeButtonAriaLabel={t('common:button.close')}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
    >
      <Stack tokens={tokens.tenGapStack}>
        <TextField label={props.label} placeholder={  t('common:delete.validationplaceholder', {validationValue: props.validationText })}
                   required
                   autoFocus={true}
                   onKeyPress={(ev) => {
                     if (ev.key === 'Enter') {
                       ev.preventDefault();
                       deleteAction();
                     }
                   }}
                   onChange={(e) => setValidationText((e.target as HTMLInputElement).value)}/>

        {!isOkToDelete &&
        <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={false}
            dismissButtonAriaLabel={t('common:button.close')}
        >
          {t('common:delete.validationfailmessage', { validationValue: props.validationText })}
        </MessageBar>
        }

        <Stack horizontal tokens={tokens.tenGapStack} horizontalAlign="end">

          <DefaultButton onClick={props.onDismiss} text={t('common:button.cancel')}/>
          <PrimaryButton onClick={deleteAction} text={t('common:button.delete')} disabled={!isOkToDelete}/>

        </Stack>
      </Stack>
    </Panel>
  </>
};

export default DeletePanel;
