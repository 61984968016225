// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useState} from "react";
import {CommandBar, ContextualMenuItemType, ICommandBarItemProps,} from "@fluentui/react";
import {useTranslation} from 'react-i18next';
/* Local */
import { useKeycloak } from '@react-keycloak/web';
import {usePixonCommands, useToggleDebugPanel, useToggleStatusBar} from "../../components/api/usePixonCommand";
import EditDevicePanel from "./editDevicePanel";
import {Device} from "../../graphql/types";
import {DeviceDetailPanel} from "./deviceDetailPage";


// ----------------------------------------------------------------------------

interface IDeviceCommandBarProps {
  device: Device;
  onCancelEdit: () => void,
  onUpdatePage: () => void,
  refetch: any,
  isDirty: boolean,
  onToggleAddComponentPanel: (visible: boolean) => void
  onTogglePanel: (panel: DeviceDetailPanel) => void
}

const DeviceCommandBar = (props: IDeviceCommandBarProps) => {
  const { device, onCancelEdit, onUpdatePage, onTogglePanel, isDirty, refetch } = props;
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const [showEditDevicePanel, toggleShowEditDevicePanel] = useState(false);
  const {reloadCommand, refreshContextCommand, unlinkCommand, requestScreenShotCommand } = usePixonCommands(device.id!);

  const toggleDebugPanelCommand = useToggleDebugPanel();
  const toggleStatusBarCommand = useToggleStatusBar();


  const commandBarItems: ICommandBarItemProps[] =  [
    {
      key: 'save',
      name: t('common:button.save'),
      disabled: !isDirty,
      iconProps: {
        iconName: 'Save'
      },
      onClick: () => onUpdatePage(),
    },
    {
      key: 'cancel',
      name: t('common:button.cancel'),
      iconProps: {
        iconName: 'Cancel'
      },
      disabled: !isDirty,
      onClick: () => {
        onCancelEdit();
      }
    },
    {
      key: 'editDevice',
      name: t('devices:commandbar.editDevice'),
      iconProps: {
        iconName: 'Edit'
      },
      onClick: () => toggleShowEditDevicePanel(!showEditDevicePanel)
    },
    {
      key: 'editComponents',
      name: t('devices:commandbar.editComponents'),
      iconProps: {
        iconName: 'WebComponents'
      },
      onClick: () => onTogglePanel(DeviceDetailPanel.PageEditor)
    }
  ];

  if (keycloak.hasRealmRole('developer')) {

    commandBarItems.push(
      {
        key: 'visualizeConfiguration',
        name: t('devices:commandbar.visualizeConfiguration'),
        iconProps: {
          iconName: 'Dataflows'
        },
        onClick: () => onTogglePanel(DeviceDetailPanel.DeviceVisualizer)
      },
      {
      key: 'developerTools',
      name: t('devices:commandbar.developerTools'),
      iconProps: {
        iconName: "DeveloperTools"
      },

      subMenuProps: {
        items: [
          {
            key: 'section1',
            itemType: ContextualMenuItemType.Section,
            sectionProps: {
              topDivider: false,
              bottomDivider: true,
              title: 'Administrationsgränssnitt',
              items:  [
                {
                  key: 'refreshDeviceInfo',
                  name: t('devices:commandbar.refreshDeviceInfo'),
                  iconProps: {
                    iconName: 'Refresh'
                  },
                  onClick: () => refetch(),
                }
              ]
            }
          },
          {
            key: 'section2',
            itemType: ContextualMenuItemType.Section,
            sectionProps: {
              topDivider: false,
              bottomDivider: true,
              title: 'Enhet',
              items:  [
                {
                  key: 'commands',
                  name: t('devices:commandbar.commands'),
                  iconProps: {
                    iconName: "CommandPrompt"
                  },
                  subMenuProps: {
                    items: [
                      {
                        key: 'reloadCommand',
                        name: t('devices:commandbar.reloadCommand'),
                        iconProps: {
                          iconName: "Refresh"
                        },
                        onClick: () => reloadCommand()
                      },
                      {
                        key: 'refreshContext',
                        name: t('devices:commandbar.refreshContext'),
                        iconProps: {
                          iconName: "PostUpdate"
                        },
                        onClick: () => refreshContextCommand()
                      },
                      {
                        key: 'requestScreenShotCommand',
                        name: t('devices:commandbar.requestScreenShotCommand'),
                        iconProps: {
                          iconName: "DesktopScreenshot"
                        },
                        onClick: () => requestScreenShotCommand()
                      }
                    ]
                  }
                },
                {
                  key: 'toggleStatusbarCommand',
                  name: t('devices:commandbar.toggleStatusbarCommand'),
                  iconProps: {
                    iconName: "CustomizeToolbar"
                  },
                  subMenuProps: {
                    items: [
                      {
                        key: 'toggleStatusbarCommandShow',
                        name: t('devices:commandbar.toggleStatusbarCommandShow'),
                        iconProps: {
                          iconName: "View"
                        },
                        onClick: () => toggleStatusBarCommand(device.id!, true)

                      },{
                        key: 'toggleStatusbarCommandHide',
                        name: t('devices:commandbar.toggleStatusbarCommandHide'),
                        iconProps: {
                          iconName: "Hide2"
                        },
                        onClick: () => toggleStatusBarCommand(device.id!, false)
                      }
                    ]
                  }
                },
                {
                  key: 'toggleDebugPanelCommand',
                  name: t('devices:commandbar.toggleDebugPanelCommand'),
                  iconProps: {
                    iconName: "Bug"
                  },
                  subMenuProps: {
                    items: [
                      {
                        key: 'toggleDebugPanelCommandShow',
                        name: t('devices:commandbar.toggleDebugPanelCommandShow'),
                        iconProps: {
                          iconName: "View"
                        },
                        onClick: () => toggleDebugPanelCommand(device.id!, true)
                      },{
                        key: 'toggleDebugPanelCommandHide',
                        name: t('devices:commandbar.toggleDebugPanelCommandHide'),
                        iconProps: {
                          iconName: "Hide2"
                        },
                        onClick: () =>  toggleDebugPanelCommand(device.id!, false)
                      }
                    ]
                  }
                },
                {
                  key: 'unlinkCommand',
                  name: t('devices:commandbar.unlinkCommand'),
                  iconProps: {
                    iconName: "Delete",
                    style: {
                      color: 'salmon'
                    }
                  },
                  onClick: () => unlinkCommand()
                }
              ]
            }
          }
        ]
      }

    });

  }

  return  <>
    <CommandBar items={commandBarItems}  />

    <EditDevicePanel deviceId={device.id as any} isOpen={showEditDevicePanel} onDismiss={() => toggleShowEditDevicePanel(false)}/>
  </>
};

export default DeviceCommandBar;
