// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useTranslation} from "react-i18next";
import {
  Slider
} from "@fluentui/react";

/* Local */
import {IComponentPropertyEditorProps, useComponentConfiguration} from "./propertyEditor";
import DefaultComponentEditorGroup from "./defaultComponentEditorGroup";
import { DataSourceTypeEnum} from "../../../graphql/types";
import { IKeyboardBarcodeReaderComponentConfiguration } from "pixon-component-core";
// ----------------------------------------------------------------------------

const KeyboardBarcodeReaderComponentEditorGroup = (props: IComponentPropertyEditorProps) => {
  const {pageComponent, onChange} = props;
  const {t} = useTranslation();
  const component = pageComponent.component!;
  const [configuration, updateConfigurationProperty] = useComponentConfiguration<IKeyboardBarcodeReaderComponentConfiguration>(onChange, component);

  return <>

    <DefaultComponentEditorGroup
      showDataSourceEditor={true}
      dataSourceTypes={[DataSourceTypeEnum.DeviceEvent]}
      showCssEditor={false}
      showTemplateAssetEditor={false}
      heading={t('component:keyboardBarcodeReaderComponentEditorGroup.heading')}
      {...props}>

      <Slider
        label={t('component:keyboardBarcodeReaderComponentEditorGroup.acceptBufferDelayLabel')}
        min={50}
        max={3000}
        step={1}
        defaultValue={configuration ? configuration.acceptBufferDelay : 300}
        showValue={true}
        valueFormat={(value: number) => `${value} ms`}
        onChange={(value: number) => updateConfigurationProperty({acceptBufferDelay: value})}
      />

    </DefaultComponentEditorGroup>

  </>
};

export default KeyboardBarcodeReaderComponentEditorGroup;
