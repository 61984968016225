import * as React from 'react';
import {useState} from 'react';
import Wizard from "../../components/wizard";
import {StackItem, Image, Link, IStackTokens, MessageBar, MessageBarType, Stack, Text, TextField} from "@fluentui/react";
import {isEmpty} from "../../components/api/util";
import CheckList from "../../components/checkList";
import {useTranslation} from "react-i18next";
import {HomeScreenInformation} from "../../graphql/types";
import OrganizationsDropdown from "../../components/organizationsDropdown";
import UploadAssetComponent from "../../components/uploadAssetComponent";

export interface IGetStartedWizardProps {
  info: HomeScreenInformation
}
const tokens: IStackTokens = {
  childrenGap: 'm',
  padding: 'm'
};

const GetStartedWizard = (props: IGetStartedWizardProps) => {
  const {info} = props;
  const {t} = useTranslation();

  const [organizationName, setOrganizationName] = useState('');
  const [linkCode, setLinkCode] = useState('');
  const [deviceDisplayName, setDeviceDisplayName] = useState('');
  const [organizationId, setOrganizationId] = useState('');
  const [message, setMessage] = useState('');

  return <Wizard
    addNumbering={true}
    showTabIcons={false}
    children={[
    {
      title: t('home:getStartedOrganizationCreated'),
      onValidate:  () => !isEmpty(organizationName),
      validationErrorMessage: 'Organization name is required',
      children: <Stack tokens={tokens}>
        <Text>Först behöver vi skapa en organisation som kommer att vara ägare av dina enheter.</Text>
        <TextField
          required
          autoFocus={true}
          label={t('organizations:addorganizationpanel.namelabel')}
          placeholder={t('organizations:addorganizationpanel.nameplaceholder')}
          value={organizationName}
          onChange={(e, v) => setOrganizationName(v || '')}
        />
        <Link href={'https://docs.pixon.app/admin-interface/organizations/laegg-till-organisation'}>Läs mer i dokumentationen</Link>
      </Stack>
    },
    {
      title: t('home:getStartedDeviceLinked'),
      children: <>
        <StackItem align={"center"}>
        <Image
          width={300}
          src={'https://blobscdn.gitbook.com/v0/b/gitbook-28427.appspot.com/o/assets%2F-L_D5l6-HbOzwybtLF2a%2F-Lo_bY0Q8-CnKPxkDvc6%2F-Lo_ob7NTdJwpbFISDP2%2Fimage.png?alt=media&token=8e3fab35-74ea-47b8-9e9a-b3305388f2dd'}
        />
        </StackItem>
        <Text>
          Länka din första enhet med hjälp av koden som visas på den skärm du vill installera.
        </Text>
        <TextField
          required
          label={t('devices:linkdevicepanel.linkcode')}
          placeholder={t('devices:linkdevicepanel.linkcodeplaceholder')}
          value={linkCode}
          onChange={(e,v) => setLinkCode(v || '')}
          autoFocus={true} />

        <TextField
          required
          label={t('devices:linkdevicepanel.displaynamelabel')}
          placeholder={t('devices:linkdevicepanel.displaynameplaceholder')}
          value={deviceDisplayName}
          onChange={(e,v) => setDeviceDisplayName(v || '')}
        />

        <OrganizationsDropdown required onChange={(o) => setOrganizationId(o.id!)} />
        <Link href={'https://docs.pixon.app/admin-interface/templates/layouts'}>Läs mer i dokumentationen</Link>
      </>,
      validationErrorMessage: 'Fix stuff!'
    },
    {
      title: t('home:getStartedContentConfigured'),
      children: <Stack tokens={tokens}>
        <Text>Ladda upp en bild att använda som bakgrund på din första enhet.</Text>
        <UploadAssetComponent
          organizationId={organizationId}
          uploadFinishedCallback={async (uploadedAsset) => {
            // Assign asset to linked device background image component here
            setMessage(t('assets:uploadSuccessMessage'))
          }} />

          {message !== '' &&
          <MessageBar
              messageBarType={MessageBarType.success}
              isMultiline={false}
              onDismiss={() => setMessage('')}
          >
            {message}
          </MessageBar>
          }

      </Stack>
    },
    {
      title: t('home:getStartedSummary'),
      children: <CheckList
        horizontal={false}
        items={[
          {
            completed: info.hasInitializedOrganization,
            label: t('home:getStartedOrganizationCreated')
          },
          {
            completed: info.hasInitializedDevice,
            label: t('home:getStartedDeviceLinked')
          },
          {
            completed: info.hasInitializedComponent,
            label: t('home:getStartedContentConfigured')
          },
        ]}
      />
    }
  ]}
  />
};

export default GetStartedWizard;
