// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {Dropdown, TextField, ActionButton} from "@fluentui/react";
import {useTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {withRouter} from "react-router-dom";
import { IRegistryDataSourceConfiguration } from "pixon-component-core";

/* Local */
import {Asset, AssetType} from "../../../graphql/types";
import DefaultDataSourceEditorGroup from "./defaultDataSourceEditorGroup";
import {IDataSourceEditorProps, useDataSourceConfiguration} from "./dataSourceEditor";

// ----------------------------------------------------------------------------

interface IEditResidentRegistryDataSourceFormProps extends RouteComponentProps, IDataSourceEditorProps {
}

const EditResidentRegistryDataSourceForm = (props: IEditResidentRegistryDataSourceFormProps) => {
  const {dataSource, onChange} = props;
  const {t} = useTranslation();
  const [configuration, updateConfigurationProperty] = useDataSourceConfiguration<IRegistryDataSourceConfiguration>(onChange, dataSource);

  return  <>

    <DefaultDataSourceEditorGroup
      showAssetsEditor={true}
      assetLabel={t('dataSources:editJsonAssetDataSourceComponent.assetLabel')}
      assetTypes={[AssetType.ResidentRegistry]}
      {...props}>

    <ActionButton
      iconProps={{
        iconName: 'Edit'
      }}
      disabled={!(dataSource && dataSource.assets && dataSource.assets.length > 0)}
      onClick={()=> {
        if (dataSource && dataSource.assets && dataSource.assets.length > 0) {
          const asset = dataSource.assets[0] as Asset;
          props.history.push('/assets/' + asset.id + '/editcontent')
        }
      }}
    >
      {t('dataSources:editResidentRegistryDataSourceForm.editAssetContentButton')}
    </ActionButton>

    <TextField
      label={t('dataSources:editJsonDataSourceComponent.surveyNumberStartsWithFilterLabel')}
      placeholder={t('dataSources:editJsonDataSourceComponent.surveyNumberStartsWithFilterPlaceHolder')}
      value={configuration ? configuration.surveyNumberStartsWith : undefined}
      onChange={(e, v) => updateConfigurationProperty( {
        surveyNumberStartsWith: v
      })} />

    <Dropdown
      label={t('dataSources:editJsonDataSourceComponent.residentTypeLabel')}
      placeholder={t('dataSources:editJsonDataSourceComponent.residentTypePlaceHolder')}
      options={[
        { key: 'Person', text: t('dataSources:editJsonDataSourceComponent.residentTypePerson') },
        { key: 'Företag', text: t('dataSources:editJsonDataSourceComponent.residentTypeCompany') },
      ]}
      selectedKey={configuration ? configuration.residentType : undefined}
      onChange={(e, o) =>  updateConfigurationProperty({
            residentType: o?.key as string
          })
      } />

    <Dropdown
      label={t('dataSources:editJsonDataSourceComponent.sortColumnLabel')}
      placeholder={t('dataSources:editJsonDataSourceComponent.sortColumnPlaceHolder')}
      options={[
        { key: 'name', text: t('dataSources:editJsonDataSourceComponent.sortColumnName') },
        { key: 'apartment_number', text: t('dataSources:editJsonDataSourceComponent.sortColumnApartmentNumber') },
        { key: 'national_land_survey_number', text: t('dataSources:editJsonDataSourceComponent.sortColumnLandSurveyNumber') },
      ]}
      selectedKey={configuration ? configuration.sortColumn : undefined}
      onChange={(e, o) =>  updateConfigurationProperty({
        sortColumn: o?.key as string
      })
      } />

    </DefaultDataSourceEditorGroup>

  </>
};

export default withRouter(EditResidentRegistryDataSourceForm);
