// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useEffect, useState} from "react";
import Moveable from "react-moveable"
import {Guid} from "guid-typescript";
import {useTranslation} from "react-i18next";
import {
  IContextualMenuProps,
  IStackTokens,
  Stack,
} from "@fluentui/react";

/* Local */
import {
  Component,
  ComponentTypeEnum,
  Device,
  DeviceContext,
  PageComponent,
} from "../../graphql/types";
import {getAssetUrl, preventContextmenu} from "../../components/api/util";
import SubHeading from "../../components/layout/subHeading";
import ComponentTreePicker from "./componentTreePicker";
import PageEditorCommandBar from "./pageEditorCommandBar";
import PageEditorMovableComponent from "./pageEditorMovableComponent";
import PageEditorContextMenu, {createContextMenu} from "./pageEditorContextMenu";
import { useKeycloak } from '@react-keycloak/web'

const Rematrix = require('rematrix');

// ----------------------------------------------------------------------------

export const movableTargetClassname = 'pageComponent';
export const movableContainerId = 'pageEditor';

const stackTokens: IStackTokens = {
  padding: 's1',
  childrenGap: 'm'

};

interface IPageEditorProps {
  deviceContext: DeviceContext
  device: Device,
  onEditComponent: (component: PageComponent) => void
  onRequestRefresh: () => void
  onChanged: () => void,
  onAddComponent: (newComponent: Component) => void
  onRemoveComponent: (component: Component) => void
  components: PageComponent[],
  selectedComponent?: PageComponent,
  lastUpdated: Date
}

const defaultViewScale = 1;

const PageEditor = (props: IPageEditorProps) => {
  const {device, onChanged, onEditComponent, onAddComponent, onRemoveComponent, components, selectedComponent} = props;
  const {t} = useTranslation();
  const { keycloak } = useKeycloak()
  const [container, setContainer] = useState<HTMLElement>();
  const [target, setTarget] = useState<HTMLElement>();
  const [targetElements, setTargetElements] = useState<HTMLElement[]>();
  const [contextMenu, setContextMenu] = useState<IContextualMenuProps>();
  const [viewScale, setViewScale] = useState<number>(defaultViewScale);

  const componentList = components
    .map((c, i) =>  <PageEditorMovableComponent
        key={c.component!.id!}
        viewScale={viewScale}
        pageComponent={c}
        setSelected={element => setTarget(element)}
        onMouseDown={event => {
          if (event.button === 2) {
            const component = components.find(co => co.component!.id === c.component!.id);
            if (component) {
              setContextMenu(createContextMenu(
                component,
                event.nativeEvent as MouseEvent,
                onEditComponent,
                onRemoveComponent,
                setTarget,
                () => setContextMenu(undefined),
                t
              ));
            }
          }
          return preventContextmenu(event);
        }}
        onEditComponent={onEditComponent}
      />
    );

  useEffect(() => {
    const c = document.getElementById(movableContainerId) as HTMLElement;
    const elements = Array.from(c.querySelectorAll('.' + movableTargetClassname)) as HTMLElement[];

    setContainer(c);
    setTargetElements(elements);

    if (selectedComponent) {
      const selectedElement = elements.filter(e => selectedComponent.component!.id === e.getAttribute('data-id'))[0];
      selectedComponent.width = (selectedElement.clientWidth / viewScale).toString();
      selectedComponent.height = (selectedElement.clientHeight / viewScale).toString();
      setTarget(selectedElement);
    }

  }, [components, selectedComponent, viewScale]);

  const updateComponentValues = (e: any) => {
    const selectedComponents = components.filter(c => c.component!.id === e.target.getAttribute('data-id'));
    if (selectedComponents && selectedComponents.length > 0) {
      const sc = selectedComponents[0];

      // Get movable element properties, these are scaled after viewscale
      const movableElementStyle = window.getComputedStyle(e.target, null);
      const scaledElementTransform = Rematrix.fromString(movableElementStyle.transform);

      // Calculate re-scaled properties
      const inverseScale = Rematrix.scale(1 / viewScale, 1 / viewScale);
      const scaledTransform = Rematrix.toString([inverseScale, scaledElementTransform].reduce(Rematrix.multiply));

      sc.transform = scaledTransform;
      // https://developer.mozilla.org/en-US/docs/Web/API/CSS_Object_Model/Determining_the_dimensions_of_elements
      sc.width = (e.target.clientWidth / viewScale).toString();
      sc.height = (e.target.clientHeight / viewScale).toString();

      // Notify changes
      onChanged();
    }
  };

  const getBackgroundImageUrl = () => {
    const bgImageComponent =
      components
        .filter(c => c.component!.componentType === ComponentTypeEnum.BackgroundImage);

    return bgImageComponent && bgImageComponent.length > 0
      ? bgImageComponent[0].component!.assets && bgImageComponent[0].component!.assets.length > 0
        ? getAssetUrl(bgImageComponent[0].component!.assets[0]!)
        : undefined
      : undefined;
  };


  const backgroundImageUrl = getBackgroundImageUrl();
  const scaledWidth = device.viewportWidth  * viewScale;
  const scaledHeight = device.viewportHeight * viewScale;
  const frame = {
    transform: []
  };

  return <>

    <Stack tokens={stackTokens} horizontal horizontalAlign={"start"}>
      <Stack.Item>
        <SubHeading heading={t('component:pageEditorComponentsHeadline')}/>
        <ComponentTreePicker height={scaledHeight} addComponent={c => {
          const newGuid = Guid.raw();
          onAddComponent({
            ...c,
            id: newGuid
          });
        }}/>
      </Stack.Item>

      <Stack.Item>

        <SubHeading heading={t('component:pageEditorEditViewHeadline')}/>
        {keycloak.hasRealmRole('developer') &&
        <PageEditorCommandBar onChangeViewScale={setViewScale} defaultViewScale={defaultViewScale}/>
        }

        <div
          id={movableContainerId}
          onContextMenu={preventContextmenu}
          style={{
            width: scaledWidth,
            height: scaledHeight,
            top: 0,
            left: 0,
            borderStyle: 'solid',
            borderWidth: '1px',
            backgroundColor: "white",
            backgroundImage: 'url(' + backgroundImageUrl + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%, 100%'
          }}
        >

          {componentList}

          {container && target &&
          <Moveable
              target={target}
              container={container}
              onContextMenu={preventContextmenu}
              origin={true}
              edge={false}
              keepRatio={false}
              draggable={true}
              throttleDrag={0}
              onDragStart={e =>{
              }}
              onDrag={e => {
                e.target.style.transform = e.transform
              }}
              onDragEnd={updateComponentValues}
              rotatable={true}
              throttleRotate={0}
              resizable={true}
              onResizeStart={e => {

                // If cssSize and offsetSize are different, set cssSize. (no box-sizing)
                const style = window.getComputedStyle(e.target);
                const cssWidth = parseFloat(style.width);
                const cssHeight = parseFloat(style.height);
                e.set([cssWidth, cssHeight]);

                // If a drag event has already occurred, there is no dragStart.
                frame.transform = Rematrix.fromString(style.transform);
              }}
              onResize={e => {
                //updateDimensions(e);
                e.target.style.width = `${e.width}px`;
                e.target.style.height = `${e.height}px`;

                // get drag event
                const dt = Rematrix.translate(e.drag.delta[0], e.drag.delta[1]);
                frame.transform = [frame.transform, dt].reduce(Rematrix.multiply);
                target.style.transform = Rematrix.toString(frame.transform);
              }}
              onResizeEnd={updateComponentValues}
              onRotate={e => {
                e.target.style.transform = e.transform
              }}
              onRotateEnd={updateComponentValues}
              snappable={true}
              snapCenter={true}
              snapThreshold={5}
              bounds={{
                left: container.offsetLeft,
                top: container.offsetTop,
                bottom: container.offsetHeight + container.offsetTop,
                right: container.offsetWidth + container.offsetLeft
              }}
              verticalGuidelines={[container.offsetLeft + container.offsetWidth / 2]}
              horizontalGuidelines={[container.offsetTop + container.offsetHeight / 2]}
              elementGuidelines={targetElements}
          />
          }
        </div>
      </Stack.Item>

    </Stack>

    <PageEditorContextMenu  menu={contextMenu} />
  </>
};

export default PageEditor;
