// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useState} from "react";
import {
  CommandBar,
  Stack,
} from "@fluentui/react";
import { useTranslation  } from 'react-i18next';

/* Local */
import OrganizationsCardList from "./organizationsCardList";
import AddOrganizationPanel from "./addOrganizationPanel";
import EditOrganizationPanel from "./editOrganizationPanel";
import DeleteOrganizationPanel from "./deleteOrganizationPanel";
import {Organization} from "../../graphql/types";
import {RouteComponentProps} from "react-router";
import {withRouter} from "react-router-dom";

interface OrganizationsPageIndexProps extends RouteComponentProps {
}

const OrganizationsPageIndex = (props: OrganizationsPageIndexProps) => {
  const { t } = useTranslation();
  const [selectedOrganization, setSelectedOrganization] = useState<Organization>();
  const [showAddOrganizationPanel, toggleShowAddOrganizationPanel] = useState(false);
  const [showEditOrganizationPanel, toggleShowEditOrganizationPanel] = useState(false);
  const [showDeleteOrganizationPanel, toggleDeleteOrganizationPanel] = useState(false);

  const commandBarItems = [
    {
      key: 'addOrganization',
      name: t('organizations:commandbar.addorganization'),
      cacheKey: 'addOrganization',
      iconProps: {
        iconName: 'Add'
      },
      ariaLabel: t('organizations:commandbar.addorganization'),
      onClick: () => toggleShowAddOrganizationPanel(!showAddOrganizationPanel),
    }
  ];

  return  (
    <>
      <Stack>
        <CommandBar items={commandBarItems} />
        <Stack.Item>
          <p>
            {t('organizations:pagedescription')}
          </p>
        </Stack.Item>
        <OrganizationsCardList
          onEditClick={(o:Organization) => {
          props.history.push('/organization/' + o.id)
        }} onDeleteClick={(organization:Organization) => {
          setSelectedOrganization(organization);
          toggleDeleteOrganizationPanel(true)
        }} />
      </Stack>

      <DeleteOrganizationPanel organization={selectedOrganization} isOpen={showDeleteOrganizationPanel} onDismiss={() => toggleDeleteOrganizationPanel(false)} />
      <AddOrganizationPanel isOpen={showAddOrganizationPanel} onDismiss={() => toggleShowAddOrganizationPanel(false)} />

      {selectedOrganization &&
        <EditOrganizationPanel organizationId={selectedOrganization.id as any} isOpen={showEditOrganizationPanel} onDismiss={() => toggleShowEditOrganizationPanel(false)} />
      }
    </>
  );
};

export default  withRouter(OrganizationsPageIndex);
