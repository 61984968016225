// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {Slider, TextField} from "@fluentui/react";
import {useTranslation} from "react-i18next";
import { IRssDataSourceConfiguration } from "pixon-component-core";

/* Local */
import DefaultDataSourceEditorGroup from "./defaultDataSourceEditorGroup";
import {IDataSourceEditorProps, useDataSourceConfiguration} from "./dataSourceEditor";

// ----------------------------------------------------------------------------

const RssDataSourceEditorGroup = (props: IDataSourceEditorProps) => {
  const {dataSource, onChange} = props;
  const {t} = useTranslation();
  const [configuration, updateConfigurationProperty] = useDataSourceConfiguration<IRssDataSourceConfiguration>(onChange, dataSource);

  return  <>

    <DefaultDataSourceEditorGroup
      showAssetsEditor={false}
      {...props}>

      <TextField
        label={t('component:rssDataSourceEditorGroup.feedUrlLabel')}
        placeholder={t('component:rssDataSourceEditorGroup.feedUrlPlaceHolder')}
        autoFocus
        defaultValue={configuration ? configuration.feedUrl : undefined}
        onChange={(e, v) => {
          updateConfigurationProperty({feedUrl: v})
        }}/>

      <Slider
        label={t('component:rssDataSourceEditorGroup.refreshIntervalInMinutes')}
        min={5}
        max={360}
        step={1}
        defaultValue={configuration ? configuration.refreshIntervalInMinutes : undefined}
        showValue={true}
        valueFormat={(value: number) => `${value} min`}
        onChange={(value: number) => updateConfigurationProperty({refreshIntervalInMinutes:value})}
      />

    </DefaultDataSourceEditorGroup>

  </>
};

export default RssDataSourceEditorGroup;
