// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  Text
} from "@fluentui/react";

/* Local */
import {
  Organization, useCancelOrganizationSubscriptionMutation,
} from "../../graphql/types";
import SubHeading from "../../components/layout/subHeading";

// ----------------------------------------------------------------------------

interface IShowExistingSubscriptionFormProps {
  organization: Organization;
  onRefetch: () => void;
}

const ShowExistingSubscriptionForm = (props: IShowExistingSubscriptionFormProps) => {
  const {organization, onRefetch} = props;
  const {t} = useTranslation();

  const [messageType, setMessageType] = useState<MessageBarType>();
  const [message, setMessage] = useState<string>('');

  const [cancelOrganizationSubscription] = useCancelOrganizationSubscriptionMutation();

  const subscription = organization.organizationSubscription;
  const organizationDeviceCount = organization?.devices?.length ?? 0;

  const displayError = (m: string) => {
    setMessageType(MessageBarType.error);
    setMessage(m);
  };

  const displaySuccess = (m: string) => {
    setMessageType(MessageBarType.success);
    setMessage(m);
  };

  const cancelSubscription = () => {
    cancelOrganizationSubscription({
      variables: {
        input: {
          organizationId: organization.id!,
          subscriptionId: subscription?.subscriptionPlan?.id,
        }
      }
    })
    .then(value => {
      displaySuccess(t('component:showExistingSubscriptionForm.subscriptionSuccessfullyCanceledMessage'));
      onRefetch();
    })
    .catch(reason => displayError(reason.toString()));
  };

  console.log('org', organization);
  return <>
    <SubHeading heading={'Nuvarande prenumeration'}/>

    <Text>
      {t('component:showExistingSubscriptionForm.organizationSubscriptionMessage',
        {
          organization,
          subscription,
          organizationDeviceCount,
          subscriptionName: subscription?.subscriptionPlan?.nameTranslationKey ? t(subscription?.subscriptionPlan?.nameTranslationKey) : ''
        })}
    </Text>

    {message !== '' &&
    <MessageBar messageBarType={messageType}>
      {message}
    </MessageBar>
    }

    <Stack horizontal tokens={{childrenGap: 10}} horizontalAlign="end">
      <PrimaryButton onClick={cancelSubscription}>
        {t('component:showExistingSubscriptionForm.cancelSubscriptionButtonLabel')}
      </PrimaryButton>
    </Stack>

  </>
};

export default ShowExistingSubscriptionForm;
