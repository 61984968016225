// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useState} from "react";
/* Local */
import TextPane from "../../components/textPane";
import {
  ActivityItem,
  IActivityItemProps,
  IStackTokens,
  Link,
  mergeStyleSets,
  MessageBar, MessageBarButton,
  MessageBarType,
  PrimaryButton,
  Stack,
  StackItem,
  Text
} from "@fluentui/react";
import SubHeading from "../../components/layout/subHeading";
import {
  HomeScreenInformation,
  LastActivityItem,
  Maybe,
  Notification,
  useHomeScreenInformationQuery
} from "../../graphql/types";
import Loading from "../../components/layout/loading";
import {useTranslation} from "react-i18next";
import {getUserDisplayText} from "../../components/api/util";
import GetStartedPanel from "./getStartedPanel";
import {RouteComponentProps} from "react-router";
import {withRouter} from "react-router-dom";

// ----------------------------------------------------------------------------

const stackTokens: IStackTokens = {
  childrenGap: 'm',
  padding: 'm'
};

const classNames = mergeStyleSets({
  exampleRoot: {
    marginTop: '20px'
  },
  nameText: {
    fontWeight: 'bold'
  }
});

const newsItems: (IActivityItemProps & { key: string | number })[] = [
  {
    key: 1,
    activityDescription: [
      <Link
        key={1}
        className={classNames.nameText}
      >
        Rduce Complexity AB
      </Link>,
      <span key={2}> released </span>,
      <span key={3} className={classNames.nameText}>
          version 20191022.3
        </span>
    ],
    activityPersonas: [{imageUrl: 'https://rduce.net/images/logo-blue-black.svg'}],
    timeStamp: new Date('2019-10-21').toLocaleString()
  }
];

const HomePageIndex = (props: RouteComponentProps) => {
  const {t} = useTranslation();
  const [showGetStartedPanel, toggleGetStartedPanel] = useState<boolean>(false);
  const {loading, data, error} = useHomeScreenInformationQuery();

  if (loading) {
    return <Loading/>
  }

  if (error) {
    return <MessageBar messageBarType={MessageBarType.error}>{t('assets:assetList.loadErrorMessage')}</MessageBar>
  }

  const info = data?.home as HomeScreenInformation;
  const showIntro = false;

  const notificationList = info.notifications!.map((n:Maybe<Notification>, i:number) =>
    <MessageBar
      key={'notification' + i}
      messageBarType={MessageBarType.warning}
      isMultiline={false}
      actions={
        <div>
          <MessageBarButton onClick={() =>
            props.history.push('/organization/' + n!.subjectId)
          }>
            {t('common:button.fix')}
          </MessageBarButton>
        </div>
      }
      style={{width: '700px'}}>{t('notifications:' + n!.notificationType, n!)} </MessageBar>);

  const getActivityItems = (items?: Array<Maybe<LastActivityItem>>): (IActivityItemProps & { key: string | number })[] => {
    if (!items || items.length === 0)
      return [];

    return items.filter(i => i).map((item: Maybe<LastActivityItem>, index:number) => {

      return {
        key: index,
        activityDescription: [
          <Link
            key={1}
            className={classNames.nameText}
          >
            {getUserDisplayText(item!.user!)}
          </Link>,
          <span key={2}> {t('home:edited')} </span>,
          <span key={3} className={classNames.nameText}>
            {item!.itemName}
          </span>
        ],
        activityPersonas: [{imageUrl: item!.user!.picture!}],
        timeStamp: new Date(item!.timestamp).toLocaleString()
      }
    });
  };

  return (
    <Stack tokens={stackTokens} horizontalAlign={'start'} key={'countsVertical'}>

        <Stack horizontal horizontalAlign={"center"} tokens={stackTokens} key={'countsHorizontal'}>
            <TextPane key={'organizationCount'}>
                <Text variant={"mega"}>{info.organizationCount.toString()}</Text>
                <Text>{t('home:organizationCount')}</Text>
            </TextPane>

            <TextPane key={'deviceCount'}>
                <Text variant={"mega"}>{info.deviceCount.toString()}</Text>
                <Text>{t('home:deviceCount')}</Text>
            </TextPane>

            <TextPane key={'dataSourceCount'}>
                <Text variant={"mega"}>{info.dataSourceCount.toString()}</Text>
                <Text>{t('home:datasourceCount')}</Text>
            </TextPane>

            <TextPane key={'componentCount'}>
                <Text variant={"mega"}>{info.componentCount.toString()}</Text>
                <Text>{t('home:componentCount')}</Text>
            </TextPane>
        </Stack>

      <Stack tokens={stackTokens} horizontalAlign={'start'} key={'notifcations'}>
        {notificationList}
      </Stack>

        {showIntro &&
        <>
            <SubHeading heading={'Kom igång'} />
            <Text>Använd guiden för att få en flygande start!</Text>
            <PrimaryButton
                onClick={() => toggleGetStartedPanel(true)}
            >
              {t('home:getStartedHeading')}
            </PrimaryButton>
        </>
        }

        <Stack horizontal horizontalAlign={"center"} tokens={stackTokens} key={'latestActivityHorizontal'}>
          <StackItem key="latestActivity">
          <SubHeading heading={t('home:latestActivityHeading')} key={'latestActivityHeading'}/>
          <div >
          {getActivityItems(info.lastActivity).map((item: { key: string | number }) => (
            <ActivityItem {...item} key={item.key} className={classNames.exampleRoot}/>
          ))}
          </div>
          </StackItem>

          <StackItem key="news">
          <SubHeading heading={t('home:newsHeading')} key={'newsSubHeading'} />
          <div>
          {newsItems.map((item: { key: string | number }) => (
            <ActivityItem {...item} key={item.key} className={classNames.exampleRoot}/>
          ))}
          </div>
          </StackItem>
          </Stack>

      <GetStartedPanel isOpen={showGetStartedPanel} onDismiss={() => toggleGetStartedPanel(false)} info={info}/>
    </Stack>
  );
};

export default withRouter(HomePageIndex);
