import * as React from 'react';

import {StackItem, FontIcon, IStackTokens, Stack, Text} from "@fluentui/react";

export interface ICheckListItem {
  completed: boolean,
  label: string
}
export interface ITextPaneProps {
  items: ICheckListItem[],
  horizontal?: boolean
}

const getIcon = (value: boolean) => value ? "CheckMark" : "Cancel";
const getColor = (value: boolean) => value ? "green" : "salmon";

const getStartedTokens: IStackTokens = {
  childrenGap: 'l1',
  padding: 'l1'
};

const CheckList = (props: ITextPaneProps) => {
  const {
    items,
    horizontal = true
  } = props;

  return <Stack horizontal={horizontal} tokens={getStartedTokens} horizontalAlign={'start'}>

    {items.map((item,i) => {
      return <StackItem key={'checklistItem-' + i}>
          <FontIcon
            iconName={getIcon(item.completed)}
            style={{
              color: getColor(item.completed),
              marginRight: '1em'
            }}
          />
          <Text>{item.label}</Text>
        </StackItem>
    })}

  </Stack>
};

export default CheckList;
