import * as React from "react";
import { Text } from "@fluentui/react";

interface PageHeadingProps {
  heading: string;
}

const PageHeading: React.FunctionComponent<PageHeadingProps> = props => (
  <Text variant={"xxLarge"} block id="pageHeading">
    {props.heading}
  </Text>
);

export default PageHeading;
