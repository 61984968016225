// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useEffect, useState} from "react";
import {RouteComponentProps,withRouter} from "react-router-dom";
import {IStackTokens, MessageBar, MessageBarType, Stack} from "@fluentui/react";
/* Local */
import {
  Asset,
  Component,
  ComponentAssetInput,
  DataSource,
  Device,
  DeviceContext,
  Page,
  PageComponent,
  PageComponentInput,
  UpdateAssetInput,
  UpdateComponentInput,
  UpdateDataSourceInput,
  useGetDeviceDetailsQuery,
  useSetupNewDeviceMutation,
  useUpdatePageMutation,
} from "../../graphql/types";
import Loading from "../../components/layout/loading";
import PageHeading from "../../components/layout/pageHeading";
import DeviceCommandBar from "./deviceCommandBar";
import DeviceScreenShot, {DeviceScreenShotMode} from "./deviceScreenShot";
import DeviceProperties from "./deviceProperties";
import PageEditor from "./pageEditor";
import DeviceVisualizer from "./deviceVisualizer";
import EditComponentPanel from "./editComponentPanel";
const Rematrix = require('rematrix');

// ----------------------------------------------------------------------------

interface IDeviceDetailProps {
  deviceId?: string;
  history: any;
}

interface IPropsWithParameters extends RouteComponentProps<IDeviceDetailProps> {
}

const stackTokens: IStackTokens = {
  childrenGap: 'm',
  padding: 's1'
};

export enum DeviceDetailPanel {
  PageComponents = 1,
  PageEditor,
  DeviceVisualizer
}

const DeviceDetailPage = (props: IPropsWithParameters) => {
  const {match} = props;
  const deviceId = match.params.deviceId!;
  const [showEditComponentPanel, toggleShowEditComponentPanel] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState<PageComponent>();
  const [currentPanel, setCurrentPanel] = useState(DeviceDetailPanel.PageEditor);
  const [isDirty, setDirty] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const {loading, data, error, refetch} = useGetDeviceDetailsQuery({
    variables: {
      id: deviceId
    }
  });
  const [triggerRefreshScreenShot, setTriggerRefreshScreenShot] = useState(false);
  const [device, setDevice] = useState<Device>({} as Device);
  const [deviceContext, setDeviceContext] = useState<DeviceContext>();
  const [page, setPage] = useState<Page>({} as Page);

  const defaultMessage = 'Klicka på komponenter för att lägga till dem i redigeringsvyn, högerkicka på dem för att redigera komponenternas egenskaper.';
  const [message, setMessage] = useState(defaultMessage);
  const [messageType, setMessageType] = useState<MessageBarType>(MessageBarType.info);

  const [updatePageMutation] = useUpdatePageMutation();
  const [setupNewDevice] = useSetupNewDeviceMutation();

  useEffect(() => {
    async function checkNewDevice() {
      if (deviceContext?.controller?.isSystem) {
        await setupNewDevice({
          variables: {
            input: {
              deviceId: device?.id || ''
            }
          }
        });
        await refetch();
      }
    }
    checkNewDevice();
  }, [deviceContext, device, refetch, setupNewDevice]);

  const assetToAssetUpdateInput = (c: Component, a?: Asset | null): UpdateAssetInput | undefined => {
    if (!a)
      return undefined;

    return {
      id: a.id,
      displayName: a.displayName,
      filename: a.filename
    } as UpdateAssetInput
  };

  const assetToComponentAssetUpdateInput = (c: Component, a?: Asset | null): ComponentAssetInput => {

    return {
      asset: assetToAssetUpdateInput(c, a),
      component: {
        id: c.id,
        name: c.name,
        componentType: c.componentType!
      }
    }
  };

  const dataSourceToUpdateDataSourceInput = (ds: DataSource): UpdateDataSourceInput => {
    return {
      id: ds.id,
      name: ds.name,
      configuration: ds.configuration ? ds.configuration : '{}'
    }
  };

  const componentToComponentUpdateInput = (c: Component): UpdateComponentInput => {
    return {
      id: c.id,
      name: c.name,
      configuration: c.configuration,
      style: c.style,
      isTemplate: c.isTemplate,
      iconName: c.iconName,
      componentType: c.componentType!,
      classNames: c.classNames!,
      identifier: c.identifier!,
      previewImage: assetToAssetUpdateInput(c, c.previewImage),
      dataSource: c.dataSource ? dataSourceToUpdateDataSourceInput(c.dataSource) : undefined,
      assets: c.assets && c.assets.length > 0 ? c.assets.map(a => assetToComponentAssetUpdateInput(c, a)) : undefined
    }
  };


  const pageComponentToUpdatePageComponentInput = (pc: PageComponent | null): PageComponentInput => {

      return {
        pageId: pc!.page!.id,
        active: true,
        height: pc!.height,
        width: pc!.width,
        horizontalAlign: pc!.horizontalAlign,
        verticalAlign: pc!.verticalAlign,
        transform: pc!.transform,
        transformOrigin: pc!.transformOrigin,
        component: componentToComponentUpdateInput(pc!.component!)
      }
  };

  const updatePage = async () => {
    if (!page)
      return;

    const updatePageInput = {
      id: page.id,
      name: page.name,
      components: page.components!.map((pc:PageComponent | null) => pageComponentToUpdatePageComponentInput(pc))
    };

    console.log('updating page', updatePageInput);

    await updatePageMutation({
      variables: {
        input: updatePageInput
      }
    })
      .then(async value => {

        setMessageType(MessageBarType.success);
        setMessage('Changes successfully saved');

        await reload();
      })
      .catch(e => {
        setMessageType(MessageBarType.error);
        setMessage(e.toString());
        setSelectedComponent(undefined);
      });
  };

  useEffect(() => {
    if (isDirty) {
      setMessageType(MessageBarType.warning);
      setMessage('Changes have not been saved');
    } else {
      setMessageType(MessageBarType.info);
      setMessage(defaultMessage);
    }
  }, [isDirty]);

  useEffect(() => {
    if (!data)
      return;

    const d = data.device as Device;
    const dc = data.deviceContext as DeviceContext;
    const p = dc.controller!.presentations![0]!.pages![0]!;

    setDevice(d);
    setDeviceContext(dc);
    setPage(p);
  }, [data]);

  if (loading || !device || !page) {
    return <Loading/>
  }

  if (error) {
    return <p>Error</p>
  }

  const reload = async () => {
    await refetch();
    setDirty(false);
    setSelectedComponent(undefined);
    setLastUpdated(new Date());
  };

  if (!deviceContext || deviceContext?.controller?.isSystem)
  {
    return <Loading />
  }

  return <>
    <Stack tokens={stackTokens}>
      <PageHeading heading={device.displayName}/>
      <DeviceCommandBar
        device={device}
        refetch={refetch}
        onUpdatePage={() => updatePage()}
        onCancelEdit={async () => {
          await reload();
        }}
        isDirty={isDirty}
        onTogglePanel={setCurrentPanel}
        onToggleAddComponentPanel={toggleShowEditComponentPanel}/>

        {message !== '' &&
          <MessageBar messageBarType={messageType}>
            {message}
          </MessageBar>
        }

      <Stack
        horizontal
        horizontalAlign="space-between"
        tokens={stackTokens}>

        <DeviceProperties device={device}/>
        <DeviceScreenShot
          mode={DeviceScreenShotMode.Thumbnail}
          device={device}
          triggerRefreshOnLoad={triggerRefreshScreenShot}
          refreshDoneCallback={() => setTriggerRefreshScreenShot(false)}
        />

      </Stack>


    </Stack>

        {(() => {
          switch(currentPanel) {
            case DeviceDetailPanel.PageEditor:
              return  <PageEditor
                lastUpdated={lastUpdated}
                device={device}
                components={page.components! as PageComponent[]}
                deviceContext={deviceContext}
                onRequestRefresh={refetch}
                selectedComponent={selectedComponent}
                onAddComponent={c => {
                  const newPageComponent = {
                    page: page,
                    transform: Rematrix.toString(Rematrix.identity()),
                    component: c
                  };
                  setSelectedComponent(newPageComponent);
                  setPage(prevState => {
                    return {
                      ...prevState,
                      components: page.components!.concat(newPageComponent)
                    }
                  });
                  setDirty(true);
                }}
                onRemoveComponent={componenToRemove => {
                  setPage(prevState => {
                    return {
                      ...prevState,
                      components: prevState.components!.filter(c => c?.component!.id !== componenToRemove.id)
                    }
                  });
                  setDirty(true);
                }}
                onEditComponent={(component) => {
                  setSelectedComponent(component);
                  toggleShowEditComponentPanel(true)
                }}
                onChanged={() => setDirty(true)}
              />;
            case DeviceDetailPanel.DeviceVisualizer:
              return <DeviceVisualizer
                device={device}
                deviceContext={deviceContext}
              />;
          }
        })()}

    {selectedComponent &&
      <EditComponentPanel
        device={device}
        page={page}
        pageComponent={selectedComponent}
        isOpen={showEditComponentPanel}
        onCancel={() => {
          toggleShowEditComponentPanel(false);
        }}
        onUpdateAndDismiss={(pc) => {
          setPage(prevState => {
            return {
              ...prevState,
              components: [...prevState.components!.filter(o => o?.component!.id !== pc.component!.id), pc]
            }
          });
          toggleShowEditComponentPanel(false);
          setDirty(true);
        }}
      />
    }

  </>
};

export default withRouter(DeviceDetailPage);
