// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {Slider} from "@fluentui/react";
import {useTranslation} from "react-i18next";
import {IDexcomDataSourceConfiguration} from "pixon-component-core";

/* Local */
import DefaultDataSourceEditorGroup from "./defaultDataSourceEditorGroup";
import {IDataSourceEditorProps, useDataSourceConfiguration} from "./dataSourceEditor";

// ----------------------------------------------------------------------------

const DexcomDataSourceEditorGroup = (props: IDataSourceEditorProps) => {
  const {dataSource, onChange} = props;
  const {t} = useTranslation();
  const [configuration, updateConfigurationProperty] = useDataSourceConfiguration<IDexcomDataSourceConfiguration>(onChange, dataSource);

  return  <>

    <DefaultDataSourceEditorGroup
      showAssetsEditor={false}
      showOAuthEditor={true}
      {...props}>



      <Slider
        label={t('component:DexcomDataSourceEditorGroup.intervalLabel')}
        min={1}
        max={60}
        step={1}
        defaultValue={configuration ? configuration.refreshIntervalInMinutes : 5}
        showValue={true}
        valueFormat={(value: number) => `${value}m`}
        onChange={(value: number) => updateConfigurationProperty({refreshIntervalInMinutes: value})}
      />


    </DefaultDataSourceEditorGroup>

  </>
};

export default DexcomDataSourceEditorGroup;
