// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  DocumentCard,
  DocumentCardActions, DocumentCardImage,
  DocumentCardLocation,
  DocumentCardTitle,
  ImageFit,
  MessageBar,
  MessageBarType,
  Stack,
} from "@fluentui/react";

/* Local */
import Loading from "../../components/layout/loading";
import {getOrganizations, getOrganizationsError, getOrganizationsAsyncStatus} from "../../store/organization/reducers";
import {AsyncActionStatus} from "../../store/asyncAction/types";
import {loadOrganizationsAction} from "../../store/organization/actions";
import {Organization} from "../../graphql/types";
import {RouteComponentProps} from "react-router";
import {withRouter} from "react-router-dom";
import {getAssetUrl} from "../../components/api/util";

// ----------------------------------------------------------------------------
// TYPES

interface OrganizationsCardListProps extends RouteComponentProps {
  onEditClick: (organization: Organization) => void;
  onDeleteClick: (organization: Organization) => void;
}

// ----------------------------------------------------------------------------
// CONSTANTS

const tokens = {
  tenGapStack: {
    childrenGap: 10
  }
};

// ----------------------------------------------------------------------------
// COMPONENTS

const OrganizationsCardList = (props: OrganizationsCardListProps) => {
  // Hook into redux store information (important to load after useEffect)
  const dispatch = useDispatch();
  const organizations = useSelector(getOrganizations);
  const loadStatus = useSelector(getOrganizationsAsyncStatus);
  const loadError = useSelector(getOrganizationsError);

  // Load organizations if not already loaded
  useEffect(() => {
    if (loadStatus === AsyncActionStatus.UNSTARTED)
    {
      dispatch(loadOrganizationsAction());
    }
  });

  // Show spinner until done
  if (loadStatus === AsyncActionStatus.UNSTARTED || loadStatus === AsyncActionStatus.STARTED) {
    return <Loading/>
  }

  // Show message bar if something went wrong
  if (loadStatus === AsyncActionStatus.FAILED) {
    return (
      <MessageBar messageBarType={MessageBarType.error} >
        An error occured when retrieving the organizations: {loadError?.message}
      </MessageBar>
    )
  }

  const getIconProps = (organization: Organization) => organization.logotype ? undefined : {
    iconName: 'Suitcase',
    styles: { root: { color: '#5fb0dc', fontSize: '120px', width: '120px', height: '120px' } }
  };

  const getLogoUrl = (organization: Organization) => organization.logotype ? getAssetUrl(organization.logotype) : undefined;

  // Show organizations cards
  return <Stack horizontal wrap tokens={tokens.tenGapStack}>
    {organizations.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map((o, i) => (


      <DocumentCard key={i} onClick={() => props.history.push('/organization/' + o.id)} >
        <DocumentCardImage
          height={150}
          imageFit={ImageFit.centerContain}
          iconProps={getIconProps(o)}
          imageSrc={getLogoUrl(o)}
        />
        <DocumentCardTitle title={o.name} shouldTruncate={true}/>

        { o.officeAddress &&
          <DocumentCardLocation
            location={o.officeAddress.addressLine1 + ', ' + o.officeAddress.city.name}
            locationHref={"https://www.google.com/maps/place/" + o.officeAddress.addressLine1}
            ariaLabel={o.officeAddress.addressLine1 + ', ' + o.officeAddress.city.name}
          />
        }

        <DocumentCardActions
          actions={[
            {
              iconProps: {iconName: 'Delete'},
              onClick:  (ev) => {
                props.onDeleteClick(o);
                ev.stopPropagation();
                ev.preventDefault();
              },
              ariaLabel: 'delete action'
            },
            {
              iconProps: {iconName: 'Edit'},
              onClick: (ev) =>{
                props.onEditClick(o);
                ev.stopPropagation();
                ev.preventDefault();
              },
              ariaLabel: 'edit action'
            }
          ]}
        />

      </DocumentCard>
    ))}
  </Stack>
};

export default withRouter(OrganizationsCardList);
