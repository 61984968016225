// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";

/* Local */
import PageHeading from "../../components/layout/pageHeading";

// ----------------------------------------------------------------------------

class PresentationsPageIndex extends React.PureComponent<{}, {}> {
  public componentDidMount = async () => {};

  public render() {
    return (
      <>
        <PageHeading heading="Connections" />
      </>
    );
  }
}

export default PresentationsPageIndex;
