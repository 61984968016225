import * as React from 'react';

import {
  Dropdown, IDropdownOption
} from "@fluentui/react";

import {useTranslation} from "react-i18next";
import {DataSourceTypeEnum} from "../graphql/types";
import {useState} from "react";

export interface IDataSourceTypeDropDownProps {
  onChange: (selected:DataSourceTypeEnum) => void;
  defaultSelected?: DataSourceTypeEnum
}

const DataSourceTypeDropDown = (props: IDataSourceTypeDropDownProps) => {
  const { t } = useTranslation();
  const {defaultSelected, onChange} = props;
  const [selected, setSelected] = useState<IDropdownOption>();

  const options = Object.entries(DataSourceTypeEnum)
    .map<IDropdownOption>(([k,v]) => ({
      key: v,
      text: k
    }));

  return <Dropdown
    label={t('common:dataSourceTypeDropdown.label')}
    placeholder={t('common:dataSourceTypeDropdown.placeholder')}
    options={options}
    selectedKey={selected ? selected.key : defaultSelected}
    onChange={(e, option) => {
      setSelected(option);
      onChange(option?.key as DataSourceTypeEnum);
    }}
  />

};

export default DataSourceTypeDropDown;
