// NPM
import React from "react";
import {useTranslation} from "react-i18next";
import {
  Panel,
  PanelType,
} from "@fluentui/react";

// Local
import {HomeScreenInformation} from "../../graphql/types";

import GetStartedWizard from "./getStartedWizard";

interface IGetStartedPanelProps {
  isOpen: boolean;
  onDismiss: () => void;
  info: HomeScreenInformation
}

const GetStartedPanel = (props: IGetStartedPanelProps) => {
  const {
    onDismiss,
    info
  } = props;
  const {t} = useTranslation();

  return (
    <Panel
      type={PanelType.medium}
      headerText={t('home:getStartedHeading')}
      closeButtonAriaLabel={t('common:button.close')}
      isOpen={props.isOpen}
      onDismiss={onDismiss}
      layerProps={
        {eventBubblingEnabled: true}
      }
      isBlocking={false}
    >
      <GetStartedWizard info={info} />
    </Panel>
  )
};

export default GetStartedPanel;
