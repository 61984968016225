import {ADD_ADDRESS, AddressActionTypes, AdressesState, DELETE_ADDRESS, LOAD_ADDRESS,} from "./types";
import {AppState} from "../index";
import {AsyncActionStatus} from "../asyncAction/types";

const initialState: AdressesState = {
  entities: [],
  loadStatus: AsyncActionStatus.UNSTARTED,
  error: undefined
};

// See https://dev.to/markusclaus/fetching-data-from-an-api-using-reactredux-55ao
export function addressesReducer(
  state = initialState,
  action: AddressActionTypes
): AdressesState {
  switch (action.type) {
    case LOAD_ADDRESS:
      switch (action.status) {
        case AsyncActionStatus.SUCCEEDED:
          return {
            ...state,
            loadStatus: action.status,
            entities: [...state.entities, action.payload]
          };
        case AsyncActionStatus.FAILED:
          return {
            ...state,
            loadStatus: action.status,
            error: action.payload
          };
        default:
          return state;
      }
    case ADD_ADDRESS:
      return state
      // {
      //   ...state,
      //   entities: [...state.entities, action.payload]
      // };
    case DELETE_ADDRESS:
      return {
        ...state,
        entities: state.entities.filter(o => o.id !== action.id)
      };
    default:
      return state;
  }
}

// Selectors
export const getAddresses = (state:AppState) => state.addresses.entities;
export const getAddressesLoadStatus = (state:AppState) => state.addresses.loadStatus;
export const getAdressesError = (state:AppState) => state.addresses.error;
