// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import React, {useRef, useState} from "react";

import {
  Callout,
  DatePicker,
  DayOfWeek,
  DefaultButton,
  IDatePickerStrings,
  ITag,
  Label,
  Stack,
  TagPicker,
  TextField
} from "@fluentui/react";
/* Local */
import SubHeading from "../../components/layout/subHeading";
import {useTranslation} from 'react-i18next';
import AuditTrailList from "./auditTrailList";
import {AuditEntriesQueryVariables, AuditEntry, AuditEntryState, User} from "../../graphql/types";
import AuditEntryDiff from "./auditEntryDiff";
import UserPicker from "../../components/userPicker";
// ----------------------------------------------------------------------------


const tokens = {
  themedMedium: {
    childrenGap: 'm'
  },
  themedLarge: {
    childrenGap: '10',
    margin: 10
  }
};

const StateTags: ITag[] =
  Object.keys(AuditEntryState)
    .map( k => ({key: AuditEntryState[k as keyof typeof AuditEntryState], name: k}));

const EntityTypeTags: ITag[] = [
  'Address',
  'Asset',
  'City',
  'Component',
  'ComponentDataSource',
  'Country',
  'Device',
  'DeviceController',
  'DeviceLinkTicket',
  'Integration',
  'Layout',
  'Organization',
  'Page',
  'Presentation',
  'Subdivision',
  'Transition',
  'User',
  'UserIdentity'
].map(item => ({key: item, name: item}));

const EntitySetTags: ITag[] = [
  'AuditEntries',
  'AuditEntryProperties',
  'Assets',
  'Addresses',
  'Cities',
  'ComponentDataSources',
  'Countries',
  'Devices',
  'DeviceLinkTickets',
  'DeviceControllers',
  'Integrations',
  'Organizations',
  'Pages',
  'Components',
  'Layouts',
  'Presentations',
  'Subdivisions',
  'Transitions',
  'Users',
  'UserIdentities',
  'DeviceControllerPresentation',
  'PagePresentation',
  'PageComponent',
  'ComponentAsset',
  'ComponentDataSourceAsset',
].map(item => ({key: item, name: item}));

function addDays(date: Date, days: number) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

const AuditTrail = () => {
  const {t} = useTranslation();
  const [isFilterCalloutVisible, setIsFilterCalloutVisible] = useState(false);
  const [lastUpdated] = useState(new Date());
  const [selectedEntry, setSelectedEntry] = useState<AuditEntry>();
  const [filter, setFilter] = useState<AuditEntriesQueryVariables>({
    input: {
      from: addDays(new Date(), -1),
      to: new Date(),
    }
  });
  const filterButtonElement = useRef(null);

  const DayPickerStrings: IDatePickerStrings = {
    months: [
      t('common:date.January'),
      t('common:date.February'),
      t('common:date.March'),
      t('common:date.April'),
      t('common:date.May'),
      t('common:date.June'),
      t('common:date.July'),
      t('common:date.August'),
      t('common:date.September'),
      t('common:date.October'),
      t('common:date.November'),
      t('common:date.December')
    ],

    shortMonths: [
      t('common:date.Jan'),
      t('common:date.Feb'),
      t('common:date.Mar'),
      t('common:date.Apr'),
      t('common:date.May'),
      t('common:date.Jun'),
      t('common:date.Jul'),
      t('common:date.Aug'),
      t('common:date.Sep'),
      t('common:date.Oct'),
      t('common:date.Nov'),
      t('common:date.Dec')
    ],

    days: [
      t('common:date.Monday'),
      t('common:date.Tuesday'),
      t('common:date.Wednesday'),
      t('common:date.Thursday'),
      t('common:date.Friday'),
      t('common:date.Saturday'),
      t('common:date.Sunday')
      ],

    shortDays: [
      t('common:date.M'),
      t('common:date.Tu'),
      t('common:date.W'),
      t('common:date.Th'),
      t('common:date.F'),
      t('common:date.Sa'),
      t('common:date.Su')
    ],

    goToToday: t('common:date.gotoToday'),
    prevMonthAriaLabel: t('common:date.gotoPreviousMonth'),
    nextMonthAriaLabel: t('common:date.gotoNextMonth'),
    prevYearAriaLabel: t('common:date.gotoPreviousYear'),
    nextYearAriaLabel: t('common:date.gotoNextYear'),
    closeButtonAriaLabel: t('common:date.gotoCloseDatePicker'),
  };

  const onItemSelected = (item?:ITag) => {
    if (item
      && filter
      && filter.input
      && filter.input.createdBy
      && filter.input.createdBy.indexOf(item.key.toString()) >= 0)
    {
      return null;
    }
    return item!;
  };

  const onResolveSuggestions = (filterText: string, items: ITag[], selectedItems?: ITag[]) => {
    return filterText ? items.filter(tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0) : []
  };

return (
    <Stack tokens={tokens.themedLarge} styles={{
      root: {
        height: '100%',
        width: '100%',
      }
    }}>

      <Stack.Item>
        <div style={{display: 'flex', alignItems: 'stretch', height: '40px'}}>
          <SubHeading heading={t('debug:auditTrail.header')}/>
        </div>
        <p>
          {t('debug:auditTrail.description')}
        </p>

        <SubHeading heading={t('debug:auditTrail.filter')}/>

        <Stack horizontal tokens={tokens.themedMedium}>
          <DatePicker
            label={t('debug:auditTrail.filterFromLabel')}
            firstDayOfWeek={DayOfWeek.Monday}
            strings={DayPickerStrings}
            placeholder={t('debug:auditTrail.filterFromPlaceholder')}
            ariaLabel={t('debug:auditTrail.filterFromAriaLabel')}
            value={filter.input!.from}
            onSelectDate={(d) => {
              setFilter({
                ...filter,
                input: {
                  ...filter.input,
                  from: d
                }
              })
            }}
          />
          <DatePicker
            label={t('debug:auditTrail.filterToLabel')}
            firstDayOfWeek={DayOfWeek.Monday}
            strings={DayPickerStrings}
            placeholder={t('debug:auditTrail.filterToPlaceholder')}
            ariaLabel={t('debug:auditTrail.filterToAriaLabel')}
            value={filter.input!.to}
            onSelectDate={(d) => {
              setFilter({
                ...filter,
                input: {
                  ...filter.input,
                  to: d
                }
              })
            }}/>

          <div>
            <Label htmlFor='users'>{t('debug:auditTrail.filterUsersLabel')}</Label>
            <UserPicker onChange={ (items:User[]) => {
              setFilter({
                ...filter,
                input: {
                  ...filter.input,
                  createdBy: items && items.length > 0 ? items.map(i => i.externalUserId!) : undefined
              }
            })}} />
          </div>

          <div ref={filterButtonElement}>
            <Label htmlFor='filterButton'>{t('debug:auditTrail.filterMoreLabel')}</Label>
            <DefaultButton id={'filterButton'} onClick={() => setIsFilterCalloutVisible(!isFilterCalloutVisible)}
                           text={isFilterCalloutVisible ? t('common:button.hide') : t('common:button.show') }/>
          </div>

          {isFilterCalloutVisible &&
          <Callout
              target={filterButtonElement.current}
              onDismiss={() => setIsFilterCalloutVisible(false)}
              setInitialFocus={true}
          >
              <Stack tokens={tokens.themedMedium} styles={{
                root: {
                  margin: 20
                }
              }}>

                  <div>
                      <Label htmlFor='states'>{t('debug:auditTrail.filterStateLabel')}</Label>
                      <TagPicker
                          onResolveSuggestions={ (ft, s) => onResolveSuggestions(ft, StateTags, s) }
                          inputProps={{ id: 'states' }}
                          onItemSelected={onItemSelected}
                          onChange={(items) => {
                           setFilter({
                             ...filter,
                             input: {
                               ...filter.input,
                               states: items ? items.map(i => i.key as AuditEntryState) : undefined
                             }
                           })
                          }}
                      />
                  </div>

                  <div>
                      <Label htmlFor='entitySets'>{t('debug:auditTrail.filterEntitySetsLabel')}</Label>
                      <TagPicker
                          onResolveSuggestions={ (ft, s) => onResolveSuggestions(ft, EntitySetTags, s) }
                          inputProps={{ id: 'entitySets' }}
                          onItemSelected={onItemSelected}
                          onChange={(items) => {
                           setFilter({
                             ...filter,
                             input: {
                               ...filter.input,
                               entitySetNames: items ? items.map(i => i.key.toString()) : undefined
                             }
                           })
                          }}
                      />
                  </div>

                  <div>
                      <Label htmlFor='entityTypes'>{t('debug:auditTrail.filterEntityTypesLabel')}</Label>
                      <TagPicker
                          onResolveSuggestions={ (ft, s) => onResolveSuggestions(ft, EntityTypeTags, s) }
                          inputProps={{ id: 'entityTypes'}}
                          onItemSelected={onItemSelected}
                          onChange={(items) => {
                           setFilter({
                             ...filter,
                             input: {
                               ...filter.input,
                               entityTypeNames: items ? items.map(i => i.key.toString()) : undefined
                             }
                           })
                          }}
                      />
                  </div>

                  <div>
                      <Label htmlFor='excludeEntityTypes'>{t('debug:auditTrail.filterExcludeEntityTypesLabel')}</Label>
                      <TagPicker
                          onResolveSuggestions={ (ft, s) => onResolveSuggestions(ft, EntityTypeTags, s) }
                          inputProps={{ id: 'excludeEntityTypes'}}
                          onItemSelected={onItemSelected}
                          onChange={(items) => {
                            setFilter({
                              ...filter,
                              input: {
                                ...filter.input,
                                excludeEntityTypeNames: items ? items.map(i => i.key.toString()) : undefined
                              }
                            })
                          }}
                      />
                  </div>

                  <TextField
                      label={t('debug:auditTrail.filterEntityIdLabel')}
                      onChange={(event, newValue) =>
                        setFilter({
                          ...filter,
                          input: {
                            ...filter.input,
                            entityIds: (newValue && newValue.length > 0) ? [ newValue ] : undefined
                          }
                        })
                      }
                  />
              </Stack>

          </Callout>
          }
        </Stack>

      </Stack.Item>

      <Stack.Item>
        <SubHeading heading={t('debug:auditTrail.entriesHeading')}/>
        <AuditTrailList lastUpdated={lastUpdated} filter={filter} onSelectEntry={(e) => {
          setSelectedEntry(e);
        }}/>
      </Stack.Item>

      {selectedEntry &&
        <>
          <Stack.Item>
            <SubHeading heading={t('debug:auditTrail.selectedDiffHeading')}/>
          </Stack.Item>
          <Stack.Item grow={3}>
            <AuditEntryDiff entry={selectedEntry}/>
          </Stack.Item>
        </>
      }

    </Stack>
  );
};

export default AuditTrail;
