import * as React from "react";
import {Organization} from "../../graphql/types";
import {deleteOrganization} from "../../store/organization/actions";
import DeletePanel from "../../components/deletePanel";
import {useTranslation} from "react-i18next";

interface DeleteOrganizationPanelProps {
  isOpen: boolean;
  organization?: Organization,
  onDismiss: any;
}

const DeleteOrganizationPanel = (props: DeleteOrganizationPanelProps) => {
  const { t } = useTranslation();
  const validate = (input: string): boolean => input === props.organization!.name;

  if (!props.organization)
    return null;

  return <DeletePanel
    headerText={t('organizations:deletepanel.header')}
    label={t('organizations:deletepanel.label')}
    validate={validate}
    deleteAction={deleteOrganization(props.organization.id!)}
    onDismiss={props.onDismiss}
    isOpen={props.isOpen}
    validationText={props.organization.name}
     />
};

export default DeleteOrganizationPanel;
