// Npm
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from 'react-i18next';
import {withRouter} from "react-router-dom";
import {
  ContextualMenuItemType,
  IconButton,
  IPersonaSharedProps,
  Persona,
  PersonaPresence,
  PersonaSize,
  IContextualMenuItem,
} from "@fluentui/react";

// Local
import { useKeycloak } from '@react-keycloak/web'
import '../../assets/customIcons';
import {setUiLanguage} from "../../store/ui/actions";
import {getUiLanguage} from "../../store/ui/reducers";
import {useEffect, useState} from "react";

const UserMenu = withRouter(({history}) => {
  // Hook into redux store information (important to load after useEffect)
  const dispatch = useDispatch();
  const uiLanguage = useSelector(getUiLanguage);
  const {t,i18n} = useTranslation();
  const { keycloak } = useKeycloak();
  const [userProfile, setUserProfile] = useState<any | undefined>();

  useEffect(() => {
    if (uiLanguage)
    {
      i18n.changeLanguage(uiLanguage);
    }
  }, [i18n, uiLanguage]);


  useEffect(() => {
    if (keycloak)
    {
      keycloak
        .loadUserInfo()
        .then(userInfo => {
          setUserProfile(userInfo);
        })
        .catch(e => {
          console.log('could not load user info', e);
        });
    }
  }, [keycloak]);

  if (!keycloak.authenticated || !userProfile) {
    return null;
  }

  const userPersona: IPersonaSharedProps = {
    imageUrl: undefined,
    text: userProfile.name,
    secondaryText: userProfile.preferred_username,
  };

  const logoutWithRedirect = () => {
    keycloak.logout({
      redirectUri: window.location.origin
    });
  }

  const _renderMenuList = (props: any, defaultRender: any, persona: IPersonaSharedProps) => {
    const styles = {
      root: [
        {
          margin: '10px'
        }
      ]
    };

    return (
      <>
        <Persona
          {...persona}
          hidePersonaDetails={false}
          presence={PersonaPresence.none}
          styles={styles}
        />

        {defaultRender(props)}
      </>
    );
  };

  const menuItems: IContextualMenuItem[] = [
    {
      key: "divider_1",
      itemType: ContextualMenuItemType.Divider
    },
    {
      key: "support",
      text: t('common:usermenu.support'),
      iconProps: {
        iconName: "UnknownSolid"
      },
      href: "mailto:support@rduce.net"
    },
    {
      key: "documentation",
      text: t('common:usermenu.documentation'),
      iconProps: {
        iconName: "DocumentSearch"
      },
      href: "https://docs.pixon.app",
      target: "_blank"
    },
    {
      key: "divider_2",
      itemType: ContextualMenuItemType.Divider
    },
    {
      key: 'langageSv',
      text: t('common:usermenu.language.swedish'),
      title: 'Svenska',
      iconProps: {
        iconName: "FlagSV"
      },
      onClick: () => { dispatch(setUiLanguage('sv')) }
    },
    {
      key: 'languageEn',
      text: t('common:usermenu.language.english'),
      title: 'English',
      iconProps: {
        iconName: "FlagEN"
      },
      onClick: () => { dispatch(setUiLanguage('en')) }
    },
    {
      key: "divider_3",
      itemType: ContextualMenuItemType.Divider
    },
    {
      key: "signout",
      text: t('common:usermenu.signout'),
      iconProps: {
        iconName: "SignOut"
      },
      onClick: () => logoutWithRedirect()
    }
  ];

  if (keycloak.hasRealmRole('developer')) {
    menuItems.push({
      key: "debug",
      text: t('common:usermenu.debug'),
      iconProps: {
        iconName: "Bug"
      },
      onClick: () => history.push("/debug")
    });
  }

  return (
      <IconButton
        menuProps={{
          shouldFocusOnMount: true,
          items: menuItems,
          onRenderMenuList: (props: any, defaultRender: any) => _renderMenuList(props, defaultRender, userPersona)
        }}
      >
        <Persona
          {...userPersona}
          size={PersonaSize.size48}
          hidePersonaDetails={true}
          presence={PersonaPresence.none}
        />
      </IconButton>);
});

export default UserMenu;
