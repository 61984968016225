// NPM
import * as React from "react";
import {useTranslation} from "react-i18next";

// Local
import {Device} from "../../graphql/types";
import {unlinkDevice} from "../../store/device/actions";
import DeletePanel from "../../components/deletePanel";

interface DeleteDevicePanelProps {
  isOpen: boolean;
  device?: Device,
  onDismiss: any;
}

const DeleteDevicePanel = (props: DeleteDevicePanelProps) => {
  const { t } = useTranslation();
  const validate = (input: string): boolean => input === props.device!.displayName;

  if (!props.device)
    return null;

  return <DeletePanel
    headerText={t('devices:deletepanel.header')}
    label={t('devices:deletepanel.label')}
    validate={validate}
    deleteAction={unlinkDevice(props.device.id!)}
    onDismiss={props.onDismiss}
    isOpen={props.isOpen}
    validationText={props.device.displayName}
     />
};

export default DeleteDevicePanel;
