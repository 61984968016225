// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import React from "react";
import { useSelector} from "react-redux";
import { Link, Stack} from "@fluentui/react";
import { useTranslation  } from 'react-i18next';

/* Local */
import { useKeycloak } from '@react-keycloak/web';
import Loading from "../../components/layout/loading";
import DebugEditorView from "./debugEditorView";
import {getAccessToken } from "../../store/ui/reducers";

// ----------------------------------------------------------------------------
const UserInformation = () => {
  const { t } = useTranslation();
  const accessToken = useSelector(getAccessToken);

  const { keycloak } = useKeycloak();
    const tokens = {
    themedMedium: {
      childrenGap: 'm'
    }
  };

  if (!keycloak.userInfo) {
    return <Loading/>;
  }

  const decodeJwtToken = (jwtToken: string) => {
    let jwtData = jwtToken.split('.')[1];
    let decodedJwtJsonData = window.atob(jwtData);
    return JSON.parse(decodedJwtJsonData);
  };

  return (
    <Stack tokens={tokens.themedMedium}>
      <DebugEditorView heading={t('debug:userinfo.userprofile.headline') } value={keycloak.userInfo}>
        <p>
          { t('debug:userinfo.userprofile.description') }
        </p>
      </DebugEditorView>

      <DebugEditorView heading={t('debug:userinfo.accesstoken.headline') } value={keycloak?.token ? decodeJwtToken(keycloak.token) : ''} clipBoardValue={JSON.stringify(
        {
          Authorization: "Bearer " + accessToken
        }, null, 2)}>
        <p>
          { t('debug:userinfo.accesstoken.description') } <Link
          href="https://manage.auth0.com/dashboard/eu/rduce/roles">{ t('debug:userinfo.usermanagementinterface') }</Link>.
        </p>
      </DebugEditorView>

    </Stack>
  )
};


export default UserInformation;
