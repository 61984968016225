// NPM
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {MaskedTextField, TextField} from "@fluentui/react";

// Local
import MutatePanel from "../../components/mutatePanel";
import SubHeading from "../../components/layout/subHeading";
import AddressField from "../../components/addressField";

import {
  Organization,
  OrganizationUpdateInput,
  useOrganizationQuery
} from "../../graphql/types";

import {updateOrganization} from "../../store/organization/actions";
import {getOrganizationsAsyncStatus, getOrganizationsError} from "../../store/organization/reducers";
import Loading from "../../components/layout/loading";
import {UpdateOrganizationValidationSchema} from "../../data/validation/updateOrganizationSchema";

interface EditOrganizationPanelProps {
  isOpen: boolean;
  onDismiss: () => void;
  organizationId?: string;
}

const EditOrganizationPanel = (props: EditOrganizationPanelProps) => {
  const {organizationId, isOpen, onDismiss} = props;
  const { t } = useTranslation();

  // Validation and api call status objects
  const [input, setInput] = useState<OrganizationUpdateInput>({} as OrganizationUpdateInput);
  const { loading, error, data }= useOrganizationQuery({
    variables: {
      id: organizationId!
    }
  });

  //Whenever the selected value changes, update form values
  useEffect(() => {
    if (!data || !data.organization)
      return;

    const organization = data.organization as Organization;

    setInput({
      id: organizationId!,
      name: organization.name,
      vatNumber: organization.vatNumber || undefined,
      organizationNumber: organization.organizationNumber|| undefined,
      billingEmail: organization.billingEmail|| undefined,
      contactEmail: organization.contactEmail|| undefined,
      phoneNumber: organization.phoneNumber|| undefined,
      url: organization.url|| undefined,
      billingAddress: organization.billingAddress,
      officeAddress: organization.officeAddress
    });
  }, [data, organizationId]);

  if (loading || error) {
    return <Loading/>;
  }

  if (!organizationId)
    return null;

  return (
    <MutatePanel
      headerText={t('organizations:editorganizationpanel.headertext')}
      isOpen={isOpen}
      onDismiss={onDismiss}
      input={input}
      objectId={organizationId as any}
      updateAction={updateOrganization}
      validationSchema={UpdateOrganizationValidationSchema}
      errorSelector={getOrganizationsError}
      statusSelector={getOrganizationsAsyncStatus}
    >

      <SubHeading heading={t('organizations:editorganizationpanel.subheadingname')} />
      <TextField required
                 autoFocus={true}
                 label={t('organizations:fields.namelabel')}
                 placeholder={t('organizations:fields.nameplaceholder')}
                 value={input.name}
                 onChange={(e,v) => setInput({
                   ...input,
                   name: v || ''
                 })}
      />
      <MaskedTextField
                 label={t('organizations:fields.vatlabel')}
                 placeholder={t('organizations:fields.vatplaceholder')}
                 mask="aa 999999999999"
                 value={input.vatNumber as any}
                 onChange={(e,v) => setInput({
                   ...input,
                   vatNumber: v
                 })}
      />
      <MaskedTextField label={t('organizations:fields.orgnumberlabel')}
                 placeholder={t('organizations:fields.orgnumberplaceholder')}
                 mask="999999-9999"
                 value={input.organizationNumber as any}
                 onChange={(e,v) => setInput({
                   ...input,
                   organizationNumber: v
                 })}
      />

      <SubHeading heading={t('organizations:editorganizationpanel.subheadingbilling')}/>
      <TextField required
                 label={t('organizations:fields.billingemaillabel')}
                 placeholder={t('organizations:fields.billingemailplaceholder')}
                 value={input.billingEmail as any}
                 onChange={(e,v) => setInput({
                   ...input,
                   billingEmail: v
                 })}
      />
      <AddressField required
                    label={t('organizations:fields.billingaddresslabel')}
                    initialValue={input.billingAddress as any}
                    onAddressSelected={(address) => setInput({
                      ...input,
                      billingAddress: address
                    })} />

      <SubHeading heading={t('organizations:editorganizationpanel.subheadingcontact')} />
      <AddressField label={t('organizations:fields.officeaddresslabel')}
                    initialValue={input.officeAddress as any}
                    onAddressSelected={(address) => setInput({
                      ...input,
                      officeAddress: address
                    })} />
      <TextField label={t('organizations:fields.emailcontactlabel')}
                 placeholder={t('organizations:fields.emailcontactplaceholder')}
                 value={input.contactEmail as any}
                 onChange={(e,v) => setInput({
                   ...input,
                   contactEmail: v
                 })}
      />
      <TextField label={t('organizations:fields.officephonelabel')}
                 placeholder={t('organizations:fields.officephoneplaceholder')}
                 value={input.phoneNumber as any}
                 onChange={(e,v) => setInput({
                   ...input,
                   phoneNumber: v
                 })}
      />
      <TextField label={t('organizations:fields.urllabel')}
                 placeholder={t('organizations:fields.urlplaceholder')}
                 value={input.url as any}
                 onChange={(e,v) => setInput({
                   ...input,
                   url: v
                 })}
      />
    </MutatePanel>
  )
};

export default EditOrganizationPanel;
