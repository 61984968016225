// ----------------------------------------------------------------------------
import * as React from 'react';

import {
  Stack,
  PersonaSize,
  PersonaPresence,
  Persona,
  IPersonaSharedProps
} from "@fluentui/react";

import Loading from "../../components/layout/loading";
import { useKeycloak } from '@react-keycloak/web'

const tokens = {
  tenGapStack: {
    childrenGap: 10
  }
};

const UserPageIndex = () => {
      const {keycloak} = useKeycloak();

      if (!keycloak.userInfo) {
        return <Loading/>;
      }

      const user:any = keycloak?.userInfo;

      const userPersona: IPersonaSharedProps = {
        imageUrl: undefined,
        text: user.name,
        secondaryText: user.preferred_username,
      };

      return (
          <>
            <Persona
                {...userPersona}
                size={PersonaSize.size100}
                presence={PersonaPresence.none}
            />

            <Stack tokens={tokens.tenGapStack} >
              <pre>{JSON.stringify(user, null, 2)}</pre>
            </Stack>
          </>
      );
    };

export default UserPageIndex;
