// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useState} from "react";
import {
  CommandBar,
  Stack,
} from "@fluentui/react";
import { useTranslation  } from 'react-i18next';

/* Local */
import DevicesCardList from "./devicesCardList";
import {Device} from "../../graphql/types";
import DeleteDevicePanel from "./deleteDevicePanel";
import LinkDevicePanel from "./linkDevicePanel";
import EditDevicePanel from "./editDevicePanel";

// ----------------------------------------------------------------------------

const DevicePageIndex = () => {
  const { t } = useTranslation();
  const [selectedDevice, setSelectedDevice] = useState<Device>();
  const [showDeleteDevicePanel, toggleDeleteDevicePanel] = useState(false);
  const [showEditDevicePanel, toggleShowEditDevicePanel] = useState(false);
  const [showLinkDevicePanel, toggleLinkDevicePanel] = useState(false);


  const commandBarItems = [
    {
      key: 'linkDevice',
      name: t('devices:commandbar.linkdevice'),
      cacheKey: 'linkDevice', // changing this key will invalidate this items cache
      iconProps: {
        iconName: 'Add'
      },
      ariaLabel: t('devices:commandbar.linkdevice'),
      onClick: () => toggleLinkDevicePanel(!showLinkDevicePanel),
    }
  ];

  return  (
    <>
      <Stack>
        <CommandBar items={commandBarItems} />
        <Stack.Item>
          <p id={showEditDevicePanel.toString()}>
            {t('devices:pagedescription')}
          </p>
        </Stack.Item>
        <DevicesCardList onEditClick={(device) => {
                          setSelectedDevice(device);
                          toggleShowEditDevicePanel(true);
                         }}
                         onDeleteClick={(device) => {
                           setSelectedDevice(device);
                           toggleDeleteDevicePanel(true)
                         }} />
      </Stack>

      <DeleteDevicePanel device={selectedDevice} isOpen={showDeleteDevicePanel} onDismiss={() => toggleDeleteDevicePanel(false)} />
      <LinkDevicePanel isOpen={showLinkDevicePanel} onDismiss={() => toggleLinkDevicePanel(false)} />
      {selectedDevice &&
        <EditDevicePanel deviceId={selectedDevice.id as any} isOpen={showEditDevicePanel}
                       onDismiss={() => toggleShowEditDevicePanel(false)}/>
      }
    </>
  );
};

export default DevicePageIndex;
