import React from "react";
import {Spinner, SpinnerSize} from "@fluentui/react";

const Loading = () => (
  <div className="spinner">
    <Spinner size={SpinnerSize.large} label="Loading..." />
  </div>
);

export default Loading;
