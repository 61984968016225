// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {
  Stack,
  ChoiceGroup, IStackTokens, Pivot, PivotItem,
  TextField
} from "@fluentui/react";
import {useTranslation} from "react-i18next";
import {IComponentPropertyEditorProps} from "./propertyEditor";
import CssPropertiesEditorGroup from "./cssPropertiesEditorGroup";
import SubHeading from "../../../components/layout/subHeading";
import DataSourceDropDown from "../../../components/dataSourceDropDown";
import {ReactNode} from "react";
import {Asset, AssetType, HorizontalAlign, VerticalAlign} from "../../../graphql/types";
import AssetsDropDown from "../../../components/assetsDropDown";
import IdField from "../../../components/idField";

/* Local */

interface IDefaultComponentEditorGroupProps extends IComponentPropertyEditorProps {
  children?: ReactNode
  themeChildren?: ReactNode

  showDataSourceEditor?: boolean;
  dataSourceTypes?: string[],

  showCssEditor?: boolean,
  showTemplateAssetEditor?: boolean

  showPositionEditor?: boolean,
  showClassNameProperty?: boolean,

  heading?: string
}


const tokens: IStackTokens = {
  childrenGap: 'm',
  padding: 's1'
};


// ----------------------------------------------------------------------------
const DefaultComponentEditorGroup = (props: IDefaultComponentEditorGroupProps) => {
  const {t} = useTranslation();
  const {
    pageComponent,
    onChange,
    onPageComponentChange,
    children,
    themeChildren,
    showDataSourceEditor = false,
    showPositionEditor = true,
    dataSourceTypes,
    showCssEditor = true,
    showTemplateAssetEditor = true,
    showClassNameProperty = false,
    heading = t('component:defaultComponentPropertiesEditorGroup.heading') ?? ''
  } = props;
  const component = pageComponent.component!;

  return <>

    <SubHeading heading={heading}/>

    <Pivot>
      <PivotItem headerText={t('component:defaultComponentPropertiesEditorGroup.pivotBaseHeading')}>
        <Stack tokens={tokens}>
          <TextField
            label={t('component:editTextTableComponent.nameLabel')}
            placeholder={t('component:editTextTableComponent.placeHolder')}
            autoFocus
            defaultValue={component.name}
            onChange={(e, v) => {
              onChange({name: v})
            }}/>

          <IdField id={component.id} />

          {showClassNameProperty &&
          <TextField
            label={t('component:defaultComponentPropertiesEditorGroup.classNamesLabel')}
            placeholder={t('component:defaultComponentPropertiesEditorGroup.classNamesPlaceholder')}
            autoFocus
            defaultValue={component.classNames ? component.classNames : undefined}
            onChange={(e, v) => {
              onChange({classNames: v})
            }}/>
          }

          {children}
        </Stack>
      </PivotItem>
      {showDataSourceEditor &&
      <PivotItem headerText={t('component:defaultComponentPropertiesEditorGroup.pivotDataSourceHeading')}>
          <Stack tokens={tokens}>
              <DataSourceDropDown
                  label={t('component:defaultComponentPropertiesEditorGroup.dataSourceLabel')}
                  placeholder={t('component:defaultComponentPropertiesEditorGroup.dataSourcePlaceholder')}
                  dataSourceTypes={dataSourceTypes}
                  onChange={(selectedDataSource) => onChange({dataSource: selectedDataSource})}
                  selected={component.dataSource ? component.dataSource : undefined}
              />
          </Stack>

      </PivotItem>
      }

      {showPositionEditor &&
      <PivotItem headerText={t('component:defaultComponentPropertiesEditorGroup.pivotPositioningHeading')}>
          <Stack tokens={tokens}>
              <ChoiceGroup
                  label={t('component:defaultComponentPropertiesEditorGroup.choiceHorizontalAlign')}
                  defaultSelectedKey={pageComponent.horizontalAlign as string}
                  onChange={(ev, option) => onPageComponentChange({horizontalAlign: option?.key as HorizontalAlign})}
                  options={[
                    {
                      key: HorizontalAlign.Left,
                      iconProps: {iconName: 'AlignHorizontalLeft'},
                      text: t('component:defaultComponentPropertiesEditorGroup.choiceHorizontalAlignLeft')
                    },
                    {
                      key: HorizontalAlign.Center,
                      iconProps: {iconName: 'AlignHorizontalCenter'},
                      text: t('component:defaultComponentPropertiesEditorGroup.choiceHorizontalAlignCenter')
                    },
                    {
                      key: HorizontalAlign.Right,
                      iconProps: {iconName: 'AlignHorizontalRight'},
                      text: t('component:defaultComponentPropertiesEditorGroup.choiceHorizontalAlignRight')
                    }
                  ]}
              />

              <ChoiceGroup
                  label={t('component:defaultComponentPropertiesEditorGroup.choiceVerticalAlign')}
                  defaultSelectedKey={pageComponent.verticalAlign as string}
                  onChange={(ev, option) => onPageComponentChange({verticalAlign: option?.key as VerticalAlign})}
                  options={[
                    {
                      key: VerticalAlign.Top,
                      iconProps: {iconName: 'AlignVerticalTop'},
                      text: t('component:defaultComponentPropertiesEditorGroup.choiceVerticalAlignTop')
                    },
                    {
                      key: VerticalAlign.Center,
                      iconProps: {iconName: 'AlignVerticalCenter'},
                      text: t('component:defaultComponentPropertiesEditorGroup.choiceVerticalAlignCenter')
                    },
                    {
                      key: VerticalAlign.Bottom,
                      iconProps: {iconName: 'AlignVerticalBottom'},
                      text: t('component:defaultComponentPropertiesEditorGroup.choiceVerticalAlignBottom')
                    }
                  ]}
              />
          </Stack>
      </PivotItem>
      }

      {(showCssEditor || showTemplateAssetEditor) &&
      <PivotItem headerText={t('component:defaultComponentPropertiesEditorGroup.pivotAppearanceHeading')}>
          <Stack tokens={tokens}>
              <SubHeading heading={t('component:defaultComponentPropertiesEditorGroup.themeHeading')}/>
            {showTemplateAssetEditor &&
            <>

                <AssetsDropDown
                    label={t('component:defaultComponentPropertiesEditorGroup.templateLabel')}
                    onChange={(a) => {
                      onChange({assets: [a]})
                    }}
                    assetTypes={[AssetType.HandlebarsTemplate]}
                    selected={component.assets ? component.assets[0] as Asset : undefined}
                />
            </>
            }
            {themeChildren}

            {showCssEditor &&
            <CssPropertiesEditorGroup {...props}/>
            }
          </Stack>
      </PivotItem>
      }
    </Pivot>


  </>
};

export default DefaultComponentEditorGroup;
