import * as React from 'react';

import {
  IBasePickerSuggestionsProps, IPersonaProps, NormalPeoplePicker, ValidationState
} from "@fluentui/react";

import {useTranslation} from "react-i18next";
import {Organization, useOrganizationsWithLogoQuery} from "../graphql/types";
import {useEffect, useState} from "react";
import {getAssetUrl} from "./api/util";

export interface IOrganizationPickerProps {
  onChange: (items: Organization[]) => void;
}

const OrganizationPicker = (props: IOrganizationPickerProps) => {
  const {
    onChange
  } = props;

  const {t} = useTranslation();
  const {loading, data, error} = useOrganizationsWithLogoQuery();

  let organizations = [] as Organization[];
  if (!loading && !error && data) {
    organizations = (data.organizations as Organization[]);
  }

  const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: t('common:organizationPicker.suggestionsHeaderText'), //'Suggested People'
    mostRecentlyUsedHeaderText: t('common:organizationPicker.mostRecentlyUsedHeaderText'), // 'Suggested Contacts',
    noResultsFoundText: t('common:organizationPicker.noResultsFoundText'), //'No results found',
    loadingText: t('common:organizationPicker.loadingText'), //'Loading',
    showRemoveButtons: true,
    suggestionsAvailableAlertText: t('common:organizationPicker.suggestionsAvailableAlertText'), //'People Picker Suggestions available',
    suggestionsContainerAriaLabel: t('common:organizationPicker.suggestionsContainerAriaLabel'), //'Suggested contacts'
  };

  const organizationsToPersonas = (userList:Organization[]): IPersonaProps[] =>
    userList.map(o => {
      return {
        imageUrl: o.logotype ? getAssetUrl(o.logotype) : null,
        isValid: true,
        key: o.id,
        //presence: 2,
        optionalText: o.organizationNumber,
        secondaryText: o.url,
        //tertiaryText: "In a meeting",
        text: o.name
      } as IPersonaProps
    });

  const personasToOrganizations = (personas: any[]): Organization[] =>
    personas.map(p => organizations.find(u => u.id === p.key!)!);

  const [organizationList, setOrganizationList] = useState<IPersonaProps[]>(organizationsToPersonas(organizations));
  const [mostRecentlyUsed, setMostRecentlyUsed] = useState<IPersonaProps[]>([]);

  useEffect(() => {
    if (!loading && !error && data) {
      setOrganizationList(organizationsToPersonas(data.organizations as Organization[]))
    }

  }, [data, loading, error]);

  const onRemoveSuggestion = (item: IPersonaProps): void => {
    const indexPeopleList: number = organizationList.indexOf(item);
    const indexMostRecentlyUsed: number = mostRecentlyUsed.indexOf(item);

    if (indexPeopleList >= 0) {
      const newPeople: IPersonaProps[] = organizationList.slice(0, indexPeopleList).concat(organizationList.slice(indexPeopleList + 1));
      setOrganizationList(newPeople);
    }

    if (indexMostRecentlyUsed >= 0) {
      const newSuggestedPeople: IPersonaProps[] = mostRecentlyUsed
        .slice(0, indexMostRecentlyUsed)
        .concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1));
      setMostRecentlyUsed(newSuggestedPeople);
    }
  };

  const onFilterChanged = (
    filterText: string,
    currentPersonas?: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText);

      filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas!);
      filteredPersonas = limitResults ? filteredPersonas.splice(0, limitResults) : filteredPersonas;
      return filterPromise(filteredPersonas);
    } else {
      return [];
    }
  };

  const returnMostRecentlyUsed = (currentPersonas?: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
    const mru = removeDuplicates(mostRecentlyUsed, currentPersonas!);
    return filterPromise(mru);
  };

  const filterPromise = (personasToReturn: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return personasToReturn;
  };

  const listContainsPersona = (persona: IPersonaProps, personas: IPersonaProps[]) => {
    if (!personas || !personas.length || personas.length === 0) {
      return false;
    }
    return personas.filter(item => item.text === persona.text).length > 0;
  };

  const filterPersonasByText = (filterText: string): IPersonaProps[] => {
    return organizationList.filter(item => doesTextStartWith(item.text as string, filterText));
  };

  const doesTextStartWith = (text: string, filterText: string): boolean => {
    return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
  };

  const getTextFromItem = (persona: IPersonaProps): string => {
    return persona.text  as string;
  };

  const removeDuplicates = (personas: IPersonaProps[], possibleDupes: IPersonaProps[]) => {
    return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
  };

  const validateInput = (input: string): ValidationState => {
    if (input.indexOf('@') !== -1) {
      return ValidationState.valid;
    } else if (input.length > 1) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  };

  const onInputChange = (input: string): string => {
    const outlookRegEx = /<.*>/g;
    const emailAddress = outlookRegEx.exec(input);

    if (emailAddress && emailAddress[0]) {
      return emailAddress[0].substring(1, emailAddress[0].length - 1);
    }

    return input;
  };

  return <NormalPeoplePicker
    onResolveSuggestions={onFilterChanged}
    onEmptyInputFocus={returnMostRecentlyUsed}
    getTextFromItem={getTextFromItem}
    pickerSuggestionsProps={suggestionProps}
    onRemoveSuggestion={onRemoveSuggestion}
    onValidateInput={validateInput}
    onInputChange={onInputChange}
    resolveDelay={300}
    onChange={items => {
      onChange(personasToOrganizations(items || []));
    }}
  />

};

export default OrganizationPicker;
