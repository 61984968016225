// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useEffect} from "react";
import 'litegraph.js/css/litegraph.css';

import {ComponentTypeEnum, Device, DeviceContext, PageComponent,} from "../../graphql/types";
import {LGraph, LGraphCanvas, LGraphGroup, LGraphNode, LiteGraph} from "litegraph.js";
import './deviceVisualizerNodeTypes'
import {PixonGraphComponents} from "./deviceVisualizerNodeTypes";
import {getComponentDisplayText} from "../../components/api/util";
import _ from "lodash"
/* Local */

// ----------------------------------------------------------------------------

interface IDeviceVisualizerProps {
  deviceContext: DeviceContext
  device: Device
}

const DeviceVisualizer = (props: IDeviceVisualizerProps) => {
  const {deviceContext, device} = props;
  const canvasId = 'deviceConfigCanvas';

  useEffect(() => {
    const graph = new LGraph();
    const canvas = new LGraphCanvas('#' + canvasId, graph);
    const presentation = deviceContext.controller!.presentations![0]!;

    function calculateGroupSizeAndPosition(pageNodes: LGraphNode[], group: any) {
      const nodeBounds = pageNodes.map(n => n.getBounding());

      const top = _.min(nodeBounds.map(b => b[1])) || 0;
      const bottom = _.max(nodeBounds.map(b => b[1] + b[3])) || 0;

      const left = _.min(nodeBounds.map(b => b[0])) || 0;
      const right = _.max(nodeBounds.map(b => b[0] + b[2])) || 0;

      group.pos = [left, top];
      group.size = [right - left, bottom - top];
    }

    function createComponentNodes(components: PageComponent[], pageNodes: LGraphNode[]) {
      for (let c of components) {
        if (!c.transform
          || !c.component
          || c.component.componentType === ComponentTypeEnum.BackgroundImage)
          continue;

        const componentNode = LiteGraph.createNode(PixonGraphComponents.VisualComponent);
        componentNode.title = getComponentDisplayText(c.component);
        const m = JSON.parse(c.transform);
        componentNode.pos = [m[12], m[13]];
        graph.add(componentNode);
        pageNodes.push(componentNode);

        if (c.component.dataSource) {
          const dataSourceNode = LiteGraph.createNode(PixonGraphComponents.DataSource);
          dataSourceNode.title = c.component.dataSource.name;
          dataSourceNode.pos = [m[12] - 300, m[13]];
          graph.add(dataSourceNode);
          pageNodes.push(dataSourceNode);
          dataSourceNode.connect(0, componentNode, 0);
        }
      }
    }

    function createPageNodes() {
      for (let page of presentation.pages!) {
        if (!page)
          continue;

        const pageNodes = [] as LGraphNode[];

        const group = new LGraphGroup() as any;
        group.title = page.name;

        const components = page.components! as PageComponent[];
        createComponentNodes(components, pageNodes);

        canvas.adjustNodesSize();
        calculateGroupSizeAndPosition(pageNodes, group);
        graph.add(group);
      }
    }

    createPageNodes();
    graph.start();
  }, [deviceContext.controller]);

  return <>
    <canvas
      id={canvasId}
      width={device.viewportWidth}
      height={device.viewportHeight}
      style={{
        top: 500,
        width: device.viewportWidth,
        height: device.viewportHeight,
        borderStyle: 'solid',
        borderWidth: '1px',
        backgroundColor: "white"
      }}
    >
    </canvas>
  </>
};

export default DeviceVisualizer;
