import {
  LINK_DEVICE,
  UNLINK_DEVICE,
  LOAD_DEVICES, UPDATE_DEVICE
} from "./types";
import {async} from "../asyncAction/actions";
import {
  Device,
  DevicesDocument,
  DevicesQuery,
  DevicesQueryVariables, DeviceUpdateInput,
  LinkDeviceDocument,
  LinkDeviceInput,
  LinkDeviceMutation,
  LinkDeviceMutationVariables,
  RemoveDeviceDocument,
  RemoveDeviceMutation,
  RemoveDeviceMutationVariables,
  UpdateDeviceDocument,
  UpdateDeviceMutation,
  UpdateDeviceMutationVariables,
} from "../../graphql/types";
import {notEmpty} from "../../typeFilters";
import {
  ApolloClient,
  InMemoryCache
} from '@apollo/client';

async function loadDevices(client: ApolloClient<InMemoryCache>) : Promise<Device[]> {
  var result = await client.query<DevicesQuery, DevicesQueryVariables>({
    query: DevicesDocument
  });

  const data = result.data && result.data.devices as Device[];
  const devices =  data.filter(notEmpty);

  return Promise.resolve(devices);
}

export function loadDevicesAction() {
  return async(LOAD_DEVICES, loadDevices);
}

export function linkDevice(linkDeviceInput: LinkDeviceInput) {
  return async(LINK_DEVICE, async (client: ApolloClient<InMemoryCache>) => {
    var result = await client.mutate<LinkDeviceMutation, LinkDeviceMutationVariables>({
      mutation: LinkDeviceDocument,
      variables: {
        input: linkDeviceInput
      }
    });
    return Promise.resolve(result.data!.linkDevice!.device);
  });
}

export function unlinkDevice(id: string) {
  return async(UNLINK_DEVICE, async (client: ApolloClient<InMemoryCache>) => {
    var result = await client.mutate<RemoveDeviceMutation, RemoveDeviceMutationVariables>({
      mutation: RemoveDeviceDocument,
      variables: {
        input: {
          deviceId: id
        }
      }
    });
    return Promise.resolve(result.data!.removeDevice!.device);
  });
}

export function updateDevice(updatedDevice: DeviceUpdateInput) {
  return async(UPDATE_DEVICE, async (client: ApolloClient<InMemoryCache>) => {
    var result = await client.mutate<UpdateDeviceMutation, UpdateDeviceMutationVariables>({
      mutation: UpdateDeviceDocument,
      variables: {
        input: updatedDevice
      }
    });
    return Promise.resolve(result.data!.updateDevice!.device);
  });
}
