// NPM
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  DefaultButton,
  Panel,
  PanelType, PrimaryButton,
  Stack
} from "@fluentui/react";

// Local
import {
  DataSource, DataSourceTypeEnum,
} from "../../graphql/types";

import EditJsonAssetDataSourceForm from "./datasource-editors/editJsonAssetDataSourceForm";
import EditResidentRegistryDataSourceForm from "./datasource-editors/editResidentRegistryDataSourceForm";
import EditDeviceMessageDataSourceForm from "./datasource-editors/editDeviceMessageDataSourceForm";
import DefaultDataSourceEditorGroup from "./datasource-editors/defaultDataSourceEditorGroup";
import ClockDataSourceEditorGroup from "./datasource-editors/clockDataSourceEditorGroup";
import RssDataSourceEditorGroup from "./datasource-editors/rssDataSourceEditorGroup";
import CalendarDataSourceEditorGroup from "./datasource-editors/calendarDataSourceEditorGroup";
import TransitStopDataSourceEditorGroup from "./datasource-editors/transitStopDataSourceEditorGroup";
import WeatherDataSourceEditorGroup from "./datasource-editors/weatherDataSourceEditorGroup";
import NameOfDayDataSourceEditorGroup from "./datasource-editors/namerOfDayDataSourceEditorGroup";
import DexcomDataSourceEditorGroup from "./datasource-editors/DexcomDataSourceEditorGroup";

interface EditDataSourcePanelProps {
  isOpen: boolean;
  onUpdateAndDismiss: (newOrUpdated: DataSource) => void;
  onDismiss: () => void;
  dataSource: DataSource
}

const EditDataSourcePanel = (props: EditDataSourcePanelProps) => {
  const {
    dataSource,
    onUpdateAndDismiss,
    onDismiss
  } = props;
  const {t} = useTranslation();

  const [editedDataSource, setEditedDataSource] = useState<DataSource>(dataSource);

  useEffect(() => {
    console.log('datasource is', dataSource);
    setEditedDataSource(dataSource);
  }, [dataSource]);


  const handleChange = (partiallyUpdatedDataSource: Partial<DataSource>) => {
    setEditedDataSource(prevState => {
      const result = {
        ...prevState,
        ...partiallyUpdatedDataSource
      };
      return result;
    });
  };




  const changeCallback = useCallback(handleChange, []);

  return (
    <Panel
      type={PanelType.medium}
      headerText={t('dataSources:editDataSourcesPanel.headerText')}
      closeButtonAriaLabel={t('common:button.close')}
      isOpen={props.isOpen}
      onDismiss={onDismiss}
      isBlocking={true}
    >
      <Stack tokens={{childrenGap: 10}}>
        {dataSource && <>

            {(() => {
              switch(dataSource.dataSourceType) {
                case DataSourceTypeEnum.JsonAssets:
                  return <EditJsonAssetDataSourceForm dataSource={dataSource} onChange={changeCallback} />;
                case DataSourceTypeEnum.ResidentRegistry:
                  return <EditResidentRegistryDataSourceForm dataSource={dataSource} onChange={changeCallback}/>;
                case  DataSourceTypeEnum.DeviceMessage:
                  return <EditDeviceMessageDataSourceForm dataSource={dataSource} onChange={changeCallback}/>;
                case DataSourceTypeEnum.Clock:
                  return <ClockDataSourceEditorGroup dataSource={dataSource} onChange={changeCallback}/>;
                case DataSourceTypeEnum.RssFeeds:
                  return <RssDataSourceEditorGroup dataSource={dataSource} onChange={changeCallback}/>;
                case DataSourceTypeEnum.CalendarIcal:
                  return <CalendarDataSourceEditorGroup dataSource={dataSource} onChange={changeCallback}/>;
                case DataSourceTypeEnum.TransitStop:
                  return <TransitStopDataSourceEditorGroup dataSource={dataSource} onChange={changeCallback}/>;
                case DataSourceTypeEnum.WeatherForecastSmhi:
                  return <WeatherDataSourceEditorGroup dataSource={dataSource} onChange={changeCallback}/>;
                case DataSourceTypeEnum.NameOfDay:
                  return <NameOfDayDataSourceEditorGroup dataSource={dataSource} onChange={changeCallback}/>;
                case DataSourceTypeEnum.Dexcom:
                  return <DexcomDataSourceEditorGroup dataSource={dataSource} onChange={changeCallback}/>;
                default:
                  return <DefaultDataSourceEditorGroup dataSource={dataSource} onChange={changeCallback} />;
              }
            })()}

            <Stack horizontal tokens={{childrenGap: 10}} horizontalAlign="end">
                <DefaultButton onClick={() => {
                  // Undo changes
                  setEditedDataSource(dataSource);

                  // Close
                  onDismiss();
                }}>
                  {t('common:button.cancel')}
                </DefaultButton>
                <PrimaryButton onClick={() =>
                {
                  onUpdateAndDismiss(editedDataSource);
                }}>
                  {t('common:button.update')}
                </PrimaryButton>
            </Stack>

          </>
        }

      </Stack>
    </Panel>
  )
};

export default EditDataSourcePanel;
