// Routes

// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */

// We're using `react-router-dom` to handle routing, so grab the `RouteProps`
// type that we'll use to ensure our own types conform to the expected configuration
import { RouteProps} from "react-router-dom";

/* Local */

// Components

// By default, pull in the ReactQL example. In your own project, just nix
// the `src/components/example` folder and replace the following line with
// your own React components

import Device from "../views/devices";
import Connections from "../views/connections";
import Extensions from "../views/extensions";
import InstalledExtensions from "../views/extensions/installed";
import Organizations from "../views/organizations";
import User from "../views/user";
import Home from "../views/home";
import Debug from "../views/debug";
import Oauth from "../views/oauth";

import MainLayout from "../components/layout/mainLayout";
import AssetsPage from "../views/content/assets";

import DeviceDetailPage from "../views/devices/deviceDetailPage";
import DataSourcesPage from "../views/content/dataSources";
import OrganizationDetailPage from "../views/organizations/organizationDetailPage";


// ----------------------------------------------------------------------------

export interface IBreadCrumbRouteProps extends RouteProps {
  breadcrumb: string;
}


// Specify the routes. This is provided as an array of `RouteProp`, which is
// a type provided by `react-router-dom` for rendering a route. Typically, this
// will contain at least a component and a path
export const routes: IBreadCrumbRouteProps[] = [
  {

    component: MainLayout(Home), // <-- this is the component that'll be rendered
    exact: true, // <-- this says to ONLY match when the path is exactly '/'
    path: "/home", // <-- ... and this is the actual path to match on
    breadcrumb: 'common:routes.home'
  },
  {

    component: Oauth, // <-- this is the component that'll be rendered
    exact: true, // <-- this says to ONLY match when the path is exactly '/'
    path: "/oauthconnection", // <-- ... and this is the actual path to match on
    breadcrumb: 'common:routes.oauth'
  },
  {
    component: MainLayout(Device),
    exact: true,
    path: "/devices",
    breadcrumb: 'common:routes.devices'
  },
  {
    component: MainLayout(DeviceDetailPage),
    exact: true,
    path: "/device/:deviceId",
    breadcrumb: 'common:routes.device'
  },
  {
    component: MainLayout(Device),
    exact: true,
    path: "/link",
    breadcrumb: 'common:routes.linkdevice'
  },
  {
    component: MainLayout(AssetsPage),
    exact: true,
    path: "/assets",
    breadcrumb: 'common:routes.assets'
  },
  {
    component: MainLayout(AssetsPage),
    exact: true,
    path: "/assets/:assetId/editcontent",
    breadcrumb: 'common:routes.assets'
  },
  {
    component: MainLayout(DataSourcesPage),
    exact: true,
    path: "/datasources",
    breadcrumb: 'common:routes.datasources'
  },
  {
    component: MainLayout(Connections),
    exact: true,
    path: "/connections",
    breadcrumb: 'common:routes.connections'
  },
  {
    component: MainLayout(Organizations),
    exact: true,
    path: "/organizations",
    breadcrumb: 'common:routes.organizations'
  },
  {
    component: MainLayout(OrganizationDetailPage),
    exact: true,
    path: "/organization/:organizationId",
    breadcrumb: 'common:routes.organization'
  },
  {
    component: MainLayout(OrganizationDetailPage),
    exact: true,
    path: "/organization/:organizationId/addpayment",
    breadcrumb: 'common:routes.organization'
  },
  {
    component: MainLayout(Extensions),
    exact: true,
    path: "/extensions",
    breadcrumb: 'common:routes.extensions'
  },
  {
    component: MainLayout(InstalledExtensions),
    exact: true,
    path: "/extensions/installed",
    breadcrumb: 'common:routes.installedextensions'
  },
  {
    component: MainLayout(User),
    exact: true,
    path: "/user",
    breadcrumb: 'common:routes.user'
  },
  {
    component: MainLayout(Debug),
    exact: true,
    path: "/debug",
    breadcrumb: 'common:routes.debug'
  }
];

export default routes;
