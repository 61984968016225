// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
  DefaultButton,
  FontWeights,
  getTheme,
  ITextStyles,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  Text,
  TextField
} from "@fluentui/react";
import {Card, ICardTokens} from "@uifabric/react-cards";
/* Local */
import Loading from "../../components/layout/loading";
import {
  Organization,
  SubscriptionPlan,
  useSubscriptionsQuery,
  useUpdateOrganizationSubscriptionMutation
} from "../../graphql/types";
import SubHeading from "../../components/layout/subHeading";
import {useStripe} from "../../components/StripeHookProvider";

// ----------------------------------------------------------------------------

const cardTokens: ICardTokens = {childrenMargin: 12};


interface IAddSubscriptionFormProps {
  organization: Organization;
  onRefetch: () => void;
}

const AddSubscriptionForm = (props: IAddSubscriptionFormProps) => {
  const {organization} = props;
  const {t} = useTranslation();
  const {stripe} = useStripe();

  const [subscriptionPlan, setSubscriptionPlan] = useState('');
  const [subscriptionQuantity, setSubscriptionQuantity] = useState(1);
  const [messageType, setMessageType] = useState<MessageBarType | undefined>();
  const [message, setMessage] = useState('');
  const [quantityValidationMessage, setQuantityValidationMessage] = useState();

  const [updateOrganizationSubscription] = useUpdateOrganizationSubscriptionMutation();
  const {loading, data, error} = useSubscriptionsQuery();

  const theme = getTheme();
  const {palette} = theme;
  const organizationDeviceCount = ((organization?.devices?.length ?? 0) +1);

  if (loading) {
    return <Loading/>
  }

  const siteTextStyles: ITextStyles = {
    root: {
      color: palette.themePrimary,
      fontWeight: FontWeights.semibold
    }
  };
  const descriptionTextStyles: ITextStyles = {
    root: {
      color: palette.neutralDark,
      fontWeight: FontWeights.regular
    }
  };

  const displayError = (m: string) => {
    setMessageType(MessageBarType.error);
    setMessage(m);
  };

  const displaySuccess = (m: string) => {
    setMessageType(MessageBarType.success);
    setMessage(m);
  };

  if (error) {
    return <MessageBar messageBarType={MessageBarType.error}>
      {error.message}
    </MessageBar>;
  }

  var subscriptions = data?.subscriptionPlans as SubscriptionPlan[];

  const handleSubmit = () => {
    updateOrganizationSubscription({
      variables: {
        input: {
          subscriptionExternalId: subscriptionPlan,
          organizationId: organization.id!,
          quantity: subscriptionQuantity
        }
      }
    })
      .then(value => {
        console.log('Subscription Updated', value);

         const payload = value?.data?.updateOrganizationSubscription;
         if (payload) {
           const {clientSecret, status} = payload;

           if (status === 'requires_action') {
            console.log('taking action');
             stripe.handleCardPayment(clientSecret || '').then(function (result) {
               if (result.error) {
                 // Display error message in your UI.
                 // The card was declined (i.e. insufficient funds, card has expired, etc)
                 console.log('payment error', result);
                 displayError('Payment confirmation error');
               } else {
                 // Show a success message to your customer
                 console.log('payment intent confirmed');
                 displaySuccess(t('component:addSubscriptionForm.subscriptionUpdatedSuccessfullyMessage'));
               }
             });
           } else {
             // No additional information was needed
             // Show a success message to your customer
             console.log('no additional information needed');
             displaySuccess(t('component:addSubscriptionForm.subscriptionUpdatedSuccessfullyMessage'));
           }
         }
      })
      .catch(reason => displayError(reason.toString()));
  };

  const subscriptionList = subscriptions?.map(s =>
    <Card key={s.id!} tokens={cardTokens} onClick={() => setSubscriptionPlan(s.externalId)}>
      <Card.Section>
        <Text variant="small" styles={siteTextStyles}>
          {t(s.nameTranslationKey!)}
        </Text>
        <Text styles={descriptionTextStyles}>
          {t(s.descriptionTranslationKey!)}
        </Text>
      </Card.Section>
    </Card>
  );

  const isValid = () => !quantityValidationMessage && subscriptionPlan;

  return <>

    <SubHeading heading={t('component:addSubscriptionForm.heading')}/>

    {subscriptionPlan === '' &&
    <Stack tokens={{childrenGap: 10}} horizontalAlign={"center"}>

      {subscriptionList}

    </Stack>
    }


    {subscriptionPlan !== '' &&
    <>
      <TextField
        placeholder={t('component:addSubscriptionForm.subscriptionQuantityLabel')}
        label={t('component:addSubscriptionForm.subscriptionQuantityLabel')}
        defaultValue={organizationDeviceCount.toString()}
        errorMessage={quantityValidationMessage}
        onChange={(e, v) => {
          var num = parseInt(v!);

          if (!num) {
            setQuantityValidationMessage(t('common:validation.mustBeNumber'));
            return;
          }

          if (num < organizationDeviceCount ) {
            setQuantityValidationMessage(t('common:validation.mustBeLargerThanNumberOfDevices'));
            return;
          }
          setQuantityValidationMessage(undefined);
          setSubscriptionQuantity(num);
        }}
      />

      {message !== '' &&
      <MessageBar messageBarType={messageType}>
        {message}
      </MessageBar>
      }


      <Stack horizontal tokens={{childrenGap: 10}} horizontalAlign="end">
        <DefaultButton onClick={() => setSubscriptionPlan('')}>
          {t('common:button.cancel')}
        </DefaultButton>
        <PrimaryButton onClick={handleSubmit} disabled={!isValid()}>
          {t('component:addSubscriptionForm.addSubscriptionButtonLabel')}
        </PrimaryButton>
      </Stack>
    </>
    }

  </>
};

export default AddSubscriptionForm;
