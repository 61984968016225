// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useState} from "react";
import {MessageBar, MessageBarType, Selection, Stack} from "@fluentui/react";
/* Local */
import DataSourcesList from "./dataSourcesList";
import DataSourcesCommandBar from "./dataSourcesCommandBar";
import EditDataSourcePanel from "./editDataSourcePanel";
import {
  Asset,
  AssetType,
  DataSource,
  DataSourceInput,
  DataSourceTypeEnum,
  useAssignDataSourceAssetMutation,
  useCreateDataSourceMutation,
  useCreateOrganizationAssetsMutation,
  useDataSourcesQuery,
  useRemoveDataSourceMutation,
  useUnassignDataSourceAssetMutation,
  useUpdateDataSourceMutation
} from "../../graphql/types";
import {useTranslation} from "react-i18next";
import NewDataSourcePanel from "./newDataSourcePanel";
import Loading from "../../components/layout/loading";
import {TenantRegistryItem} from "./editTextAssetPanel";
import {UploadAsset} from "../../components/api/util";

// ----------------------------------------------------------------------------

const DataSourcesPage = () => {
  // Utilities
  const {t} = useTranslation();

  // State
  const [errorReason, setErrorReason] = useState<string>();
  const [selectedDataSource, setSelectedDataSource] = useState<any>();
  const [showEditDataSourcePanel, toggleShowEditDataSourcePanel] = useState(false);
  const [showNewDataSourcePanel, toggleShowNewDataSourcePanel] = useState(false);
  const [selection] = useState<Selection>(new Selection({
    onSelectionChanged: () => {
      setSelectedDataSource(selection.getSelection()[0])
    }
  }));

  // Queries
  const {loading, data, error, refetch} = useDataSourcesQuery();

  // Mutations
  const [createAssetsMutation] = useCreateOrganizationAssetsMutation();
  const [updateDataSourceMutation] = useUpdateDataSourceMutation();

  const [assignDataSourceAssetMutation] = useAssignDataSourceAssetMutation();
  const [unassignDataSourceAssetMutation] = useUnassignDataSourceAssetMutation();

  const [createDataSourceMutation] = useCreateDataSourceMutation();
  const [removeDataSourceMutation] = useRemoveDataSourceMutation();

  if (loading) {
    return <Loading/>
  }

  if (error) {
    return <MessageBar
      messageBarType={MessageBarType.error}>{t('dataSources:dataSourcesList.loadErrorMessage')}</MessageBar>
  }
  const dataSources = data?.dataSources as DataSource[];

  const removeSelectedDataSources = async () => {
    for (const item of selection.getSelection()) {
      await removeDataSourceMutation({
        variables: {
          input: (item as DataSource).id!
        }
      });
    }

    await refetch();
  };

  const createNewResidentRegistryAsset = async (organizationId: string): Promise<Asset> => {
    const defaultItem = [
      {
        entrance: '',
        floor: 1,
        name: '',
        apartment_number: '',
        national_land_survey_number: 'A1100',
        resident_type: 'Person'
      } as TenantRegistryItem
    ];

    const arrayData = new TextEncoder()
      .encode(JSON.stringify(defaultItem, null, 2));

    const tenantRegistryAsset = {
      mimeType: 'application/json',
      filename: 'resident-registry.json',
      size: arrayData.length,
      assetType: AssetType.ResidentRegistry
    } as Asset;

    return createAssetsMutation({
      variables: {
        input: {
          id: organizationId,
          assets: [tenantRegistryAsset]
        }
      }
    })
      .then(result => {
        const createdAsset = result.data!.createOrganizationAssets!.assets![0] as Asset;
        UploadAsset(createdAsset, arrayData, createdAsset.mimeType);
        return Promise.resolve(createdAsset);
      })
  };

  const createNewDataSource = async (input: DataSourceInput): Promise<DataSource> => {
    return createDataSourceMutation({
      variables: {
        input: input
      }
    })
    .then(result => {
      const dataSource = result.data!.createDataSource!.dataSource;
      return Promise.resolve(dataSource);
    });
  };

  const updateDataSource = async (updated: DataSource, previous: DataSource) => {
    // Update properties
    await updateDataSourceMutation({
      variables: {
        input: {
          id: updated.id,
          name: updated.name,
          configuration: updated.configuration ? updated.configuration : '{}'
        }
      }
    }).catch((reason) => {
      setErrorReason(reason.toString());
    });

    // Look for new assets added and assign them
    const existingAssets = previous.assets ? previous.assets : [] as Asset[];
    const editedAssets = updated.assets ? updated.assets : [] as Asset[];

    for (const a of existingAssets) {
      await unassignDataSourceAssetMutation({
        variables: {
          input: {
            dataSourceId: updated.id!,
            assetId: a!.id!
          }
        }
      }).catch((reason) => {
        setErrorReason(reason.toString());
      });
    }

    for (const a of editedAssets) {
      await assignDataSourceAssetMutation({
        variables: {
          input: {
            dataSourceId: updated.id!,
            assetId: a!.id!
          }
        }
      }).catch((reason) => {
        setErrorReason(reason.toString());
      });
    }

    await refetch();
  };

  return (
    <Stack>
      <DataSourcesCommandBar
        onAddNewDataSource={() => toggleShowNewDataSourcePanel(true)}
        onEditDataSource={() => {
          if (selectedDataSource) toggleShowEditDataSourcePanel(true);
        }}
        onRemoveDataSource={removeSelectedDataSources}
      />

      {errorReason &&
      <MessageBar messageBarType={MessageBarType.error}>
        {t('common:error.api')}: {errorReason}
      </MessageBar>
      }

      <DataSourcesList
        dataSources={dataSources}
        selection={selection}
        onEditDataSource={(dataSource) => {
          setSelectedDataSource(dataSource);
          toggleShowEditDataSourcePanel(true);
        }}
        onRemoveDataSources={removeSelectedDataSources}
      />

      <NewDataSourcePanel
        isOpen={showNewDataSourcePanel}
        dataSourceType={DataSourceTypeEnum.ResidentRegistry}
        onDismiss={() => toggleShowNewDataSourcePanel(false)}
        onCreateAndDismiss={async (input) => {
          toggleShowNewDataSourcePanel(false);

          if (input.dataSourceType === DataSourceTypeEnum.ResidentRegistry) {
            let createdAsset = {} as Asset;
            await createNewResidentRegistryAsset(input.organizationId!)
              .then(asset => {
                createdAsset = asset;
                return createNewDataSource(input)
              })
              .then(dataSource => {
                console.log('datasource', dataSource);
                return assignDataSourceAssetMutation({
                  variables: {
                    input: {
                      assetId: createdAsset.id!,
                      dataSourceId: dataSource.id!
                    }
                  }
                })
              })
              .then((res) => {
                console.log('assigned', res)
              })
              .catch(reason => {
                setErrorReason('Error creating resident registry data source')
              });
          } else {
            await createNewDataSource(input).catch(reason => {
              setErrorReason('Error creating datasource')
            });
          }

          await refetch();
        }}/>

      {selectedDataSource && showEditDataSourcePanel &&
      <EditDataSourcePanel
          dataSource={selectedDataSource}
          isOpen={showEditDataSourcePanel}
          onDismiss={() => {
            toggleShowEditDataSourcePanel(false);
          }}
          onUpdateAndDismiss={async (updated) => {
            toggleShowEditDataSourcePanel(false);
            await updateDataSource(updated, selectedDataSource)
          }}

      />
      }
    </Stack>
  );
};

export default DataSourcesPage;
