import React from 'react';
import {matchPath, withRouter} from 'react-router-dom';
import routes from "../../data/routes";
import {Breadcrumb, IDividerAsProps, TooltipHost} from "@fluentui/react";
import i18next from "i18next";

const renderer = ( breadcrumb:any, match:any ) => {
  if (typeof breadcrumb === 'function') { return breadcrumb({ match }); }
  return i18next.t(breadcrumb);
};

export const getBreadcrumbs = ( pathname:string ) => {
  const matches:any = [];

  pathname
    .replace(/\/$/, '')
    .split('/')
    .reduce((previous, current) => {
      const pathSection = `${previous}/${current}`;

      routes.forEach(({ breadcrumb, path }) => {
        const match = matchPath(pathSection, { exact: true, path });

        if (match) {
          matches.push({
            breadcrumb: renderer( breadcrumb, match ),
            path,
            match,
          });
        }
      });

      return pathSection;
    });

  return matches;
};

const Breadcrumbs = (props: any) => {
  const breadcrumbs = getBreadcrumbs(props.location.pathname);
  const items = breadcrumbs.map( (bc:any) => {
    return {
      text: bc.breadcrumb,
      key: bc.path
    }
  });

  const _getCustomDivider = (dividerProps: IDividerAsProps): JSX.Element => {
    const tooltipText = dividerProps.item ? dividerProps.item.text : '';
    return (
      <TooltipHost content={`Show ${tooltipText} contents`} calloutProps={{gapSpace: 0}}>
        <span aria-hidden="true" style={{cursor: 'pointer'}}>
          /
        </span>
      </TooltipHost>
    );
  };

  return (
      <Breadcrumb items={items} dividerAs={_getCustomDivider}/>
  );
};

export default withRouter(Breadcrumbs);
