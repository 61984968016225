// NPM
import * as React from "react";
import {useTranslation} from "react-i18next";
import {
  IStackStyles,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
  Stack,
  IStyleSet
} from "@fluentui/react";
import {
  Organization, useOrganizationWithPaymentDetailsQuery,
} from "../../graphql/types";
import AddSubscriptionForm from "./addSubscriptionForm";
import ShowExistingSubscriptionForm from "./showExistingSubscriptionForm";
import AddPaymentMethodForm from "./addPaymentMethodForm";
import {RouteComponentProps, withRouter} from "react-router-dom";
import PaymentMethodsList from "./paymentMethodsList";
import {useEffect, useState} from "react";
import Loading from "../../components/layout/loading";

// Local
interface AddSubscriptionPanelPanelProps extends RouteComponentProps  {
  isOpen: boolean;
  organization: Organization;
  onDismiss: any;
}

const stackStyles: Partial<IStyleSet<IStackStyles>> = {
  root: { marginTop: 10 }
};

const ManageSubscriptionPanel = (props: AddSubscriptionPanelPanelProps) => {
  const {t} = useTranslation();
  const {match} = props;
  const initialKey =match.path.endsWith('addpayment') ? 'newPaymentMethod' :'newSubscription'
  const [selectedKey, setSelectedKey] = useState(initialKey);
  const [havePaymentDetails, setHavePaymentDetails] = useState(false);
  const [haveSubscription, setHaveSubscription] = useState(false);
  const {loading, data, error, refetch} = useOrganizationWithPaymentDetailsQuery({
    variables: {
      id: props.organization.id!
    }
  });

  const organization = data?.organization as Organization;
  useEffect(() => {
    if (organization)
    {
      const hs = (organization.organizationSubscription !== undefined && organization.organizationSubscription !== null) ?? false;
      setHaveSubscription(hs);
      const pd = (organization?.paymentMethods?.length || 0) > 0 ?? false;
      setHavePaymentDetails(pd);
      if (pd) {
        setSelectedKey('newSubscription');
      }
    }
  }, [organization]);

  if (loading)
  {
    return <Loading />
  }

  if (error || !data || !organization)
  {
    return null;
  }

  return (
    <Panel
      type={PanelType.medium}
        headerText={t('component:manageSubscriptionPanel.heading')}
      closeButtonAriaLabel={t('common:button.close')}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
    >
      <Pivot selectedKey={selectedKey} onLinkClick={item => setSelectedKey(item?.props.itemKey!)}>
        <PivotItem headerText={t('component:manageSubscriptionPanel.newPaymentMethodPivot')} itemKey={'newPaymentMethod'}>
          <Stack tokens={{childrenGap: 10}} styles={stackStyles}>

            <AddPaymentMethodForm organization={organization} onRefetch={() => refetch() } />

          </Stack>
        </PivotItem>

        <PivotItem headerText={t('component:manageSubscriptionPanel.subscriptionPivot')} itemKey={'newSubscription'}>
          <Stack tokens={{childrenGap: 10}} styles={stackStyles}>

            {!havePaymentDetails &&
            <MessageBar messageBarType={MessageBarType.warning}>
              {t('common:validation.missingPaymentMethods')}
            </MessageBar>
            }

            {havePaymentDetails && !haveSubscription &&
              <AddSubscriptionForm organization={organization} onRefetch={() => refetch() } />
            }

            {haveSubscription &&
              <ShowExistingSubscriptionForm organization={organization} onRefetch={() => refetch() } />
            }

          </Stack>
        </PivotItem>

        <PivotItem headerText={t('component:manageSubscriptionPanel.paymentMethodsPivot')}  itemKey={'previousPaymentMethods'}>
          <Stack tokens={{childrenGap: 10}} styles={stackStyles}>

            <PaymentMethodsList organization={organization} onRefetch={() => refetch() } />

          </Stack>
        </PivotItem>

      </Pivot>
    </Panel>
  )
};

export default withRouter(ManageSubscriptionPanel);
