import * as React from "react";
import UserMenu from "./userMenu";
import {Stack } from "@fluentui/react";
import BreadCrumb from './breadCrumbs';


const Header = () => {
  return (
    <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
      <Stack.Item align="start" grow={4}>
        <BreadCrumb/>
      </Stack.Item>

      <Stack horizontal horizontalAlign="end">
          <UserMenu/>
      </Stack>

    </Stack>
  );
};

export default Header;
