import * as React from 'react';

import {
  Dropdown, IDropdownOption
} from "@fluentui/react";

import {useTranslation} from "react-i18next";
import {DataSource, useDataSourcesQuery} from "../graphql/types";
import {useEffect, useState} from "react";

export interface IDataSourceDropDownProps {
  onChange: (selected:DataSource) => void;
  dataSourceTypes?: string[],
  required?: boolean,
  selected?: DataSource
  selectFirstIfAvailable?: boolean,
  label?: string,
  placeholder?: string
}

const DataSourceDropDown = (props: IDataSourceDropDownProps) => {
  const { t } = useTranslation();
  const {
    onChange,
    selected,
    dataSourceTypes = ['JSON_ASSETS'],
    selectFirstIfAvailable = false,
    label = t('common:dataSourcesDropdown.label'),
    placeholder = t('common:dataSourcesDropdown.placeholder')
  } = props;
  const {loading, data, error } = useDataSourcesQuery();
  let dataSources = [] as DataSource[];
  const [selectedOption, setSelectedOption] = useState<IDropdownOption>();

  if (!loading && !error && data)
  {
    dataSources = (data.dataSources as DataSource[])
      .filter(ds => dataSourceTypes.includes(ds.dataSourceType!));
  }

  const options = dataSources
    .map<IDropdownOption>(a => ({
      key: a.id!,
      text: a.name
    }));

  useEffect(() => {
    if (options && options.length > 0 && !selectedOption)
    {
      if (selected)
      {
        const found = options.filter(o => o.key === selected.id!)[0];
        setSelectedOption(found);
      } else if (selectFirstIfAvailable)  {
        const first = options[0];
        setSelectedOption(first);
      }
    }
  }, [options, onChange, selected, selectedOption, selectFirstIfAvailable]);

  return <Dropdown
    required={props.required}
    label={label}
    placeholder={placeholder}
    options={options}
    selectedKey={selectedOption ? selectedOption.key : undefined}
    onChange={(e, option) => {
      setSelectedOption(option);
      const found = dataSources.filter(ds => ds.id === option?.key)[0];
      props.onChange(found)
    }}
  />

};

export default DataSourceDropDown;
