// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {Dropdown} from "@fluentui/react";
import {useTranslation} from "react-i18next";
import { INameOfDayDataSourceConfiguration } from "pixon-component-core";

/* Local */
import DefaultDataSourceEditorGroup from "./defaultDataSourceEditorGroup";
import {IDataSourceEditorProps, useDataSourceConfiguration} from "./dataSourceEditor";

// ----------------------------------------------------------------------------

const NameOfDayDataSourceEditorGroup = (props: IDataSourceEditorProps) => {
  const {dataSource, onChange} = props;
  const {t} = useTranslation();
  const [configuration, updateConfigurationProperty] = useDataSourceConfiguration<INameOfDayDataSourceConfiguration>(onChange, dataSource);


  const providerOptions = [
    'MIN_NAMNSDAG',
  ].map(key => {
    return {
      key: key,
      text: t('dataSources:nameOfDayDataSourceEditorGroup' + key)
    }});


  return  <>

    <DefaultDataSourceEditorGroup
      showAssetsEditor={false}
      {...props}>

    <Dropdown
      label={t('dataSources:nameOfDayDataSourceEditorGroup.providerLabel')}
      placeholder={t('dataSources:nameOfDayDataSourceEditorGroup.providerPlaceHolder')}
      options={providerOptions}
      selectedKey={configuration ? configuration.providerName : undefined}
      onChange={(e, o) =>  updateConfigurationProperty({
        providerName: o?.key as string
          })
      } />


    </DefaultDataSourceEditorGroup>

  </>
};

export default NameOfDayDataSourceEditorGroup;
