/* NPM */
import React, { Suspense }  from 'react';
import {BrowserRouter, Route, Switch } from "react-router-dom";
import {Fabric, loadTheme, initializeIcons} from "@fluentui/react";

/* Local */
import PrivateRoute from "./components/auth/privateRoute";
import Loading from "./components/layout/loading";
import routes from "./data/routes";
import Landing from "./views/landing";

loadTheme({
  palette: {
    themePrimary: '#0981c4',
    themeLighterAlt: '#f3f9fd',
    themeLighter: '#d0e9f6',
    themeLight: '#aad6ed',
    themeTertiary: '#5fb0dc',
    themeSecondary: '#2190cb',
    themeDarkAlt: '#0976b1',
    themeDark: '#076495',
    themeDarker: '#05496e',
    neutralLighterAlt: '#f8f8f8',
    neutralLighter: '#f4f4f4',
    neutralLight: '#eaeaea',
    neutralQuaternaryAlt: '#dadada',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c8c8',
    neutralTertiary: '#a8a8a8',
    neutralSecondary: '#8c8c8c',
    neutralPrimaryAlt: '#707070',
    neutralPrimary: '#010101',
    neutralDark: '#383838',
    black: '#1c1c1c',
    white: '#ffffff',
  }
});

initializeIcons(/* optional base url */);

const App: React.FC = () => {

    return (
      <Suspense fallback={<Loading/>}>
        <Fabric className="App">
            <BrowserRouter>
                <Switch>
                    <Route path='/' exact component={Landing}/>

                    {routes.map(route => (
                        <PrivateRoute key={route.path as string} {...route} />
                    ))}
                </Switch>
            </BrowserRouter>
        </Fabric>
      </Suspense>
    );
}

export default App;
