// NPM
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack
} from "@fluentui/react";
// Local
import {Component, ComponentTypeEnum, Device, Page, PageComponent} from "../../graphql/types";

import DefaultComponentEditorGroup from "./component-editors/defaultComponentEditorGroup";
import BackgroundImageComponentEditorGroup from "./component-editors/backgroundImageComponentEditorGroup";
import DeviceSendMessageButtonComponentEditorGroup
  from "./component-editors/deviceSendMessageButtonComponentEditorGroup";
import TextTableComponentEditorGroup from "./component-editors/textTableComponentEditorGroup";
import SmhiWeatherComponentEditorGroup from "./component-editors/smhiWeatherComponentEditorGroup";
import ClockComponentEditorGroup from "./component-editors/clockComponentEditorGroup";
import DeviceMessageLabelComponentEditorGroup from "./component-editors/deviceMessageLabelComponentEditorGroup";
import RssComponentEditorGroup from "./component-editors/rssComponentEditorGroup";
import CalendarComponentEditorGroup from "./component-editors/calendarComponentEditorGroup";
import TextLabelComponentEditorGroup from "./component-editors/textLabelComponentEditorGroup";
import TransitTimesComponentEditorGroup from "./component-editors/transitTimesComponentEditorGroup";
import {isEmpty} from "../../components/api/util";
import NameOfDayComponentEditorGroup from "./component-editors/nameOfDayComponentEditorGroup";
import DeviceEventActionComponentEditorGroup from "./component-editors/deviceEventActionComponentEditorGroup";
import ImageComponentEditorGroup from "./component-editors/imageComponentEditorGroup";
import DeviceVoteCategoryButtonComponentEditorGroup
  from "./component-editors/deviceVoteCategoryButtonComponentEditorGroup";
import SnowFlakeEffectComponentEditorGroup from "./component-editors/snowFlakeEffectComponentEditorGroup";
import IframeComponentEditorGroup from "./component-editors/iframeComponentEditorGroup";
import BarcodeReaderComponentEditorGroup from "./component-editors/barcodeReaderComponentEditorGroup";
import ImageCarouselComponentEditorGroup from "./component-editors/imageCarouselComponentEditorGroup";
import DexComComponentEditorGroup from "./component-editors/dexcomComponentEditorGroup";
import KeyboardBarcodeReaderComponentEditorGroup from "./component-editors/keyboardBarcodeReaderComponentEditorGroup";

interface EditComponentPanelProps {
  isOpen: boolean;
  onUpdateAndDismiss: (updatedComponent: PageComponent) => void;
  onCancel: () => void;
  device: Device,
  page: Page,
  pageComponent: PageComponent
}

const EditComponentPanel = (props: EditComponentPanelProps) => {
  const {
    pageComponent,
    onCancel,
    onUpdateAndDismiss
  } = props;
  const {t} = useTranslation();
  const component = pageComponent.component!;

  const [editedComponent, setEditedComponent] = useState<Component>(component);
  const [editedPageComponent, setEditedPageComponent] = useState<PageComponent>(pageComponent);
  const [errorReason] = useState('');

  // If  changes in component, set edited component (after reload/refresh)
  useEffect(() => {
    setEditedComponent(component);
  }, [component]);

  // If  changes in pageComponent, set edited component (after reload/refresh)
  useEffect(() => {
    setEditedPageComponent(pageComponent);
  }, [pageComponent]);

  // If component is updated, update edited component too
  useEffect(() => {
    setEditedPageComponent(prevState => {
      return {
        ...prevState,
        component: editedComponent
      }
    });
  }, [editedComponent]);

  // Handle partial property updates to component
  const handleComponentChange = (partiallyUpdatedComponent: Partial<Component>) => {
    setEditedComponent(prevState => {
      return {
        ...prevState,
        ...partiallyUpdatedComponent
      };
    });
  };

  // Handle partial property updates to pagecomponent
  const handlePageComponentChange = (partiallyUpdatedComponent: Partial<PageComponent>) => {
    setEditedPageComponent(prevState => {
      return {
        ...prevState,
        ...partiallyUpdatedComponent
      };
    });
  };

  const handleComponentChangeCallback = useCallback(handleComponentChange, []);
  const handlePageComponentChangeCallback = useCallback(handlePageComponentChange, []);

  const editContext = {
    pageComponent: editedPageComponent,
    onChange: handleComponentChangeCallback,
    onPageComponentChange: handlePageComponentChangeCallback
  };

  return (
    <Panel
      type={PanelType.medium}
      headerText={t('devices:editPageComponentPanel.headerText')}
      closeButtonAriaLabel={t('common:button.close')}
      isOpen={props.isOpen}
      onDismiss={onCancel}
      layerProps={
        {eventBubblingEnabled: true}
      }
      isBlocking={true}
    >
      <Stack tokens={{childrenGap: 10}}>
        {editedComponent && <>
            {(() => {
            switch(editedComponent.componentType) {
              case ComponentTypeEnum.Calendar:
                return  <CalendarComponentEditorGroup {...editContext} />;
              case ComponentTypeEnum.RssFeed:
                return  <RssComponentEditorGroup {...editContext} />;
              case ComponentTypeEnum.TransitTimetable:
                return  <TransitTimesComponentEditorGroup {...editContext} />;
              case ComponentTypeEnum.BackgroundImage:
                return  <BackgroundImageComponentEditorGroup {...editContext} />;
              case ComponentTypeEnum.WeatherSmhi:
                return  <SmhiWeatherComponentEditorGroup {...editContext} />;
              case ComponentTypeEnum.TextLabel:
                return  <TextLabelComponentEditorGroup {...editContext} />;
              case ComponentTypeEnum.TextTable:
                return  <TextTableComponentEditorGroup {...editContext} />;
              case ComponentTypeEnum.TextClock:
                return  <ClockComponentEditorGroup {...editContext} />;
              case ComponentTypeEnum.DeviceMessageButton:
                return  <DeviceSendMessageButtonComponentEditorGroup {...editContext} />;
              case ComponentTypeEnum.DeviceMessage:
                return  <DeviceMessageLabelComponentEditorGroup {...editContext} />;
              case ComponentTypeEnum.TextNameOfDay:
                return  <NameOfDayComponentEditorGroup {...editContext} />;
              case ComponentTypeEnum.DeviceEventAction:
                return  <DeviceEventActionComponentEditorGroup {...editContext} />;
              case ComponentTypeEnum.Image:
                return  <ImageComponentEditorGroup {...editContext} />;
              case ComponentTypeEnum.DeviceGarbageCategoryVoteButton:
                return  <DeviceVoteCategoryButtonComponentEditorGroup {...editContext} />;
              case ComponentTypeEnum.FullscreenSnowflakeEffect:
                return  <SnowFlakeEffectComponentEditorGroup {...editContext} />;
              case ComponentTypeEnum.Iframe:
                return  <IframeComponentEditorGroup {...editContext} />;
              case ComponentTypeEnum.TextBarcodeReader:
                return  <BarcodeReaderComponentEditorGroup {...editContext} />;
              case ComponentTypeEnum.TextKeyboardBarcodeReader:
                return  <KeyboardBarcodeReaderComponentEditorGroup {...editContext} />;
              case ComponentTypeEnum.ImageCarousel:
                return  <ImageCarouselComponentEditorGroup {...editContext} />;
              case ComponentTypeEnum.Dexcom:
                return  <DexComComponentEditorGroup {...editContext} />;
              default:
                return <DefaultComponentEditorGroup {...editContext} />;
            }
            })()}

            {!isEmpty(errorReason) &&
            <MessageBar messageBarType={MessageBarType.error}>
              {t('common:error.api')}: {errorReason.toString()}
            </MessageBar>
            }

            <Stack horizontal tokens={{childrenGap: 10}} horizontalAlign="end">
                <DefaultButton onClick={() => {
                  // Undo changes
                  setEditedComponent(component);
                  setEditedPageComponent(pageComponent);

                  // Close
                  onCancel();
                }}>
                  {t('common:button.cancel')}
                </DefaultButton>
                <PrimaryButton onClick={() => {
                  // Close
                  onUpdateAndDismiss(editedPageComponent);
                }}>
                  {t('common:button.update')}
                </PrimaryButton>
            </Stack>

          </>
        }

      </Stack>
    </Panel>
  )
};

export default EditComponentPanel;
