import * as React from 'react';

import {
  Dropdown, IDropdownOption
} from "@fluentui/react";

import {useTranslation} from "react-i18next";
import {Controller, useControllersQuery} from "../graphql/types";
import {useEffect, useState} from "react";

export interface IControllerDropDownProps {
  onChange: (selected:Controller) => void;
  required?: boolean,
  selected?: Controller
  selectFirstIfAvailable?: boolean,
  label?: string,
  placeholder?: string
}

const ControllerDropDown = (props: IControllerDropDownProps) => {
  const { t } = useTranslation();
  const {
    onChange,
    selected,
    selectFirstIfAvailable = false,
    label = t('common:controllersDropdown.label'),
    placeholder = t('common:controllersDropdown.placeholder')
  } = props;
  const {loading, data, error } = useControllersQuery({
    variables: {
      input: {}
    }
  });
  let controllers = [] as Controller[];
  const [selectedOption, setSelectedOption] = useState<IDropdownOption>();

  if (!loading && !error && data)
  {
    controllers = data.controllers as Controller[];
  }

  const options = controllers
    .map<IDropdownOption>(a => ({
      key: a.id!,
      text: a.name
    }));

  useEffect(() => {
    if (options && options.length > 0 && !selectedOption)
    {
      if (selected)
      {
        const found = options.filter(o => o.key === selected.id!)[0];
        setSelectedOption(found);
      } else if (selectFirstIfAvailable)  {
        const first = options[0];
        setSelectedOption(first);
      }
    }
  }, [options, onChange, selected, selectedOption, selectFirstIfAvailable]);

  return <Dropdown
    required={props.required}
    label={label}
    placeholder={placeholder}
    options={options}
    selectedKey={selectedOption ? selectedOption.key : undefined}
    onChange={(e, option) => {
      setSelectedOption(option);
      const found = controllers.filter(ds => ds.id === option?.key)[0];
      props.onChange(found)
    }}
  />

};

export default ControllerDropDown;
