// NPM
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
  Text,
  Label
} from "@fluentui/react";

// Local
import {Asset, Organization} from "../../graphql/types";

import OrganizationsDropDown from "../../components/organizationsDropdown";
import UploadAssetComponent from "../../components/uploadAssetComponent";

interface NewAssetPanelProps {
  isOpen: boolean;
  onCreateAndDismiss: (input: Asset) => void;
  onDismiss: () => void;
}

const NewAssetPanel = (props: NewAssetPanelProps) => {
  const {
    onCreateAndDismiss,
    onDismiss
  } = props;
  const {t} = useTranslation();

  // State
  const [message, setMessage] = useState('');
  const [organization, setOrganization] = useState<Organization>();
  const [editedAsset, setEditedAsset] = useState<Asset>({
    displayName: '',
    filename: '',
    mimeType: '',
    size: 0
  });

  return (
    <Panel
      type={PanelType.medium}
      headerText={t('assets:newAssetPanel.headerText')}
      closeButtonAriaLabel={t('common:button.close')}
      isOpen={props.isOpen}
      onDismiss={onDismiss}
      layerProps={
        {eventBubblingEnabled: true}
      }
      isBlocking={false}
    >
      <Stack tokens={{childrenGap: 10}}>
        <TextField label={t('assets:newAssetPanel.displayNameLabel')}
                   placeholder={t('assets:newAssetPanel.displayNamePlaceholder')} autoFocus
                   value={editedAsset.displayName ? editedAsset.displayName : ''}
                   onChange={(e, v) => setEditedAsset({
                     ...editedAsset,
                     displayName: v
                   })}/>


        <OrganizationsDropDown

          onChange={(o) => setOrganization(o)}
          selectFirstIfAvailable={false}
          selectedOrganizationId={organization ? organization.id! : ''}
        />

        <Label>{t('assets:newAssetPanel.fileHeader')}</Label>

        {organization &&
        <UploadAssetComponent
            organizationId={organization.id!}
            uploadFinishedCallback={async (uploadedAsset) => {
              setEditedAsset({
                ...editedAsset,
                id: uploadedAsset.id!,
                filename: uploadedAsset.filename
              });
              setMessage(t('assets:uploadSuccessMessage'))
            }}
        />
        }

        {!organization &&
          <Text>{t('assets:newAssetPanel.fileDescription')}</Text>
        }

        {message !== '' &&
        <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={false}
            onDismiss={() => setMessage('')}
        >
          {message}
        </MessageBar>
        }

        <Stack horizontal tokens={{childrenGap: 10}} horizontalAlign="end">
          <DefaultButton onClick={onDismiss}>
            {t('common:button.cancel')}
          </DefaultButton>
          <PrimaryButton onClick={() => {
            onCreateAndDismiss(editedAsset)
          }}>
            {t('common:button.add')}
          </PrimaryButton>
        </Stack>
      </Stack>
    </Panel>
  )
};

export default NewAssetPanel;
