// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";

/* Local */
import PageHeading from "../../components/layout/pageHeading";

// ----------------------------------------------------------------------------

class InstalledExtensionsPage extends React.PureComponent<{}, {}> {
  public componentDidMount = async () => {};

  public render() {
    return (
      <>
        <PageHeading heading="Installed Extensions" />
      </>
    );
  }
}

export default InstalledExtensionsPage;
