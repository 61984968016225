// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useTranslation} from "react-i18next";
import { IImageCarouselComponentConfiguration } from "pixon-component-core";

/* Local */
import {IComponentPropertyEditorProps, useComponentConfiguration} from "./propertyEditor";
import DefaultComponentEditorGroup from "./defaultComponentEditorGroup";
import {Checkbox, Slider } from "@fluentui/react";
import AssetPicker from "../../../components/assetPicker";
import {Asset} from "../../../graphql/types";

// ----------------------------------------------------------------------------

const ImageCarouselComponentEditorGroup = (props: IComponentPropertyEditorProps) => {
  const {pageComponent, onChange} = props;
  const component = pageComponent.component!;
  const {t} = useTranslation();
  const [configuration, updateConfigurationProperty] = useComponentConfiguration<IImageCarouselComponentConfiguration>(onChange, component);

  return <>

    <DefaultComponentEditorGroup
      showDataSourceEditor={false}
      showCssEditor={false}
      showTemplateAssetEditor={false}
      heading={t('component:imageCarouselComponentEditorGroup.heading')}
      {...props}>

      <AssetPicker
        onChange={(value?: Asset[]) => component.assets = value }
        selectedItems={component?.assets as Asset[]}
        label={t('component:imageCarouselComponentEditorGroup.imagesLabel')}
      />

      <Slider
        label={t('component:imageCarouselComponentEditorGroup.intervalLabel')}
        min={1}
        max={86400}
        step={1}
        defaultValue={configuration ? configuration.interval : 3}
        showValue={true}
        valueFormat={(value: number) => `${value}s`}
        onChange={(value: number) => updateConfigurationProperty({interval: value})}
      />

      <Slider
        label={t('component:imageCarouselComponentEditorGroup.transitionTimeLabel')}
        min={100}
        max={5000}
        step={1}
        defaultValue={configuration ? configuration.transitionTime : 3}
        showValue={true}
        valueFormat={(value: number) => `${value}ms`}
        onChange={(value: number) => updateConfigurationProperty({transitionTime: value})}
      />

      <Checkbox
        label={t('component:imageCarouselComponentEditorGroup.autoPlayLabel')}
        defaultChecked={configuration?.autoPlay}
        onChange={(ev: any, value?: boolean) => updateConfigurationProperty({autoPlay: value})}
      />

      <Checkbox
        label={t('component:imageCarouselComponentEditorGroup.infiniteLoopLabel')}
        defaultChecked={configuration?.infiniteLoop}
        onChange={(ev: any, value?: boolean) => updateConfigurationProperty({infiniteLoop: value})}
      />

      <Checkbox
        label={t('component:imageCarouselComponentEditorGroup.showArrowsLabel')}
        defaultChecked={configuration?.showArrows}
        onChange={(ev: any, value?: boolean) => updateConfigurationProperty({showArrows: value})}
      />

      <Checkbox
        label={t('component:imageCarouselComponentEditorGroup.showIndicatorsLabel')}
        defaultChecked={configuration?.showIndicators}
        onChange={(ev: any, value?: boolean) => updateConfigurationProperty({showIndicators: value})}
      />

      <Checkbox
        label={t('component:imageCarouselComponentEditorGroup.showStatusLabel')}
        defaultChecked={configuration?.showStatus}
        onChange={(ev: any, value?: boolean) => updateConfigurationProperty({showStatus: value})}
      />

      <Checkbox
        label={t('component:imageCarouselComponentEditorGroup.showThumbsLabel')}
        defaultChecked={configuration?.showThumbs}
        onChange={(ev: any, value?: boolean) => updateConfigurationProperty({showThumbs: value})}
      />

    </DefaultComponentEditorGroup>

  </>
};

export default ImageCarouselComponentEditorGroup;
