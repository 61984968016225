// NPM
import * as React from "react";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack
} from "@fluentui/react";
import {validate, ValidationError, ValidationSchema, registerSchema} from "class-validator";

// Local
import {AsyncActionStatus} from "../store/asyncAction/types";
import {AppState} from "../store";

interface MutatePanelProps<T> {
  isOpen: boolean;
  headerText: string;
  onDismiss: any;
  input?: T;
  objectId?: string;
  updateAction?: (input: T) => any;
  createAction?: (input: T) => any;
  validationSchema: ValidationSchema;
  children?: React.ReactNode;
  statusSelector: (state:AppState) => AsyncActionStatus;
  errorSelector: (state:AppState) => Error | undefined;
}

// instead of inline with component assignment
const MutatePanel = <T extends object>(props: MutatePanelProps<T>) => {
  const {input, headerText, validationSchema, onDismiss} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [hasSentRequest, setHasSentRequest] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>();
  const status = useSelector(props.statusSelector);
  const error = useSelector(props.errorSelector);

  registerSchema(validationSchema);

  // Close if the async action returns successfully
  useEffect(() => {
    if (hasSentRequest && !error && status === AsyncActionStatus.SUCCEEDED)
    {
      setHasSentRequest(false);
      onDismiss();
    }
  }, [status, error, onDismiss, hasSentRequest]);

  // Whenever the input object changes, update validation status
  useEffect(() => {
    if (!input)
      return;

    validate(validationSchema.name, input).then(errors => {
      if (errors.length > 0) {
        setValidationErrors(errors);
        setIsValid(false)
      } else {
        setValidationErrors(undefined);
        setIsValid(true);
      }
    });
  }, [input, validationSchema.name]);

  const dispatchAsyncMutationAction = async (internalStatus: any) => {
    if (!isValid || !input)
      return;

    // If the panel is acting as an edit panel then id and updateaction must be set
    if (props.updateAction) {
      await dispatch(props.updateAction(input));
    }

    // Wait for the update action to complete
    if (props.createAction) {
      await dispatch(props.createAction(input));
    }

    setHasSentRequest(true);
  };

  return (
    <Panel
      type={PanelType.medium}
      headerText={headerText}
      closeButtonAriaLabel={t('common:button.close')}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
    >
      <Stack tokens={{childrenGap: 10}}>
        {props.children}

        {status === AsyncActionStatus.FAILED &&
        <MessageBar messageBarType={MessageBarType.error}>
          {t('common:error.api')}: {error?.message}
        </MessageBar>
        }

        {validationErrors &&
        <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
            <ul>
              {validationErrors.map((validationError: ValidationError, index: number) =>
                Object.keys(validationError.constraints!).map((key, constraintIndex) => (
                    <li key={index + '-' + constraintIndex}>{t(validationError.constraints![key]!)}</li>
                  )
                ))}
            </ul>
        </MessageBar>
        }

        <Stack horizontal tokens={{childrenGap: 10}} horizontalAlign="end">
          <DefaultButton onClick={props.onDismiss}>
            {t('common:button.cancel')}
          </DefaultButton>
          <PrimaryButton onClick={() => dispatchAsyncMutationAction(status)}>
            {t('common:button.save')}
          </PrimaryButton>
        </Stack>
      </Stack>
    </Panel>
  )
};

export default MutatePanel;
