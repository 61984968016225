import {useSendCommandMutation} from "../../graphql/types";

export enum PixonCommand {
    Reload = "ReloadCommand",
    RefreshContext = "RefreshContextCommand",
    RequestScreenShot = "RequestScreenShotCommand",
    ToggleStatusbar = "ToggleStatusbarCommand",
    ToggleDebugPanel = "ToggleDebugPanelCommand",
    Unlink = "UnlinkCommand"
}
const GetCommandOptions = (command: PixonCommand, deviceId: string, data?: any) => {
  return {
    variables: {
      input: {
        targetId: deviceId,
        commandName: command,
        data: data ? JSON.stringify(data) : '{}'
      }
    },
  };
};

export function useToggleDebugPanel()  {
  const [sendCommandHandler] = useSendCommandMutation();
  return (deviceId: string, visible: boolean) => {
    sendCommandHandler(GetCommandOptions(PixonCommand.ToggleDebugPanel, deviceId, {visible: visible}));
  }
}

export function useToggleStatusBar()  {
  const [sendCommandHandler] = useSendCommandMutation();
  return (deviceId: string, visible: boolean) => {
    sendCommandHandler(GetCommandOptions(PixonCommand.ToggleStatusbar, deviceId, {visible: visible}));
  }
}

export function usePixonCommands(deviceId: string) {
  const [sendCommandHandler] = useSendCommandMutation();

  const reloadCommand = () => { sendCommandHandler(GetCommandOptions(PixonCommand.Reload, deviceId)) };
  const refreshContextCommand = () => { sendCommandHandler(GetCommandOptions(PixonCommand.RefreshContext, deviceId))};
  const unlinkCommand = () => { sendCommandHandler(GetCommandOptions(PixonCommand.Unlink, deviceId))};
  const requestScreenShotCommand = () => { sendCommandHandler(GetCommandOptions(PixonCommand.RequestScreenShot, deviceId))};

  return {
    reloadCommand,
    refreshContextCommand,
    unlinkCommand,
    requestScreenShotCommand
  }
}


