// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useTranslation} from 'react-i18next';
import {
  Selection,
  DetailsList,
  IColumn,
  DetailsListLayoutMode,
  SelectionMode,
  IContextualMenuProps,
  ContextualMenu
} from "@fluentui/react";

/* Local */
import {
  Asset,
  DataSource
} from "../../graphql/types";

import {useState} from "react";

// ----------------------------------------------------------------------------

export interface IDataSourcesListProps {
  dataSources: DataSource[],
  selection?: Selection,
  onEditDataSource: (dataSource: DataSource) => void
  onRemoveDataSources: () => void
}

const DataSourcesList = (props: IDataSourcesListProps) => {
  const {onEditDataSource, onRemoveDataSources, selection, dataSources} = props;
  const {t} = useTranslation();
  const [contextMenu, setContextMenu] = useState<IContextualMenuProps>();

  const defaultColumns: IColumn[] = [
    {
      key: 'name',
      name: t('dataSources:fields.name'),
      fieldName: 'name',
      minWidth: 210,
      maxWidth: 350,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      isPadded: true
    },
    {
      key: 'organization',
      name: t('dataSources:fields.organization'),
      fieldName: 'organization',
      minWidth: 210,
      maxWidth: 350,
      isResizable: true,
      data: 'string',
      onRender: (item: Asset) => {
        return <span>{item.organization!.name}</span>;
      },
      isPadded: true
    },
    {
      key: 'dataSourceType',
      name: t('dataSources:fields.dataSourceType'),
      fieldName: 'dataSourceType',
      minWidth: 70,
      maxWidth: 90,
      isRowHeader: false,
      isResizable: true,
      data: 'string',
      isPadded: true
    },
  ];

  const  _onItemContextMenu = (item: any, index?: number, ev?: Event): boolean => {
    const mouseEvent = ev! as MouseEvent;
    const dataSource = item as DataSource;

    const contextualMenuProps: IContextualMenuProps = {
      target: { x: mouseEvent.x, y: mouseEvent.y }, //ev!.target as HTMLElement,
      items: [
        {
          key: 'editSelected',
          text: t('common:menu.editSelected'),
          iconProps: {
            iconName: 'Edit'
          },
          onClick: () => {
            onEditDataSource(dataSource)
          }
        },
        {
          key: 'delete',
          iconProps: {
            iconName: 'Delete',
            style: {
              color: 'salmon'
            }
          },
          onClick: onRemoveDataSources,
          text: t('common:menu.removeSelected'),

        }],
      onDismiss: () => {
        setContextMenu(undefined);
      }
    };

    if (index! > -1) {
      setContextMenu(contextualMenuProps);
    }

    return false;
  };

  return (
    <>
      {contextMenu &&
        <ContextualMenu {...contextMenu} />
      }

      <DetailsList
        items={dataSources}
        compact={false}
        columns={defaultColumns}
        selectionMode={SelectionMode.multiple}
        selection={selection}
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
        selectionPreservedOnEmptyClick={true}
        enterModalSelectionOnTouch={true}
        onItemContextMenu={_onItemContextMenu}
      />
      </>
  );
};

export default DataSourcesList;
