// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {getTheme, Icon, IStackTokens, Stack, Text,} from "@fluentui/react";
/* Local */
import {PageComponent} from "../../graphql/types";
import {getHorizontalAlignIconName, getVerticalAlignIconName, preventContextmenu} from "../../components/api/util";
import {movableTargetClassname} from "./pageEditor";

// ----------------------------------------------------------------------------
const Rematrix = require('rematrix');

interface IPageEditorMovableComponentProps {
  pageComponent: PageComponent,
  onMouseDown: (event:any) => void
  setSelected: (element: HTMLElement) => void
  onEditComponent: (component: PageComponent) => void,
  viewScale: number
}

const stackTokens: IStackTokens = {
  padding: 's1',
  childrenGap: 'm'
};

const PageEditorMovableComponent = (props: IPageEditorMovableComponentProps) => {
  const {pageComponent, setSelected, onMouseDown, onEditComponent, viewScale} = props;
  const component = pageComponent.component!;
  const theme = getTheme();
  const componentId = 'comp-' + component.id!.split('-').join('');

  const calcStyle = () => {
    const scaleMatrix = Rematrix.scale(viewScale);
    const matrix = pageComponent.transform ? Rematrix.fromString(pageComponent.transform) : Rematrix.identity();

    // The transform will scale width and height too so no need to scale them individually
    const transform = Rematrix.toString([matrix, scaleMatrix].reduce(Rematrix.multiply));
    const width = pageComponent.width ? `${parseFloat(pageComponent.width)}px` : undefined;
    const height = pageComponent.height ? `${parseFloat(pageComponent.height)}px` : undefined;

    return {
      position: 'absolute' as 'absolute',
      borderStyle: 'dashed',
      opacity: 0.7,
      borderColor: theme.palette.themeSecondary,
      backgroundColor: theme.palette.themeLight,
      transform: transform,
      width: width,
      height: height
    }
  };

  return  <div
    onClick={event => {
      const hoveredTarget = document.querySelector('#' + componentId);
      setSelected(hoveredTarget as HTMLElement);
    }}
    onMouseDown={onMouseDown}
    onContextMenu={preventContextmenu}
    id={componentId}
    className={movableTargetClassname + ' editorComponent'}
    style={calcStyle()}
    data-id={component.id!}
  >
    <div
      onContextMenu={preventContextmenu}
      style={{
        textAlign: 'center'
      }}>

      <Stack onContextMenu={preventContextmenu}>
        <Text
          variant={"large"}
          styles={{
            root: {
              display: 'block'
            }
          }}
          onContextMenu={preventContextmenu}>
          {component.name}
        </Text>

        <Stack
          horizontal
          horizontalAlign={"center"}
          tokens={stackTokens}
          onContextMenu={preventContextmenu}>

          <Icon
            onContextMenu={preventContextmenu}
            iconName={getHorizontalAlignIconName(pageComponent.horizontalAlign!)}
            style={{
              color: theme.palette.themeSecondary,
              fontSize: 20,
              cursor: 'pointer'
            }}
          />

          <Icon
            onContextMenu={preventContextmenu}
            iconName='Settings'
            onClick={() => onEditComponent(pageComponent)}
            style={{
              color: theme.palette.black,
              fontSize: 20,
              cursor: 'pointer'
            }}
          />

          <Icon
            onContextMenu={preventContextmenu}
            iconName={getVerticalAlignIconName(pageComponent.verticalAlign!)}
            style={{
              color: theme.palette.themeSecondary,
              fontSize: 20,
              cursor: 'pointer'
            }}
          />
        </Stack>

        <Text
          variant={"small"}
          styles={{
            root: {
              display: 'block'
            }
          }}
          onContextMenu={preventContextmenu}>
          {`${pageComponent.width}x${pageComponent.height}`}
        </Text>

      </Stack>
    </div>
  </div>
};

export default PageEditorMovableComponent;
