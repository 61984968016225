import * as React from "react";
import logo from "../../assets/img/pixon-white-text.svg";
import styled from "@emotion/styled";

const LogoImage = styled("img")`
  padding-top: 10px;
  padding-left: 5px;
`;

const Logo: React.FunctionComponent = () => (
  <LogoImage src={logo} width={160} alt="Pixon Displays" />
);

export default Logo;
