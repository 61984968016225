import {Address, Asset, AssetType, Component, HorizontalAlign, User, VerticalAlign} from "../../graphql/types";
import {
  Aborter,
  AnonymousCredential,
  BlobURL,
  BlockBlobURL,
  ContainerURL,
  StorageURL,
  uploadBrowserDataToBlockBlob
} from "@azure/storage-blob";
import {TransferProgressEvent} from "@azure/ms-rest-js";

export const isEmpty = (s?: string | null) => (!s || !s.length);

export const fileExt = (filename: string): string => {
  const parts = filename.split('.');
  return parts[parts.length-1];
};

export const copyAndSort = <T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] => {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
};

export const getAssetUrl = (asset: Asset): string => `${window.CONTENT_BASE_URL}assets/${asset.id}${asset.lastShortTermReadToken}`;

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const http = async (request: RequestInfo): Promise<any> => {
  return new Promise(resolve => {
    fetch(request)
      .then(response => response.json())
      .then(body => {
        resolve(body);
      });
  });
};

export const UploadAsset = async (asset: Asset, file: Blob | ArrayBuffer | ArrayBufferView, contentType: string) => {
  // Blob url with write sas token
  const container = 'assets';
  const sasString = asset.lastShortTermWriteToken;
  const blobName = asset.id!;

  // Use AnonymousCredential when url already includes a SAS signature
  const anonymousCredential = new AnonymousCredential();
  const containerURL = new ContainerURL(
    `${window.CONTENT_BASE_URL}${container}?${sasString}`,
    StorageURL.newPipeline(anonymousCredential));

  const blobURL = BlobURL.fromContainerURL(containerURL, blobName);
  const blockBlobURL = BlockBlobURL.fromBlobURL(blobURL);

  await uploadBrowserDataToBlockBlob(Aborter.none, file, blockBlobURL, {
    blockSize: 4 * 1024 * 1024, // 4MB block size
    parallelism: 20, // 20 concurrency
    blobHTTPHeaders: {
      blobContentType: contentType
    },
    progress: (ev: TransferProgressEvent) => {
      console.debug(ev)
    }
  });
};

export const addressString = (address: Address) => {
  let result = address
    ? `${address.addressLine1 ? address.addressLine1 : ''}${address.streetNumber ? ' '
      + address.streetNumber : ''}${address.postalcode ? ', '
      + address.postalcode : ''}${address.city && address.city.name ? ', '
      + address.city.name : ''}`
    : '';

  result = result.trim();
  return !isEmpty(result) ? result : undefined;
};

export const getAssetDisplayText = (asset: Asset) => {
  return asset
    ? `${asset.displayName ? asset.displayName : asset.filename}${asset.organization && asset.organization.name ? ' (' + asset.organization.name + ')': ''}`
    : '';
};

export const getUserDisplayText = (user: User) => {
  return user
    ? `${user.fullName ? user.fullName : user.username ? user.username : user.email}`
    : '';
};

export const getComponentDisplayText = (component: Component) => {
  return component
    ? `${component.name ? component.name : component.componentType}`
    : '';
};

export const isEditableUsingTextEditor = (asset?: Asset) => {
  const editable = [
    AssetType.HandlebarsTemplate,
    AssetType.Data,
    AssetType.ResidentRegistry
  ];

  return asset && editable.includes(asset.assetType!);
};

export const isEditableUsingTableEditor = (asset?: Asset) => {
  return asset && asset.assetType === AssetType.ResidentRegistry
};

export const humanFileSize = (size: number) => {
  const i =  size === 0 ? 0 : Math.floor( Math.log(size) / Math.log(1024) );
  const val = ( size / Math.pow(1024, i) ).toFixed(1) ;
  return val + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

export const isNumeric = (val: any): val is number | string => {
  // https://github.com/ReactiveX/rxjs/blob/master/src/internal/util/isNumeric.ts
  // parseFloat NaNs numeric-cast false positives (null|true|false|"")
  // ...but misinterprets leading-number strings, particularly hex literals ("0x...")
  // subtraction forces infinities to NaN
  // adding 1 corrects loss of precision from parseFloat (#15100)
  return val && (val - parseFloat(val) + 1) >= 0;
};

export const identityMatrix  = [
  1, 0, 0, 0,
  0, 1, 0, 0,
  0, 0, 1, 0,
  0, 0, 0, 1,
];

export const isColor = (strColor?:string) => {
  if (!strColor)
    return false;

  const s = new Option().style;
  const before = s.color;
  s.color = strColor;

  // value persisted? then valid color!
  return s.color !== before;
};

export const getKeyByValue = (object:any, value:any) => {
  return Object.keys(object).find(key => object[key] === value);
};

export const preventContextmenu = (event: any) => {
  event.preventDefault();
  return false;
};

export const getHorizontalAlignIconName = (align: HorizontalAlign) => {
  switch (align) {
    case HorizontalAlign.Left:
      return 'AlignHorizontalLeft';
    case HorizontalAlign.Center:
      return 'AlignHorizontalCenter';
    case HorizontalAlign.Right:
      return 'AlignHorizontalRight';
  }
};

export const getVerticalAlignIconName = (align: VerticalAlign) => {
  switch (align) {
    case VerticalAlign.Top:
      return 'AlignVerticalTop';
    case VerticalAlign.Center:
      return 'AlignVerticalCenter';
    case VerticalAlign.Bottom:
      return 'AlignVerticalBottom';
  }
};
