// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {
  Checkbox,
  Slider,
} from "@fluentui/react";
import {useTranslation} from "react-i18next";
import { ISmhiWeatherConfiguration } from "pixon-component-core";

/* Local */
import {IComponentPropertyEditorProps, useComponentConfiguration} from "./propertyEditor";
import DefaultComponentEditorGroup from "./defaultComponentEditorGroup";
import {Asset, AssetType, DataSourceTypeEnum} from "../../../graphql/types";
import AssetsDropDown from "../../../components/assetsDropDown";

// ----------------------------------------------------------------------------

const SmhiWeatherComponentEditorGroup = (props: IComponentPropertyEditorProps) => {
  const {pageComponent, onChange} = props;
  const component = pageComponent.component!;
  const {t} = useTranslation();
  const [configuration, updateConfigurationProperty] = useComponentConfiguration<ISmhiWeatherConfiguration>(onChange, component);

  return <>

    <DefaultComponentEditorGroup
      showDataSourceEditor={true}
      dataSourceTypes={[DataSourceTypeEnum.WeatherForecastSmhi]}
      showCssEditor={true}
      heading={t('component:smhiWeatherComponentEditorGroup.heading')}
      themeChildren={<AssetsDropDown
        label={t('component:editSmhiWeatherComponent.iconPackLabel')}
        onChange={(a) => {
          onChange({ assets: [a] })
        }}
        assetTypes={[AssetType.WeatherIcons]}
        selected={component.assets ? component.assets[0] as Asset : undefined}
      />}
      {...props}>

      <Slider
        label={t('component:editSmhiWeatherComponent.numDaysToShow')}
        min={1}
        max={7}
        step={1}
        defaultValue={configuration ? configuration.numDaysToShow : 7}
        showValue={true}
        valueFormat={(value: number) => `${value}`}
        onChange={(value: number) => updateConfigurationProperty({numDaysToShow: value})}
      />

      <Slider
        label={t('component:editSmhiWeatherComponent.iconSize')}
        min={20}
        max={400}
        step={5}
        defaultValue={configuration ? configuration.iconSize : 60}
        showValue={true}
        valueFormat={(value: number) => `${value}`}
        onChange={(value: number) => updateConfigurationProperty({iconSize: value})}
      />

      <Checkbox
        label={t('component:editSmhiWeatherComponent.showDayLabel')}
        onChange={(ev: any, value?: boolean) => updateConfigurationProperty({showDayLabel: value})}
      />

      <Checkbox
        label={t('component:editSmhiWeatherComponent.showTemperatureLabel')}
        onChange={(ev: any, value?: boolean) => updateConfigurationProperty({showTemperatureLabel: value})}
      />

    </DefaultComponentEditorGroup>

  </>
};

export default SmhiWeatherComponentEditorGroup;
