// NPM
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {DefaultButton, Panel, PanelType, PrimaryButton, Stack, TextField} from "@fluentui/react";
// Local
import {DataSource, DataSourceInput, DataSourceTypeEnum, Organization,} from "../../graphql/types";

import OrganizationsDropDown from "../../components/organizationsDropdown";
import DataSourceTypeDropDown from "../../components/dataSourceTypeDropDown";

interface NewDataSourcePanelProps {
  isOpen: boolean;
  onCreateAndDismiss: (input: DataSourceInput) => void;
  onDismiss: () => void;
  dataSourceType: DataSourceTypeEnum
}

const NewDataSourcePanel = (props: NewDataSourcePanelProps) => {
  const {
    dataSourceType,
    onCreateAndDismiss,
    onDismiss
  } = props;
  const {t} = useTranslation();

  const timeStamp = new Date();
  const [organization, setOrganization] = useState<Organization>();
  const [editedDataSource, setEditedDataSource] = useState<DataSource>({
    name: 'New DataSource',
    dataSourceType: dataSourceType,
    created: timeStamp,
    updated: timeStamp
  });

  return (
    <Panel
      type={PanelType.medium}
      headerText={t('dataSources:newDataSourcesPanel.headerText')}
      closeButtonAriaLabel={t('common:button.close')}
      isOpen={props.isOpen}
      onDismiss={onDismiss}
      layerProps={
        {eventBubblingEnabled: true}
      }
      isBlocking={false}
    >
      <Stack tokens={{childrenGap: 10}}>
        <TextField label={t('dataSources:editJsonDataSourceComponent.nameLabel')}
                   placeholder={t('dataSources:editJsonDataSourceComponent.namePlaceholder')} autoFocus
                   value={editedDataSource.name}
                   onChange={(e, v) => setEditedDataSource({
                     ...editedDataSource,
                     name: v || ''
                   })}/>

        <DataSourceTypeDropDown
          defaultSelected={DataSourceTypeEnum.ResidentRegistry}
          onChange={(dst) => setEditedDataSource({
            ...editedDataSource,
            dataSourceType: dst
          })}
        />

        <OrganizationsDropDown

          onChange={(o) => setOrganization(o)}
          selectFirstIfAvailable={false}
          selectedOrganizationId={organization ? organization.id! : undefined}
        />

        <Stack horizontal tokens={{childrenGap: 10}} horizontalAlign="end">
          <DefaultButton onClick={onDismiss}>
            {t('common:button.cancel')}
          </DefaultButton>
          <PrimaryButton onClick={() => {
            const input = {
              configuration: editedDataSource.configuration,
              name: editedDataSource.name,
              organizationId: organization?.id,
              dataSourceType: editedDataSource.dataSourceType!
            };

            onCreateAndDismiss(input)
          }}>
            {t('common:button.add')}
          </PrimaryButton>
        </Stack>
      </Stack>
    </Panel>
  )
};

export default NewDataSourcePanel;
