// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {
  TextField
} from "@fluentui/react";
import {useTranslation} from "react-i18next";
import SubHeading from "../../../components/layout/subHeading";
import {ReactNode} from "react";
import {IDataSourceEditorProps} from "./dataSourceEditor";
import AssetsDropDown from "../../../components/assetsDropDown";
import IdField from "../../../components/idField";
import DefaultOAuthEditorGroup from "./defaultOAuthEditorGroup";

/* Local */

interface IDefaultDataSourceEditorGroupProps extends IDataSourceEditorProps {
  children?: ReactNode,
  showAssetsEditor?: boolean;
  showOAuthEditor?: boolean;
  assetTypes?: string[],
  assetLabel?: string
}
// ----------------------------------------------------------------------------
const DefaultDataSourceEditorGroup = (props: IDefaultDataSourceEditorGroupProps) => {
  const {
    dataSource,
    onChange,
    children,
    showAssetsEditor = false,
    showOAuthEditor = false,
    assetTypes,
    assetLabel
  } = props;
  const {t} = useTranslation();

  const assetLabelInternal = assetLabel ? assetLabel : t('component:defaultDataSourceEditorGroup.assetsLabel');

  return <>

    <SubHeading heading={t('component:defaultDataSourceEditorGroup.heading')}/>

    <TextField
      label={t('component:defaultDataSourceEditorGroup.nameLabel')}
      placeholder={t('component:defaultDataSourceEditorGroup.namePlaceHolder')}
      autoFocus
      defaultValue={dataSource.name}
      onChange={(e, v) => {
        onChange({name: v})
      }}/>

    <IdField id={dataSource.id} />

    {showOAuthEditor &&
      <DefaultOAuthEditorGroup dataSource={dataSource} onChange={onChange} />
    }

    {showAssetsEditor &&
    <AssetsDropDown
      label={assetLabelInternal}
      assetTypes={assetTypes}
      onChange={(selectedAsset) => onChange({assets: [selectedAsset]})}
      selected={dataSource && dataSource.assets && dataSource.assets.length > 0 ? dataSource.assets[0]! : undefined}
    />
    }

    {children}

  </>
};

export default DefaultDataSourceEditorGroup;
