// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import React, {useEffect} from "react";
import {RouteComponentProps} from "react-router";
import {withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  DocumentCard,
  DocumentCardActions,
  DocumentCardImage,
  DocumentCardLocation,
  DocumentCardTitle,
  ImageFit,
  MessageBar,
  MessageBarType,
  Stack,
  TooltipHost,
  Text, IDocumentCardStyles
} from "@fluentui/react";

/* Local */
import Loading from "../../components/layout/loading";
import {AsyncActionStatus} from "../../store/asyncAction/types";
import {loadDevicesAction} from "../../store/device/actions";
import {getDevices, getDevicesError, getDevicesAsyncStatus} from "../../store/device/reducers";
import {Device} from "../../graphql/types";

// ----------------------------------------------------------------------------
// TYPES

interface DevicesCardListProps extends RouteComponentProps {
  onEditClick: (device: Device) => void;
  onDeleteClick: (device: Device) => void;
}

// ----------------------------------------------------------------------------
// CONSTANTS

const tokens = {
  tenGapStack: {
    childrenGap: 10
  }
};

// ----------------------------------------------------------------------------
// COMPONENTS

const DevicesCardList =(props: DevicesCardListProps) => {
  // Hook into redux store information (important to load after useEffect)
  const dispatch = useDispatch();
  const entities = useSelector(getDevices);
  const loadStatus = useSelector(getDevicesAsyncStatus);
  const loadError = useSelector(getDevicesError);

  // Load entities if not already loaded
  useEffect(() => {
    if (loadStatus === AsyncActionStatus.UNSTARTED) {
      dispatch(loadDevicesAction());
    }
  });

  // Show spinner until done
  if (loadStatus === AsyncActionStatus.UNSTARTED || loadStatus === AsyncActionStatus.STARTED) {
    return <Loading/>
  }

  // Show message bar if something went wrong
  if (loadStatus === AsyncActionStatus.FAILED) {
    return (
      <MessageBar messageBarType={MessageBarType.error}>
        An error occured when retrieving the devices: {loadError?.message}
      </MessageBar>
    )
  }

  // Group by organization
  const grouped = entities.reduce((g: any, e: Device) => {
    g[e.organization!.name] = g[e.organization!.name] || [];
    g[e.organization!.name].push(e);
    return g;
  }, {});

  const cardStyles: IDocumentCardStyles = {
    root: {
      width: 320
    }
  };

  // Show entity cards
  return <Stack wrap tokens={tokens.tenGapStack}>
    {Object.keys(grouped)
      .sort((a,b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)
      .map((key: string, i:number) => (
      <div key={i}>
        <Text variant='large'>{key}</Text>

        <Stack horizontal wrap tokens={tokens.tenGapStack}>
          {grouped[key]
            .sort((a:Device,b:Device) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1)
            .map((e:Device, deviceIndex:number) => (

            <DocumentCard key={deviceIndex} onClick={() => props.history.push('/device/' + e.id)}  styles={cardStyles}>
              <DocumentCardImage
                height={150}
                imageFit={ImageFit.centerContain}
                imageSrc={e.screenShotThumbnailSasUrl!}
              />

              <TooltipHost content={e.ioTDeviceId} id={e.id!}>
                <DocumentCardTitle title={e.displayName} aria-labelledby={e.id} shouldTruncate={true}/>
              </TooltipHost>

              {e.organization &&
              <DocumentCardTitle title={e.organization.name} showAsSecondaryTitle />
              }

              {e.address &&
              <DocumentCardLocation location={e.address.addressLine1 + ', ' + e.address.city.name}
                                    ariaLabel={e.address.addressLine1 + ', ' + e.address.city.name}/>
              }

              {e.placement &&
              <DocumentCardLocation location={e.placement} ariaLabel={e.placement}/>
              }

              <DocumentCardActions
                actions={[
                  {
                    iconProps: {iconName: 'Delete'},
                    onClick: (ev) => {
                      props.onDeleteClick(e);
                      ev.stopPropagation();
                      ev.preventDefault();
                    },
                    ariaLabel: 'delete action'
                  },
                  {
                    iconProps: {iconName: 'Edit'},
                    onClick: (ev) =>{
                      props.onEditClick(e);
                      ev.stopPropagation();
                      ev.preventDefault();
                    },
                    ariaLabel: 'edit action'
                  }
                ]}
              />

            </DocumentCard>
          ))}
        </Stack>
      </div>
    ))}
  </Stack>
};

export default withRouter(DevicesCardList);
