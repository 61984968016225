// Describing the shape of the organization's slice of state
import {AsyncAction, AsyncActionStatus} from "../asyncAction/types";
import {Device} from "../../graphql/types";

export interface DevicesState {
  entities: Device[];
  asyncStatus: AsyncActionStatus;
  error?: Error
}

export const LOAD_DEVICES = 'LOAD_DEVICES';
export const LINK_DEVICE = "LINK_DEVICE";
export const UNLINK_DEVICE = "UNLINK_DEVICE";
export const UPDATE_DEVICE = "UPDATE_DEVICE";

type LoadDevicesAction = AsyncAction<typeof LOAD_DEVICES, Device[]>;
type UnlinkDeviceAction = AsyncAction<typeof UNLINK_DEVICE, Device>;
type LinkDeviceAction = AsyncAction<typeof LINK_DEVICE, Device>;
type UpdateDeviceAction = AsyncAction<typeof UPDATE_DEVICE, Device>;

export type DeviceActionTypes =
  LoadDevicesAction |
  LinkDeviceAction |
  UnlinkDeviceAction |
  UpdateDeviceAction;
