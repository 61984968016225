// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {Link, Slider, TextField} from "@fluentui/react";
import {useTranslation} from "react-i18next";
import { ICalendarDataSourceConfiguration } from "pixon-component-core";

/* Local */
import DefaultDataSourceEditorGroup from "./defaultDataSourceEditorGroup";
import {IDataSourceEditorProps, useDataSourceConfiguration} from "./dataSourceEditor";

// ----------------------------------------------------------------------------

const CalendarDataSourceEditorGroup = (props: IDataSourceEditorProps) => {
  const {dataSource, onChange} = props;
  const {t} = useTranslation();
  const [configuration, updateConfigurationProperty] = useDataSourceConfiguration<ICalendarDataSourceConfiguration>(onChange, dataSource);

  return  <>

    <DefaultDataSourceEditorGroup
      showAssetsEditor={false}
      {...props}>

      <TextField
        label={t('component:calendarDataSourceEditorGroup.icalUrlLabel')}
        placeholder={t('component:calendarDataSourceEditorGroupicalUrlPlaceHolder')}
        autoFocus
        defaultValue={configuration ? configuration.icalUrl : undefined}
        onChange={(e, v) => {
          updateConfigurationProperty({icalUrl: v})
        }}/>

      <Link href='http://icalshare.com/' target='_blank'>
        {t('component:clockComponentEditorGroup.icalUrlPublicCalendars')} &#x3E;&#x3E;
      </Link>

      <Link href='https://docs.pixon.app/admin-interface/hem' target='_blank'>
        {t('component:clockComponentEditorGroup.icalUrlHowTo')} &#x3E;&#x3E;
      </Link>

      <Slider
        label={t('component:calendarDataSourceEditorGroup.refreshIntervalInMinutes')}
        min={5}
        max={360}
        step={1}
        defaultValue={configuration ? configuration.refreshIntervalInMinutes : undefined}
        showValue={true}
        valueFormat={(value: number) => `${value} min`}
        onChange={(value: number) => updateConfigurationProperty({refreshIntervalInMinutes:value})}
      />

    </DefaultDataSourceEditorGroup>

  </>
};

export default CalendarDataSourceEditorGroup;
