// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {
  IStackTokens,
  Stack,
  Text,
} from "@fluentui/react";
import {Organization} from "../../graphql/types";
import {useTranslation} from "react-i18next";
import SubHeading from "../../components/layout/subHeading";
import {addressString} from "../../components/api/util";

/* Local */

// ----------------------------------------------------------------------------

interface IOrganizationPropertiesProps {
  organization: Organization
}

interface IPropertyProps {
  localizationKey: string,
  property?: any
}

const outerTokens: IStackTokens = {
  childrenGap: 's1',
};


const innerTokens: IStackTokens = {
  childrenGap: 's1',
};

const OrganizationProperties = (props: IOrganizationPropertiesProps) => {
  const {organization} = props;
  const {t} = useTranslation();

  const Property = ({localizationKey, property}: IPropertyProps) => {
    return <>
      {property &&
      <Text><strong>{t(localizationKey)}</strong>: {property.toString()}</Text>
      }
    </>
  };


  return <Stack horizontal tokens={outerTokens} horizontalAlign={"start"} >
      <Stack tokens={innerTokens} verticalAlign='start'>
        <SubHeading heading={t('organizations:organizationProperties.header')}/>

        <Property localizationKey='organizations:property.organizationName' property={organization.name}/>
        <Property localizationKey='organizations:property.organizationNumber'
                  property={organization.organizationNumber}/>
        <Property localizationKey='organizations:property.url' property={organization.url}/>
      </Stack>

    <Stack tokens={innerTokens} verticalAlign='start'>
      <SubHeading heading={t('organizations:organizationProperties.billingHeader')}/>

      <Property localizationKey='organizations:property.vatNumber' property={organization.vatNumber}/>
      <Property localizationKey='organizations:property.billingEmail' property={organization.billingEmail}/>

      {organization.billingAddress &&
      <Property localizationKey='organizations:property.billingAddressLine1'
                property={addressString(organization.billingAddress)}/>
      }
    </Stack>

    <Stack tokens={innerTokens} verticalAlign='start'>
      <SubHeading heading={t('organizations:organizationProperties.contactHeader')}/>

      <Property localizationKey='organizations:property.phoneNumber' property={organization.phoneNumber}/>
      <Property localizationKey='organizations:property.contactEmail' property={organization.contactEmail}/>

      {organization.officeAddress &&
      <Property localizationKey='organizations:property.officeAddressLine1'
                property={addressString(organization.officeAddress)}/>
      }
    </Stack>

    <Stack tokens={innerTokens} verticalAlign='start'>
      <SubHeading heading={t('organizations:organizationProperties.subscriptionHeader')}/>

      <Property localizationKey='organizations:property.subscriptionName' property={t(organization.organizationSubscription?.subscriptionPlan?.nameTranslationKey ?? '')}/>
      <Property localizationKey='organizations:property.subscriptionQuantity' property={organization.organizationSubscription?.quantity}/>
      <Property localizationKey='organizations:property.subscriptionExpiryDate' property={organization.organizationSubscription?.validTo}/>

    </Stack>
  </Stack>
};

export default OrganizationProperties;
