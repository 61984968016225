import {ValidationSchema} from "class-validator";

// See: https://github.com/typestack/class-validator/issues/147
const isOptional = (fieldName: string) => {
  return {
    type: 'conditionalValidation',
    constraints: [(object: any, value: any) => {
      return object[fieldName] !== null && object[fieldName] !== undefined && object[fieldName] !== '';
    }], options: undefined
  }
};

export let UpdateOrganizationValidationSchema: ValidationSchema = {
  name: "updateOrganizationValidationSchema",
  properties:  {
    name: [{
      type: 'isNotEmpty',
      message: 'organizations:validation.nameisnotempty',
    }],
    billingEmail: [{
      type: 'isEmail',
      constraints: [],
      message: 'organizations:validation.billingemailisEmail'
    }],
    billingAddress: [{
      type: 'isNotEmpty',
      message: 'organizations:validation.billingaddressisNotEmpty'
    }],
    contactEmail: [{
      type: 'isEmail',
      constraints: [],
      message: 'organizations:validation.contactemailisEmail'
    }, isOptional('contactEmail')],
    phoneNumber: [{
      type: 'isPhoneNumber',
      constraints: [],
      message: 'organizations:validation.phonenumberisPhoneNumber'
    }, isOptional('phoneNumber')],
    url: [{
      type: 'isUrl',
      constraints: [],
      message: 'organizations:validation.urlisUrl'
    }, isOptional('url')],
  }
};
