// NPM
import * as React from "react";
import {useTranslation} from "react-i18next";
import {
  FontWeights,
  getTheme,
  Icon,
  IIconStyles,
  ITextStyles, MessageBar, MessageBarType,
  Stack,
  Text,
} from "@fluentui/react";
import {
  Maybe,
  Organization,
  PaymentMethod,
  useRemoveOrganizationPaymentMethodMutation
} from "../../graphql/types";
import SubHeading from "../../components/layout/subHeading";
import {Card, ICardSectionStyles, ICardSectionTokens, ICardTokens} from "@uifabric/react-cards";
import moment from "moment-timezone";
import {useState} from "react";

// Local
interface PaymentMethodsListProps {
  organization: Organization;
  onRefetch: () => void;
}

const iconStyles: IIconStyles = {
  root: {
    color: 'salmon',
    fontSize: 16,
    fontWeight: FontWeights.regular,
  }
};
const footerCardSectionStyles: ICardSectionStyles = {
  root: {
    width: '30px',
    borderLeft: '1px solid #F3F2F1'
  }
};
const cardTokens: ICardTokens = {childrenMargin: 12};
const footerCardSectionTokens: ICardSectionTokens = {padding: '0px 0px 0px 12px'};

const PaymentMethodsList = (props: PaymentMethodsListProps) => {
  const {t} = useTranslation();
  const {organization, onRefetch} = props;
  const [removeOrganizationPaymentMethod] = useRemoveOrganizationPaymentMethodMutation();
  const theme = getTheme();
  const {palette} = theme;
  const [messageType, setMessageType] = useState<MessageBarType>();
  const [message, setMessage] = useState('');

  const paymentMethods = organization.paymentMethods ?? [];
  const displayError = (m: string) => {
    setMessageType(MessageBarType.error);
    setMessage(m);
  };

  const displaySuccess = (m: string) => {
    setMessageType(MessageBarType.success);
    setMessage(m);
  };

  const siteTextStyles: ITextStyles = {
    root: {
      color: palette.themePrimary,
      fontWeight: FontWeights.semibold,
      width: '250px'
    }
  };
  const descriptionTextStyles: ITextStyles = {
    root: {
      color: palette.neutralDark,
      fontWeight: FontWeights.regular
    }
  };

  const removePaymentMethod = (paymentMethod:PaymentMethod) => {
    removeOrganizationPaymentMethod({
      variables: {
        input: {
          organizationId: organization.id!,
          paymentMethodId: paymentMethod.id
        }
      }
    })
      .then(value => {
        displaySuccess(t('component:paymentMethodsList.paymentMethodSuccessfullyRemovedMessage'));
        onRefetch();
      })
      .catch(reason => displayError(reason.toString()));
  };

  const defaultPaymentMethods = paymentMethods.filter(pm => pm!.isDefault);
  const previousPaymentMethods = paymentMethods.filter(pm => !pm!.isDefault);

  const paymentMethodsList = (methods: Maybe<PaymentMethod>[]) => methods?.map(pm =>
    <Card key={pm!.id!} tokens={cardTokens}>
      <Card.Section>
        <Stack horizontal horizontalAlign={"space-between"}>
          <Text variant="small" styles={siteTextStyles}>
            {pm!.name}
          </Text>
          <Text styles={descriptionTextStyles}>Registrerat {moment(pm!.created).format('MMM D, YYYY')}</Text>
        </Stack>
      </Card.Section>
      <Card.Section styles={footerCardSectionStyles} tokens={footerCardSectionTokens}>
        <Icon iconName="Delete" styles={iconStyles} onClick={() => removePaymentMethod(pm!)}/>
      </Card.Section>
    </Card>
  );

  return <>
    {message !== '' &&
    <MessageBar messageBarType={messageType}>
      {message}
    </MessageBar>
    }

    <SubHeading heading={t('component:managePaymentMethodsForm.paymentMethodsHeading')}/>
    <Stack tokens={{childrenGap: 10}} horizontalAlign={"start"}>

      {paymentMethods.length > 0 &&
      <>
        {paymentMethodsList(defaultPaymentMethods)}
      </>
      }

      {paymentMethods.length <= 0 &&
      <Text>{t('component:managePaymentMethodsForm.noPaymentMethodsMessage', {organization})}</Text>
      }
    </Stack>


    <SubHeading heading={t('component:PaymentMethodsList.previousPaymentMethodsHeading')}/>
    <Stack tokens={{childrenGap: 10}} horizontalAlign={"start"}>

      {paymentMethods.length > 0 &&
      <>
        {paymentMethodsList(previousPaymentMethods)}
      </>
      }

      {paymentMethods.length <= 0 &&
      <Text>{t('component:managePaymentMethodsForm.noPaymentMethodsMessage', {organization})}</Text>
      }
    </Stack>

  </>
};

export default PaymentMethodsList;
