// NPM
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  DefaultButton,
  Panel,
  PanelType, PrimaryButton,
  Stack, TextField
} from "@fluentui/react";

// Local
import {
  Asset
} from "../../graphql/types";

interface EditAssetPanelProps {
  isOpen: boolean;
  onUpdateAndDismiss: (newOrUpdated: Asset) => void;
  onDismiss: () => void;
  asset: Asset
}

const EditAssetPanel = (props: EditAssetPanelProps) => {
  const {
    asset,
    onUpdateAndDismiss,
    onDismiss
  } = props;
  const {t} = useTranslation();

  const [editedAsset, setEditedAsset] = useState<Asset>(asset);

  useEffect(() => {
    setEditedAsset(asset);
  }, [asset]);

  return (
    <Panel
      type={PanelType.medium}
      headerText={t('assets:editAssetPanel.headerText')}
      closeButtonAriaLabel={t('common:button.close')}
      isOpen={props.isOpen}
      onDismiss={onDismiss}
      isBlocking={true}
    >
      <Stack tokens={{childrenGap: 10}}>
        {editedAsset &&
        <>
            <TextField label={t('assets:editAssetPanel.displayNameLabel')}
                       placeholder={t('assets:editAssetPanel.displayNamePlaceholder')} autoFocus
                       value={editedAsset.displayName ? editedAsset.displayName : ''}
                       onChange={(e, v) => setEditedAsset({
                         ...editedAsset,
                         displayName: v
                       })}/>

            <TextField label={t('assets:editAssetPanel.filenameLabel')}
                       placeholder={t('assets:editAssetPanel.filenamePlaceholder')} autoFocus
                       value={editedAsset.filename ? editedAsset.filename : ''}
                       onChange={(e, v) => setEditedAsset({
                         ...editedAsset,
                         filename: v || ''
                       })}/>

            <Stack horizontal tokens={{childrenGap: 10}} horizontalAlign="end">
                <DefaultButton onClick={() => {
                  // Undo changes
                  setEditedAsset(asset);

                  // Close
                  onDismiss();
                }}>
                  {t('common:button.cancel')}
                </DefaultButton>
                <PrimaryButton onClick={() => onUpdateAndDismiss(editedAsset)}>
                  {t('common:button.update')}
                </PrimaryButton>
            </Stack>

        </>
        }

      </Stack>
    </Panel>
  )
};

export default EditAssetPanel;
