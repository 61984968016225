// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {CommandBar, ICommandBarItemProps,} from "@fluentui/react";
import {useTranslation} from 'react-i18next';

/* Local */
import {useState} from "react";
import {Organization} from "../../graphql/types";
import EditOrganizationPanel from "./editOrganizationPanel";
import DeleteOrganizationPanel from "./deleteOrganizationPanel";
import {RouteComponentProps} from "react-router";
import AddSubscriptionPanel from "./manageSubscriptionPanel";
import { useKeycloak } from '@react-keycloak/web'
import {withRouter} from "react-router-dom";

// ----------------------------------------------------------------------------

interface IOrganizationCommandBarProps extends RouteComponentProps {
  organization: Organization;
  refetch: any,
  showAddPayment: boolean
}

const OrganizationCommandBar = (props: IOrganizationCommandBarProps) => {
  const { organization, refetch, showAddPayment } = props;
  const { t } = useTranslation();
  const [showEditOrganizationPanel, toggleShowEditOrganizationPanel] = useState(false);
  const [showDeleteOrganizationPanel, toggleDeleteOrganizationPanel] = useState(false);
  const [showAddSubscriptionPanel, toggleShowAddSubscriptionPanel] = useState(showAddPayment);
  const {keycloak} = useKeycloak();

  const commandBarItems: ICommandBarItemProps[] =  [
    {
      key: 'refreshOrganizationInfo',
      name: t('organizations:commandbar.refreshOrganizationInfo'),
      iconProps: {
        iconName: 'Refresh'
      },
      onClick: () => refetch(),
    },
    {
      key: 'editOrganization',
      name: t('organizations:commandbar.editOrganization'),
      iconProps: {
        iconName: 'Edit'
      },
      onClick: () => toggleShowEditOrganizationPanel(!showEditOrganizationPanel)
    },
    {
      key: 'removeOrganization',
      name: t('organizations:commandbar.removeOrganization'),
      iconProps: {
        iconName: "Delete",
        style: {
          color: 'salmon'
        }
      },
      onClick: () => toggleDeleteOrganizationPanel(!showDeleteOrganizationPanel)
    }
  ];

  if (keycloak.hasRealmRole('developer'))
  {
    commandBarItems.push({
      key: 'addOrganizationSubscription',
      name: t('organizations:commandbar.addOrganizationSubscription'),
      iconProps: {
        iconName: 'PaymentCard'
      },
      onClick: () => toggleShowAddSubscriptionPanel(!showAddSubscriptionPanel)
    });
  }

  return  <>
    <CommandBar items={commandBarItems}  />

    <DeleteOrganizationPanel organization={organization} isOpen={showDeleteOrganizationPanel} onDismiss={() => {
      toggleDeleteOrganizationPanel(false);
      props.history.push('/organizations');
    }} />
    <EditOrganizationPanel organizationId={organization.id!} isOpen={showEditOrganizationPanel} onDismiss={() => {
      toggleShowEditOrganizationPanel(false);
      refetch();
    }}/>

    <AddSubscriptionPanel organization={organization} isOpen={showAddSubscriptionPanel} onDismiss={() => {
      toggleShowAddSubscriptionPanel(false);
      refetch();
    }}/>
  </>
};

export default withRouter(OrganizationCommandBar);
