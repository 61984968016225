// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import React from "react";
import MonacoEditor from "react-monaco-editor";

/* Local */
import SubHeading from "../../components/layout/subHeading";
import ClipBoardButton from "../../components/layout/clipBoardButton";

// ----------------------------------------------------------------------------


interface DebugEditorViewProps {
  heading: string,
  children: React.ReactNode,
  value: any,
  clipBoardValue?: string
}

const DebugEditorView = (props: DebugEditorViewProps) => {
  const stringValue = JSON.stringify(props.value, null, 2);
  const clipBoardValue = props.clipBoardValue || stringValue;

  return (
    <>
        <div style={{ display: 'flex' }}>
          <SubHeading heading={props.heading} />
          <ClipBoardButton value={clipBoardValue} />
        </div>
        {props.children}
        <MonacoEditor
          width="100%"
          height="500"
          language="json"
          theme="vs-light"
          value={stringValue}
        />
      </>
  )
};


export default DebugEditorView;
