// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import DefaultComponentEditorGroup from "./defaultComponentEditorGroup";
import {IComponentPropertyEditorProps, useComponentConfiguration} from "./propertyEditor";
import {useTranslation} from "react-i18next";
import {Slider, TextField} from "@fluentui/react";
import {DataSourceTypeEnum} from "../../../graphql/types";
import { IDeviceVoteCategoryButtonConfiguration } from "pixon-component-core";

/* Local */

// ----------------------------------------------------------------------------

const DeviceVoteCategoryButtonComponentEditorGroup = (props: IComponentPropertyEditorProps) => {
  const {pageComponent, onChange } = props;
  const component = pageComponent.component!;
  const {t} = useTranslation();
  const [configuration, updateConfigurationProperty] = useComponentConfiguration<IDeviceVoteCategoryButtonConfiguration>(onChange, component);

  return <>

    <DefaultComponentEditorGroup
      showDataSourceEditor={true}
      dataSourceTypes={[DataSourceTypeEnum.DeviceEvent]}
      showCssEditor={false}
      showPositionEditor={false}
      showClassNameProperty={true}
      heading={t('component:deviceVoteCategoryButtonComponentEditorGroup.heading')}
      {...props}>

      <Slider
        label={t('component:deviceVoteCategoryButtonComponentEditorGroup.voteLabel')}
        min={-1}
        max={1}
        step={1}
        defaultValue={configuration ? configuration.vote : 1}
        showValue={true}
        valueFormat={(value: number) => `${value}`}
        onChange={(value: number) => updateConfigurationProperty({vote: value})}
      />

      <TextField
        label={t('component:deviceVoteCategoryButtonComponentEditorGroup.voteShowSelectorLabel')}
        placeholder={t('component:deviceVoteCategoryButtonComponentEditorGroup.voteSelectorPlaceholder')}
        value={configuration ? configuration.onVoteShowSelector : '.items-to-show-on-vote'}
        onChange={(e, v) => updateConfigurationProperty({
          onVoteShowSelector: v
        })}
      />

      <TextField
        label={t('component:deviceVoteCategoryButtonComponentEditorGroup.voteHideSelectorLabel')}
        placeholder={t('component:deviceVoteCategoryButtonComponentEditorGroup.voteHideSelectorPlaceholder')}
        value={configuration ? configuration.onVoteHideSelector : '.items-to-hide-on-vote'}
        onChange={(e, v) => updateConfigurationProperty({
          onVoteHideSelector: v
        })}
      />
    </DefaultComponentEditorGroup>


  </>
};

export default DeviceVoteCategoryButtonComponentEditorGroup;
