// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {AssetType} from "../../../graphql/types";
import {IDataSourceEditorProps} from "./dataSourceEditor";
import DefaultDataSourceEditorGroup from "./defaultDataSourceEditorGroup";

/* Local */

// ----------------------------------------------------------------------------

const EditJsonAssetDataSourceForm = (props: IDataSourceEditorProps) => {
  return <DefaultDataSourceEditorGroup
      showAssetsEditor={true}
      assetTypes={[AssetType.Data]}
      {...props} />
};

export default EditJsonAssetDataSourceForm;
