// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {ContextualMenu, IContextualMenuProps} from "@fluentui/react";
import {Component, PageComponent} from "../../graphql/types";

/* Local */

// ----------------------------------------------------------------------------

interface IPageEditorContextMenuProps {
  menu?: IContextualMenuProps
}

export const createContextMenu = (
  selectedComponent: PageComponent,
  mouseEvent: MouseEvent,
  onEditComponent: (component: PageComponent) => void,
  onRemoveComponent: (component: Component) => void,
  setSelected: (element?: HTMLElement) => void,
  hideContextMenu: () => void,
  t: any
) => {
  return {
    target: {x: mouseEvent.x, y: mouseEvent.y},
    items: [
      {
        key: 'editSelected',
        text: t('common:menu.editSelected'),
        iconProps: {
          iconName: 'Edit'
        },
        onClick: () => {
          onEditComponent(selectedComponent)
        }
      },
      {
        key: 'removeSelected',
        text: t('common:menu.removeSelected'),
        iconProps: {
          iconName: 'Delete',
          style: {
            color: 'salmon'
          }
        },
        onClick: () => {
          onRemoveComponent(selectedComponent.component!);
          setSelected(undefined);
        }
      }
    ]
    ,
    onDismiss: () => {
      hideContextMenu();
    }
  };
};


const PageEditorContextMenu = (props: IPageEditorContextMenuProps) => {
  const {menu} = props;

  return  <>
    {menu &&
      <ContextualMenu {...menu} />
    }
    </>
};

export default PageEditorContextMenu;
