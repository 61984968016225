import * as React from "react";
import { Text } from "@fluentui/react";

interface PageHeadingProps {
  heading: string;
}

const SubHeading: React.FunctionComponent<PageHeadingProps> = props => (
  <Text variant={"large"} block id="pageHeading">
    {props.heading}
  </Text>
);

export default SubHeading;
