import * as React from 'react';

import {
  CommandBar,
  ICommandBarItemProps
} from "@fluentui/react";

import {useTranslation} from "react-i18next";

export interface IDataSourcesCommandBar {
  onAddNewDataSource: () => void
  onEditDataSource: () => void
  onRemoveDataSource: () => void
}

const DataSourcesCommandBar = (props: IDataSourcesCommandBar) => {
  const {onAddNewDataSource, onEditDataSource, onRemoveDataSource} = props;
  const {t} = useTranslation();

    const _getItems = (): ICommandBarItemProps[] => {
    return [
      {
        key: 'newDataSource',
        name: t('dataSources:commandbar.newDataSource'),
        iconProps: {
          iconName: 'Add'
        },
        onClick: () => onAddNewDataSource()
      },
      {
        key: 'editSelected',
        name: t('common:menu.editSelected'),
        iconProps: {
          iconName: 'Edit'
        },
        onClick: onEditDataSource
      },
      {
        key: 'delete',
        iconProps: {
          iconName: 'Delete',
          style: {
            color: 'salmon'
          }
        },
        onClick: onRemoveDataSource,
        name: t('common:menu.removeSelected'),

      }
    ];
  };

  return <>
    <CommandBar items={_getItems()}/>

  </>;
};

export default DataSourcesCommandBar;
