//node constructor class
import {LGraphNode, LiteGraph} from "litegraph.js";

export enum PixonGraphComponents {
  VisualComponent = "pixon/visualcomponent",
  Page = "pixon/page",
  Presentation = "pixon/presentation",
  DataSource = "pixon/datasource",
  CSStyle = "pixon/csstyle",
}

class VisualComponentNode extends LGraphNode {

  constructor() {
    super();
    this.addInput("Data Source","string");
    this.addInput("CSS Style","number");
    this.addInput("Position","number");

    this.title = "Visual Component";
  }

  onExecute()
  {
    var A = this.getInputData(0);
    if( A === undefined )
      A = 0;
    var B = this.getInputData(1);
    if( B === undefined )
      B = 0;
    this.setOutputData( 0, A + B );
  }
}


class DataSourceNode extends LGraphNode {

  constructor() {
    super();
    this.addOutput("Data","string");

    this.title = "Datasource";
  }

  onExecute()
  {

  }
}

class CSStyleNode extends LGraphNode {

  constructor() {
    super();
    this.addOutput("Style","number");

    this.title = "Style";
  }

  onExecute()
  {
    
  }
}

//register in the system
LiteGraph.registerNodeType(PixonGraphComponents.VisualComponent, VisualComponentNode );
LiteGraph.registerNodeType(PixonGraphComponents.DataSource, DataSourceNode );
LiteGraph.registerNodeType(PixonGraphComponents.CSStyle, CSStyleNode );
