import * as React from 'react';

import {
  Callout,
  ColorPicker, DirectionalHint,
  IColor,
  Label, SwatchColorPicker
} from "@fluentui/react";
import {useState} from "react";
import {useRef} from "react";
import {isColor} from "./api/util";

export interface IColorPickerButtonProps {
  label?: string,
  color?: string,
  onChange: (color: IColor) => void;
}

const ColorPickerButton = (props: IColorPickerButtonProps) => {
  const {
    label,
    color = 'black',
    onChange
  } = props;

  const [isCalloutVisible, setIsCalloutVisible] = useState(false);
  const _menuButtonElement = useRef<HTMLDivElement>(null);

  return <div>

    {label &&
    <Label>{label}</Label>
    }

    <div
      ref={_menuButtonElement}
      onClick={() => setIsCalloutVisible(!isCalloutVisible)}
    >
      <SwatchColorPicker
        columnCount={1}
        cellHeight={50}
        cellWidth={50}
        cellShape={'square'}
        colorCells={[{
          id: 'c',
          label: color,
          color: isColor(color) ? color : 'black'
        }]}
      />
    </div>

    <Callout
        gapSpace={0}
        target={_menuButtonElement.current}
        onDismiss={() => setIsCalloutVisible(false)}
        setInitialFocus={true}
        hidden={!isCalloutVisible}
        directionalHint={DirectionalHint.rightCenter}
    >
        <ColorPicker
            color={color}
            onChange={(ev, c) => onChange(c)}
            alphaSliderHidden={false}
            showPreview
        />
    </Callout>

  </div>;
};

export default ColorPickerButton;
