// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {
  Stack,
  Dialog,
  IconButton,
  Image,
  ImageFit,
  Link,
  Text
} from "@fluentui/react";
import SubHeading from "../../components/layout/subHeading";
import {usePixonCommands} from "../../components/api/usePixonCommand";
import {Device} from "../../graphql/types";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Depths} from "@uifabric/fluent-theme";

/* Local */

// ----------------------------------------------------------------------------
export enum DeviceScreenShotMode {
  Thumbnail,
  Full
}
interface IDeviceScreenShotProps {
  device: Device,
  mode: DeviceScreenShotMode,
  triggerRefreshOnLoad: boolean
  refreshDoneCallback: () => void
}

const DeviceScreenShot = (props: IDeviceScreenShotProps) => {
  const {device,triggerRefreshOnLoad, refreshDoneCallback} = props;
  const isFull = props.mode === DeviceScreenShotMode.Full;
  const {t} = useTranslation();
  const {requestScreenShotCommand } = usePixonCommands(device.id!);
  const [url, setUrl] = useState(isFull ? device.screenShotSasUrl! : device.screenShotThumbnailSasUrl!);
  const [showFull, setShowFull] = useState(false);

  const waitTimeInSeconds = 10;
  const [showRequestButton, setShowRequestButton] = useState(true);
  const [seconds, setSeconds] = useState(waitTimeInSeconds);
  const [timerID, setTimerId] = useState<any>(null);

  useEffect(() => {
    if (triggerRefreshOnLoad) {
      refreshScreenShot()
    }
  } );

  //Replaces componentDidMount and componentWillUnmount
  useEffect(() => {
    if (seconds < 1) {
      clearInterval(timerID);
      setShowRequestButton(true);

      // Clear existing
      const hash = '#' + Date.now();

      // Trigger reload
      setUrl(isFull ? device.screenShotSasUrl! + hash : device.screenShotThumbnailSasUrl! + hash)
    }

  }, [seconds, timerID, isFull, device.screenShotSasUrl, device.screenShotThumbnailSasUrl]);

  const tick = ()  => {
    setSeconds(prev => prev - 1);
  };

  const refreshScreenShot = () => {
    refreshDoneCallback();
    // Rend command to device to upload a new screenshot
    requestScreenShotCommand();
    setShowRequestButton(false);
    const timer = setInterval(() => tick(), 1000);
    setTimerId(timer);
  };

  const width = isFull ? 640 : 160;
  const height = isFull ? 480 : 120;

  return <div style={{
    boxShadow: Depths.depth4,
    padding: 10
  }} >
    <div style={{
      display: 'flex'
    }} >
      <SubHeading heading={t('devices:deviceScreenShot.heading')}/>
      {showRequestButton &&
        <IconButton onClick={() => refreshScreenShot()}iconProps={{ iconName: 'Refresh' }} title={t('devices:deviceScreenShot.refreshTooltip')} />
      }

      {!showRequestButton &&
        <Text>&nbsp;&nbsp;...{seconds}</Text>
      }
    </div>
    <Image width={width} height={height} imageFit={ImageFit.centerContain} src={url} />
    <Link onClick={() => setShowFull(true)}>{t('devices:deviceScreenShot.viewLargeLink')}</Link>

    <Dialog hidden={!showFull} onDismiss={() => setShowFull(false)} title={t('devices:deviceScreenShot.fullScreenHeading')}
            modalProps={{
              containerClassName:'screenShotDialog'
            }}>
      <Stack horizontal horizontalAlign={"center"}>
        <Stack verticalAlign={"center"}>
          <Image width={640} height={480} imageFit={ImageFit.centerContain} src={device.screenShotSasUrl!} />
        </Stack>
      </Stack>
    </Dialog>
  </div>
};

export default DeviceScreenShot;
