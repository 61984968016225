// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {
  TextField
} from "@fluentui/react";
import {useTranslation} from "react-i18next";
import { ISendMessageButtonConfiguration } from "pixon-component-core";

/* Local */
import {IComponentPropertyEditorProps, useComponentConfiguration} from "./propertyEditor";
import DefaultComponentEditorGroup from "./defaultComponentEditorGroup";
import { DataSourceTypeEnum} from "../../../graphql/types";
import DataSourceDropDown from "../../../components/dataSourceDropDown";

// ----------------------------------------------------------------------------

const DeviceSendMessageButtonComponentEditorGroup = (props: IComponentPropertyEditorProps) => {
  const {pageComponent, onChange} = props;
  const component = pageComponent.component!;
  const {t} = useTranslation();
  const [configuration, updateConfigurationProperty] = useComponentConfiguration<ISendMessageButtonConfiguration>(onChange, component);

  return <>

    <DefaultComponentEditorGroup
      showDataSourceEditor={false}
      showCssEditor={false}
      heading={t('component:deviceSendMessageButtonComponentEditorGroup.heading')}
      {...props}>

      <TextField
        label={t('component:editDeviceSendMessageButtonComponent.buttonLabelLabel')}
        placeholder={t('component:editDeviceSendMessageButtonComponent.buttonLabelPlaceholder')}
        value={configuration ? configuration.label : ''}
        onChange={(e, v) => updateConfigurationProperty( {
          label: v
        })}
      />

      <TextField
        label={t('component:editDeviceSendMessageButtonComponent.messageLabel')}
        value={configuration ? configuration.message : ''}
        onChange={(e, v) => updateConfigurationProperty( {
          message: v
        })}
      />

      <DataSourceDropDown
        label={t('component:editDeviceSendMessageButtonComponent.dataSourceLabel')}
        placeholder={t('component:editDeviceSendMessageButtonComponent.dataSourcePlaceholder')}
        dataSourceTypes={[DataSourceTypeEnum.DeviceMessage]}
        onChange={(selectedDataSource) => onChange({dataSource: selectedDataSource})}
        selected={component.dataSource ? component.dataSource : undefined}
      />

    </DefaultComponentEditorGroup>

  </>
};

export default DeviceSendMessageButtonComponentEditorGroup;
