// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import {CSSProperties, useEffect, useState} from "react";
import {
  Stack,
  TextField
} from "@fluentui/react";
import {useTranslation} from "react-i18next";
import {IComponentPropertyEditorProps} from "./propertyEditor";
import { useKeycloak } from '@react-keycloak/web';
import ColorPickerButton from "../../../components/colorPickerButton";
import FontCombobox from "../../../components/fontComboBox";
import FontSizeCombobox from "../../../components/fontSizeComboBox";
import SubHeading from "../../../components/layout/subHeading";

/* Local */

// ----------------------------------------------------------------------------

const CssPropertiesEditorGroup = (props: IComponentPropertyEditorProps) => {
  const {pageComponent, onChange} = props;
  const {t} = useTranslation();
  const { keycloak } = useKeycloak();
  const component = pageComponent.component!;
  const [style, setStyle] = useState<CSSProperties>(component.style ? JSON.parse(component.style) : undefined);
  const [stringStyle, setStringStyle] = useState('{}');
  const [cssError, setCssError] = useState();

  useEffect(() => {
    if (!style)
      return;

    const serialized = JSON.stringify(style, null, 2);

    setStringStyle(serialized);
    onChange({
      style: serialized
    });
  }, [onChange, style]);

  const updateProperty = (partialUpdate: Partial<CSSProperties>) => {
    setStyle((prevState:CSSProperties) => {
      return {
        ...prevState,
        ...partialUpdate
      }
    });

  };

  return <>

    <SubHeading heading={t('component:cssPropertiesEditorGroup.heading')}/>

    <FontCombobox
      label={t('component:cssPropertiesEditorGroup.fontFamilyLabel')}
      defaultSelectedFont={style && style.fontFamily ? style.fontFamily : undefined}
      onChange={fontFamily => updateProperty( {
        fontFamily: fontFamily
      })}
    />

    <FontSizeCombobox
      label={t('component:cssPropertiesEditorGroup.fontSizeLabel')}
      defaultSize={style && style.fontSize ? style.fontSize : undefined}
      onChange={fontSize => updateProperty( {
        fontSize: fontSize
      })}
    />

    <Stack horizontal horizontalAlign={'start'}>
      <ColorPickerButton
          label={t('component:cssPropertiesEditorGroup.colorLabel')}
          color={style && style.color ? style.color.toString() : 'black'}
          onChange={c => updateProperty( {
            color: c.str
          })}
      />

      <ColorPickerButton
        label={t('component:cssPropertiesEditorGroup.backgroundColorLabel')}
        color={style && style.backgroundColor ? style.backgroundColor.toString() : 'white'}
        onChange={c => updateProperty( {
          backgroundColor: c.str
        })}
      />
    </Stack>

    {keycloak.hasRealmRole('developer') &&
      <TextField
        label={t('component:editTextTableComponent.cssStyleLabel')}
        multiline
        autoAdjustHeight
        errorMessage={cssError}
        value={stringStyle}
        onChange={(e, v) => {
          const parseCssValue = (value: string) => {
            try {
              const s = JSON.parse(value) as CSSProperties;
              setCssError(undefined);
              return s;
            } catch {
              setCssError(t('common:cssValueError'));
              return null
            }
          };

          if (!v)
            return;

          const css = parseCssValue(v);
          setStringStyle(v);

          if (!css)
            return;

          setStyle(css);
        }}/>
    }
  </>
};

export default CssPropertiesEditorGroup;
