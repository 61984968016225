// NPM
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  Text,
  TextField
} from "@fluentui/react";
import {registerSchema} from "class-validator";

// Local
import {LinkDeviceInput} from "../../graphql/types";
import {linkDevice} from "../../store/device/actions";
import {LinkDeviceValidationSchema} from "../../data/validation/linkDeviceValidationSchema";
import MutatePanel from "../../components/mutatePanel";
import {getDevicesAsyncStatus, getDevicesError} from "../../store/device/reducers";
import OrganizationsDropdown from "../../components/organizationsDropdown";

registerSchema(LinkDeviceValidationSchema);

interface AddDevicePanelProps {
  isOpen: boolean;
  onDismiss: any;
}

const LinkDevicePanel = (props: AddDevicePanelProps) => {
  const { t } = useTranslation();

  // Validation and api call status objects
  const [input, setInput] = useState<LinkDeviceInput>();

  // Form values
  const [deviceDisplayName, setDeviceDisplayName] = useState('');
  const [linkCode, setLinkCode] = useState('');
  const [organizationId, setOrganizationId] = useState('');

  // Whenever a form value changes, update our input object
  useEffect(() => {
    setInput({
      displayName: deviceDisplayName,
      linkCode: linkCode,
      organizationId: organizationId
    })
  }, [deviceDisplayName, linkCode, organizationId]);

   return (
    <MutatePanel
      headerText={t('devices:linkdevicepanel.headertext')}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      input={input}
      createAction={linkDevice}
      validationSchema={LinkDeviceValidationSchema}
      errorSelector={getDevicesError}
      statusSelector={getDevicesAsyncStatus}
     >
      <Text>
        {t('dialogs:linkdevice.description')}
      </Text>

      <TextField
        required
        label={t('devices:linkdevicepanel.linkcode')}
        placeholder={t('devices:linkdevicepanel.linkcodeplaceholder')}
        value={linkCode}
        onChange={(e,v) => setLinkCode(v || '')}
        autoFocus={true} />

      <TextField
        required
        label={t('devices:linkdevicepanel.displaynamelabel')}
        placeholder={t('devices:linkdevicepanel.displaynameplaceholder')}
        value={deviceDisplayName}
        onChange={(e,v) => setDeviceDisplayName(v || '')}
      />

      <OrganizationsDropdown required onChange={(o) => setOrganizationId(o.id!)} />
    </MutatePanel>
  )
};

export default LinkDevicePanel;
