// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useTranslation} from "react-i18next";
import {TextField} from "@fluentui/react";
import { ITextLabelConfiguration } from "pixon-component-core";

/* Local */
import {IComponentPropertyEditorProps, useComponentConfiguration} from "./propertyEditor";
import DefaultComponentEditorGroup from "./defaultComponentEditorGroup";

// ----------------------------------------------------------------------------

const TextLabelComponentEditorGroup = (props: IComponentPropertyEditorProps) => {
  const {pageComponent, onChange} = props;
  const component = pageComponent.component!;
  const {t} = useTranslation();
  const [configuration, updateConfigurationProperty] = useComponentConfiguration<ITextLabelConfiguration>(onChange, component);

  return <>

    <DefaultComponentEditorGroup
      showDataSourceEditor={false}
      showCssEditor={true}
      heading={t('component:textLabelComponentEditorGroup.heading')}
      {...props}>

      <TextField
        label={t('component:textLabelComponentEditorGroup.labelLabel')}
        placeholder={t('component:textLabelComponentEditorGroup.labelPlaceholder')}
        value={configuration ? configuration.label : ''}
        onChange={(e, v) => updateConfigurationProperty( {
          label: v
        })}
      />

    </DefaultComponentEditorGroup>

  </>
};

export default TextLabelComponentEditorGroup;
