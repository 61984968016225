// NPM
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// Local
import { organizationReducer } from "./organization/reducers";
import {uiReducer} from "./ui/reducers";
import {deviceReducer} from "./device/reducers";
import {addressesReducer} from "./address/reducers";
import {
  ApolloClient,
  InMemoryCache
} from '@apollo/client'

const rootReducer = combineReducers({
  organizations: organizationReducer,
  devices: deviceReducer,
  addresses: addressesReducer,
  ui: uiReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore(client: ApolloClient<InMemoryCache>) {
  const middlewares = [thunkMiddleware.withExtraArgument(client)];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer)
  );

  return store;
}
