import {AsyncAction, AsyncActionStatus} from "../asyncAction/types";
import {Organization} from "../../graphql/types";

// Describing the shape of the organization's slice of state
export interface OrganizationsState {
  entities: Organization[];
  asyncStatus: AsyncActionStatus;
  error?: Error
}

// Describing the different ACTION NAMES available
export const LOAD_ORGANIZATIONS = 'LOAD_ORGANIZATIONS';
export const ADD_ORGANIZATION = "ADD_ORGANIZATION";
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION";

type LoadOrganizationsAction = AsyncAction<typeof LOAD_ORGANIZATIONS, Organization[]>;
type AddOrganizationAction = AsyncAction<typeof ADD_ORGANIZATION, Organization>;
type UpdateOrganizationAction = AsyncAction<typeof UPDATE_ORGANIZATION, Organization>;
type DeleteOrganizationAction = AsyncAction<typeof DELETE_ORGANIZATION, Organization>;

export type OrganizationActionTypes =
  LoadOrganizationsAction |
  AddOrganizationAction |
  DeleteOrganizationAction |
  UpdateOrganizationAction;
