// NPM
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";

// Local
import './i18n';
import App from './app';
import { PixonApiProvider } from "./components/api/api";
import { setUiAuthConfig, setUiConfig, setUiSession } from "./store/ui/actions";
import * as serviceWorker from './serviceWorker';
import configureStore from "./store";
import './assets/css/index.css';
import './assets/css/geosuggest.css';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  concat
} from '@apollo/client'
import { ai } from './telemetryService';
import { createBrowserHistory as createHistory } from 'history'
import { StripeHookProvider } from "./components/StripeHookProvider";
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';

const history = createHistory({});
ai.initialize({ history: history });

const httpLink = new HttpLink({
  uri: window.API_BASE_URL + "graphql"
});

const authMiddleware = new ApolloLink((operation, forward) => {

  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${readToken()}` || null
    }
  }));

  return forward(operation);
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache' as 'no-cache',
  },
  query: {
    fetchPolicy: 'no-cache' as 'no-cache',
  },
};

const client = new ApolloClient<InMemoryCache>({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache({  addTypename: false}) as any,
  defaultOptions: defaultOptions
});

// Store base configuration in redux state
const store = configureStore(client);

const readToken = () =>  store.getState().ui.global.auth.session.accessToken;

store.dispatch(setUiConfig(
  window.API_BASE_URL,
  window.APPLICATION_INSIGHTS_KEY));

store.dispatch(setUiAuthConfig({
  clientId: window.KEYCLOAK_CLIENT_ID,
  realm: window.KEYCLOAK_REALM,
  baseUrl: window.KEYCLOAK_BASE_URL
}));

const keycloak = Keycloak({
  url: window.KEYCLOAK_BASE_URL,
  realm: window.KEYCLOAK_REALM,
  clientId: window.KEYCLOAK_CLIENT_ID,
});

const eventLogger = (event: unknown, error: unknown) => {
  console.log('onKeycloakEvent', event, error)
}

const decodeJwtToken = (jwtToken: string) => {
  let jwtData = jwtToken.split('.')[1];
  let decodedJwtJsonData = window.atob(jwtData);
  return JSON.parse(decodedJwtJsonData);
};

const tokenLogger = (tokens: unknown) => {
  const at = (tokens as any)?.token as string;

  if (at) {
    // after a successful login, refresh user data from identity provider to pixon db
    fetch(window.API_BASE_URL + 'user/sync', {
      headers: {
        Authorization: `Bearer ${at}`
      }
    }).catch(e => console.error('Synchronize user error', e));

    const decodedToken = decodeJwtToken(at);

    store.dispatch(setUiSession({
      accessToken: at,
      permissions: [],
      scope: decodedToken.scope,
      userId: decodedToken.sub
    }));
  }
}

// isLoadingCheck from keycloak, see https://github.com/react-keycloak/react-keycloak/blob/master/packages/core/src/provider.tsx

ReactDOM.render(
  <Provider store={store}>
    <StripeHookProvider apiKey={window.STRIPE_PK_API_KEY}>


      <ReactKeycloakProvider
        authClient={keycloak}
        onEvent={eventLogger}
        onTokens={tokenLogger}
        autoRefreshToken={true}
      >
        <PixonApiProvider baseUrl={window.API_BASE_URL} client={client}>
          <App />
        </PixonApiProvider>

      </ReactKeycloakProvider>
    </StripeHookProvider>

  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
