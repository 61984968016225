// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useState} from "react";
import {
  Selection,
  SelectionMode,
  ContextualMenu,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IContextualMenuProps,
  Text,
} from "@fluentui/react";

import {
  Device,
  Organization,
} from "../../graphql/types";
import {useTranslation} from "react-i18next";
import SubHeading from "../../components/layout/subHeading";
import {addressString} from "../../components/api/util";
import {RouteComponentProps} from "react-router";
import {withRouter} from "react-router-dom";

/* Local */

// ----------------------------------------------------------------------------

interface IPageComponentsProps extends RouteComponentProps {
  organization: Organization,
}

const OrganizationDeviceList = (props: IPageComponentsProps) => {
  const {organization} = props;
  const [contextMenu, setContextMenu] = useState<IContextualMenuProps>();
  const {t} = useTranslation();
  const [selection] = useState<Selection>(new Selection());
  const devices = organization.devices ? organization.devices : [] as Device[];

  const _onItemContextMenu = (item: any, index?: number, ev?: Event): boolean => {
    const mouseEvent = ev! as MouseEvent;
    const selected = item as Device;

    const contextualMenuProps: IContextualMenuProps = {
      target: {x: mouseEvent.x, y: mouseEvent.y}, //ev!.target as HTMLElement,
      items: [
        {
          key: 'viewDetails',
          text: t('common:menu.viewDetails'),
          iconProps: {
            iconName: 'EntryView'
          },
          onClick: () => {
            props.history.push('/device/' + selected.id)
          }
        }
      ]
      ,
      onDismiss: () => {
        setContextMenu(undefined);
      }
    };

    if (index! > -1) {
      setContextMenu(contextualMenuProps);
    }

    return false;
  };

  const defaultColumns: IColumn[] = [
    {
      key: 'displayName',
      name: t('devices:fields.displayName'),
      fieldName: 'displayName',
      minWidth: 210,
      maxWidth: 350,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      isPadded: true
    },
    {
      key: 'model',
      name: t('devices:fields.model'),
      fieldName: 'model',
      minWidth: 210,
      maxWidth: 350,
      isRowHeader: false,
      isResizable: true,
      data: 'string',
      isPadded: true
    },
    {
      key: 'address',
      name: t('devices:fields.address'),
      fieldName: 'address',
      minWidth: 210,
      maxWidth: 350,
      isRowHeader: false,
      isResizable: true,
      data: 'string',
      isPadded: true,
      onRender: item => addressString(item.address)
    },
    {
      key: 'placement',
      name: t('devices:fields.placement'),
      fieldName: 'placement',
      minWidth: 210,
      maxWidth: 350,
      isRowHeader: false,
      isResizable: true,
      data: 'string',
      isPadded: true
    }
  ];

  return (
    <>
      <SubHeading heading={t('organizations:organizationsDeviceList.heading')}/>
      <Text>
        {t('organizations:organizationsDeviceList.description')}
      </Text>

      <DetailsList
        items={devices}
        compact={false}
        columns={defaultColumns}
        selectionMode={SelectionMode.single}
        selection={selection}
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
        selectionPreservedOnEmptyClick={true}
        enterModalSelectionOnTouch={true}
        onItemContextMenu={_onItemContextMenu}
      />
      {contextMenu &&
      <ContextualMenu {...contextMenu} />
      }
    </>
  )
};

export default withRouter(OrganizationDeviceList);
