import * as React from 'react';

import {
  CommandBar,
  ICommandBarItemProps
} from "@fluentui/react";

import {useTranslation} from "react-i18next";
import {EditorType} from "./editTextAssetPanel";
import {Asset} from "../../graphql/types";
import {isEditableUsingTableEditor, isEditableUsingTextEditor} from "../../components/api/util";

export interface IAssetsCommandBarProps {
  onAddNewAsset: () => void
  onEditAsset: () => void
  onEditAssetContent: (editorType: EditorType) => void
  onRemoveAsset: () => void,
  selectedAsset?: Asset,
}

const AssetsCommandBar = (props: IAssetsCommandBarProps) => {
  const {selectedAsset, onAddNewAsset, onEditAssetContent, onEditAsset, onRemoveAsset} = props;
  const {t} = useTranslation();

    const _getItems = (): ICommandBarItemProps[] => {
    return [
      {
        key: 'newAsset',
        name: t('assets:commandbar.newAsset'),
        iconProps: {
          iconName: 'Add'
        },
        onClick: () => onAddNewAsset()
      },
      {
        key: 'editSelected',
        name: t('common:menu.editSelected'),
        iconProps: {
          iconName: 'Edit'
        },
        onClick: () => onEditAsset(),
      },
      {
        key: 'editContentSelected',
        name: t('common:menu.editContentSelected'),
        iconProps: {
          iconName: 'PageEdit'
        },
        subMenuProps: {
          items: [
            {
              key: 'editWithTextEditor',
              text: t('common:menu.editWithTextEditor'),
              disabled: !isEditableUsingTextEditor(selectedAsset),
              iconProps: {
                iconName: 'EditNote',
              },
              onClick: () =>  onEditAssetContent(EditorType.Text),
            },
            {
              key: 'editWithTableEditor',
              text: t('common:menu.editWithTableEditor'),
              disabled: !isEditableUsingTableEditor(selectedAsset),
              iconProps: {
                iconName: 'Table',
              },
              onClick: () =>  onEditAssetContent(EditorType.Table),
            }
          ]
        },
      },
      {
        key: 'delete',
        iconProps: {
          iconName: 'Delete',
          style: {
            color: 'salmon'
          }
        },
        onClick: onRemoveAsset,
        name: t('common:menu.removeSelected'),

      }
    ];
  };

  return <CommandBar items={_getItems()}/>;
};

export default AssetsCommandBar;
