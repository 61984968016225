import * as React from 'react';

import {
  ComboBox,
  IComboBoxOption, SelectableOptionMenuItemType,
} from "@fluentui/react";
import {useTranslation} from "react-i18next";

export interface IFontSizeComboboxProps {
  label?: string,
  defaultSize?: string | number,
  onChange: (fontSize: string) => void;
}

// From type AbsoluteSize
const absoluteSizeValues = [
  "large" , "medium" , "small" , "x-large" , "x-small" , "xx-large" , "xx-small"
];

const percentageSizeValues = [
  "20%" , "40%", "60%" , "80%" , "100%" ,
  "120%" , "140%", "160%", "180%",  "200%"
];

const relativeSizeValues = [
  "0.5em" , "1em" ,"2em" ,"3em" ,"4em" ,"5em"
];

const FontSizeCombobox = (props: IFontSizeComboboxProps) => {
  const {
    label,
    defaultSize = 'medium',
    onChange
  } = props;
  const {t} = useTranslation();

  const options = [
    // Default font options are listed under this heading
    { key: 'header1', text: t('common:fontSizeCombobox.absoluteSizeHeader'), itemType: SelectableOptionMenuItemType.Header },
    ...absoluteSizeValues.map((sizeValue: string) => ({ key: sizeValue, text: sizeValue })),
    { key: 'header2', text: t('common:fontSizeCombobox.relativeSizeHeader'), itemType: SelectableOptionMenuItemType.Header },
    ...relativeSizeValues.map((sizeValue: string) => ({ key: sizeValue, text: sizeValue })),
    { key: 'header3', text: t('common:fontSizeCombobox.percentageSizeHeader'), itemType: SelectableOptionMenuItemType.Header },
    ...percentageSizeValues.map((sizeValue: string) => ({ key: sizeValue, text: sizeValue })),
  ];

  const _onRenderOption = (item?: IComboBoxOption) => {
    if (!item)
      return null;

    if (item.itemType === SelectableOptionMenuItemType.Header || item.itemType === SelectableOptionMenuItemType.Divider) {
      return <span>{item.text}</span>;
    }

    return <span>{item.text}</span>;
  };

  return <ComboBox
    selectedKey={defaultSize}
    label={label}
    allowFreeform={false}
    autoComplete="off"
    options={options}
    onRenderOption={_onRenderOption}
    onChange={(event, option) => {
      onChange(option?.key?.toString() || '')
    }}
  />;
};

export default FontSizeCombobox;
