import * as React from 'react';

import {ReactNode} from "react";
import {Depths} from "@uifabric/fluent-theme";
import PageHeading from "./layout/pageHeading";
import SubHeading from "./layout/subHeading";

export interface ITextPaneProps {
  heading?: string
  subheading?: string
  children?: ReactNode
}

const TextPane = (props: ITextPaneProps) => {
  const {heading, subheading, children} = props;

  return <div style={
    {
      boxShadow: Depths.depth8,
      padding: 10
    }
  } className={'textPane'}>

    {heading &&
      <PageHeading heading={heading}/>
    }
    {subheading &&
      <SubHeading heading={subheading}/>
    }
    {children}
  </div>

};

export default TextPane;
