// NPM
import * as React from "react";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
  ChoiceGroup, DefaultButton,
  Image,
  Label,
  MaskedTextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import {Organization, useAddOrganizationPaymentMethodMutation,} from "../../graphql/types";
import {CardElement} from "react-stripe-elements";
import {useStripe} from "../../components/StripeHookProvider";
import SubHeading from "../../components/layout/subHeading";
import AddressField from "../../components/addressField";

// Local
interface ManagePaymentMethodsFormProps {
  organization: Organization;
  onRefetch: () => void;
}

const AddPaymentMethodForm = (props: ManagePaymentMethodsFormProps) => {
  const {t} = useTranslation();
  const {organization, onRefetch} = props;
  const {stripe} = useStripe();

  const [name, setName] = useState('');
  const [isValidCard, setIsValidCard] = useState<boolean>(false);
  const [provider, setProvider] = useState('stripe');
  const [addOrganizationPaymentMethod] = useAddOrganizationPaymentMethodMutation();
  const [messageType, setMessageType] = useState<MessageBarType | undefined>(undefined);
  const [message, setMessage] = useState('');

  const isValidPaymentMethod = () => {
    return name !== '' && isValidCard;
  };

  const handleAddPaymentMethod = (ev: any) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    const elements = stripe.elements();

    // Use Elements to get a reference to the Card Element mounted somewhere
    // in your <Elements> tree. Elements will know how to find your Card Element
    // becase only one is allowed.
    // See our getElement documentation for more:
    // https://stripe.com/docs/stripe-js/reference#elements-get-element
    const cardElement = elements.getElement('card') as stripe.elements.Element;
    // From here we cal call createPaymentMethod to create a PaymentMethod
    // See our createPaymentMethod documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
    stripe
      .createPaymentMethod(
        'card',
        cardElement)
      .then(({paymentMethod}: any) => {
        console.log('payment method', paymentMethod);
        return addOrganizationPaymentMethod({
          variables: {
            input: {
              externalId: paymentMethod.id,
              isDefault: true,
              name: name,
              organizationId: organization.id!
            }
          }
        })
      })
      .then(value => {
        displaySuccess(t('component:managePaymentMethodsForm.paymentMethodAddedMessage'));
        onRefetch();
      })
      .catch(reason => displayError(reason.toString()));
  };
  const displayError = (m: string) => {
    setMessageType(MessageBarType.error);
    setMessage(m);
  };

  const displaySuccess = (m: string) => {
    setMessageType(MessageBarType.success);
    setMessage(m);
  };

  return <>
    <SubHeading heading={t('component:managePaymentMethodsForm.addPaymentMethodHeading')}/>

    <MessageBar
      messageBarType={MessageBarType.info}>{t('component:managePaymentMethodsForm.noStorageDisclamer')}</MessageBar>

    {provider === '' &&
      <>
        <Label>
          Typ av betalningsmetod
        </Label>
        <ChoiceGroup

          selectedKey={provider}
          options={[
            {
              key: 'stripe',
              iconProps: {iconName: 'PaymentCard'},
              text: t('component:managePaymentMethodsForm.paymentMethodCard')
            },
            {
              key: 'billogram',
              iconProps: {iconName: 'Money'},
              text: t('component:managePaymentMethodsForm.paymentMethodOther'),
              disabled: false
            }
          ]}
          onChange={(ev, option) => setProvider(option?.key!)}
        />

        <Text>{t('component:managePaymentMethodsForm.paymentMethodCardDescription')}</Text>
        <Text>{t('component:managePaymentMethodsForm.paymentMethodOtherDescription')}</Text>
      </>
    }

    {provider !== '' &&
    <TextField autoFocus={true}
               required={true}
               label={t('component:addPaymentMethodForm.namelabel')}
               placeholder={t('component:addPaymentMethodForm.nameplaceholder')}
               onChange={(e, v) => setName(v || '')}
    />
    }

    {provider === 'stripe' &&
    <>
      <Label htmlFor={'card'} required={true}>
        {t('component:addPaymentMethodForm.cardDetailslabel')}
      </Label>
      <CardElement
        id={'card'}
        style={{
          base: {
            fontSize: '14px',
            fontFamily: '"Segoe UI", "Segoe UI Web (West European)"'
          }
        }}
        className={'card-input'}

        onChange={e => {
          setIsValidCard(e.complete);
          if (e.error) {
            displayError(e.error.message!);
          } else {
            displayError('');
          }
        }}
      />

      {message !== '' &&
      <MessageBar messageBarType={messageType}>
        {message}
      </MessageBar>
      }

      <Stack horizontal tokens={{childrenGap: 10}} horizontalAlign="end">
        <DefaultButton onClick={() => setProvider('')}>
          {t('common:button.cancel')}
        </DefaultButton>
        <PrimaryButton onClick={handleAddPaymentMethod} disabled={!isValidPaymentMethod()}>
          {t('common:button.add')}
        </PrimaryButton>
      </Stack>

      <SubHeading heading={t('component:managePaymentMethodsForm.paymentProvider')}/>
      <Image src="https://content.pixon.app/public/logos/stripe-logo-slate-small.png" alt="Stripe logo"
             width={180}/>
    </>
    }

    {provider === 'billogram' &&
    <>
      <MaskedTextField
        label={t('organizations:fields.vatlabel')}
        placeholder={t('organizations:fields.vatplaceholder')}
        mask="aa 999999999999"
      />
      <MaskedTextField label={t('organizations:fields.orgnumberlabel')}
                       placeholder={t('organizations:fields.orgnumberplaceholder')}
                       mask="999999-9999"
      />

      <TextField required
                 label={t('organizations:fields.billingemaillabel')}
                 placeholder={t('organizations:fields.billingemailplaceholder')}
      />
      <AddressField required
                    label={t('organizations:fields.billingaddresslabel')}
      />

      {message !== '' &&
      <MessageBar messageBarType={messageType}>
        {message}
      </MessageBar>
      }

      <Stack horizontal tokens={{childrenGap: 10}} horizontalAlign="end">
        <DefaultButton onClick={() => setProvider('')}>
          {t('common:button.cancel')}
        </DefaultButton>

        <PrimaryButton>
          {t('common:button.save')}
        </PrimaryButton>
      </Stack>

      <SubHeading heading={t('component:managePaymentMethodsForm.paymentProvider')}/>

      <Image src="https://content.pixon.app/public/logos/billogram-logo-purple.png" alt="Billogram logo"
             width={180}/>

    </>
    }
  </>
};

export default AddPaymentMethodForm;
