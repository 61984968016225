import {
  UiState,
  UiActionTypes,
  SET_UI_LANGUAGE,
  SET_UI_AUTH_CONFIG,
  SET_UI_CONFIG,
  SET_UI_AUTH_SESSION, SET_UI_GRAPHQL_CLIENT
} from "./types";
import {AppState} from "../index";

const initialState: UiState = {
  global: {
    language: 'sv',
    apiBaseUrl: 'https://pixon.app.localhost:3000/',
    applicationInsightsKey: '',
    graphQlClient: undefined,
    auth: {
      config: {
        baseUrl: '',
        clientId: '',
        realm: ''
      },
      session: {
        accessToken: '',
        permissions: [],
        scope: '',
        userId: ''
      }
    }
  }
};

export function uiReducer(
  state = initialState,
  action: UiActionTypes
): UiState {
  switch (action.type) {
    case SET_UI_CONFIG:
      return {
        ...state,
        global: {
          ...state.global,
          applicationInsightsKey: action.payload.applicationInsightsKey,
          apiBaseUrl: action.payload.apiBaseUrl,
        }
      };
    case SET_UI_GRAPHQL_CLIENT:
      return {
        ...state,
        global: {
          ...state.global,
          graphQlClient: action.payload
        }
      };
    case SET_UI_AUTH_SESSION:
      return {
        ...state,
        global: {
          ...state.global,
          auth: {
            ...state.global.auth,
            session: action.payload
          }
        }
      };
    case SET_UI_AUTH_CONFIG:
      return {
        ...state,
        global: {
          ...state.global,
          auth: {
            ...state.global.auth,
            config: action.payload
          }
        }
      };
    case SET_UI_LANGUAGE:
      return {
        ...state,
        global: {
          ...state.global,
          language: action.payload
        }
      };
    default:
      return state;
  }
}

// Selectors
export const getGraphQlClient = (state: AppState) => state.ui.global.graphQlClient;
export const getUiLanguage = (state: AppState) => state.ui.global.language;
export const getAccessToken = (state: AppState) => state.ui.global.auth.session.accessToken;
export const getPermissions = (state: AppState) => state.ui.global.auth.session.permissions;
export const getApiBaseUrl = (state: AppState) => state.ui.global.apiBaseUrl;

