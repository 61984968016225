// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useTranslation} from "react-i18next";

/* Local */
import {IComponentPropertyEditorProps, useComponentConfiguration} from "./propertyEditor";
import DefaultComponentEditorGroup from "./defaultComponentEditorGroup";
import { DataSourceTypeEnum} from "../../../graphql/types";
import {Link, Slider, TextField} from "@fluentui/react";

// ----------------------------------------------------------------------------

interface ICalendarComponentConfiguration {
  dateFormatString: string
  numberOfItemsToShow: number
}

const CalendarComponentEditorGroup = (props: IComponentPropertyEditorProps) => {
  const {pageComponent, onChange} = props;
  const component = pageComponent.component!;
  const {t} = useTranslation();
  const [configuration, updateConfigurationProperty] = useComponentConfiguration<ICalendarComponentConfiguration>(onChange, component);

  return <>

    <DefaultComponentEditorGroup
      showDataSourceEditor={true}
      dataSourceTypes={[DataSourceTypeEnum.CalendarIcal]}
      showCssEditor={true}
      showTemplateAssetEditor={true}
      heading={t('component:calendarComponentEditorGroup.heading')}
      {...props}>

      <Slider
        label={t('component:calendarComponentEditorGroup.numberOfItemsToShowLabel')}
        min={1}
        max={100}
        step={1}
        defaultValue={configuration ? configuration.numberOfItemsToShow : 10}
        showValue={true}
        valueFormat={(value: number) => `${value}`}
        onChange={(value: number) => updateConfigurationProperty({numberOfItemsToShow: value})}
      />

      <TextField
        label={t('component:calendarComponentEditorGroup.dateFormatStringLabel')}
        placeholder={t('component:calendarComponentEditorGroup.dateFormatStringPlaceholder')}
        value={configuration ? configuration.dateFormatString : 'MMM D HH:mm'}
        onChange={(e, v) => updateConfigurationProperty({
          dateFormatString: v
        })}
      />
      <Link href='https://momentjs.com/docs/#/displaying/format/' target='_blank'>
        {t('component:clockComponentEditorGroup.formatStringReadMore')} &#x3E;&#x3E;
      </Link>

    </DefaultComponentEditorGroup>

  </>
};

export default CalendarComponentEditorGroup;
