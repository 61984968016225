import * as React from 'react';
import {Label} from '@fluentui/react';
import Geosuggest from "react-geosuggest";
import {Address} from "../graphql/types";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {addressString} from "./api/util";

export interface IAddressFieldProps {
  id?: string,
  label?: string,
  placeholder?: string,
  required?: boolean,
  initialValue?: Address,
  initialValueString?: string,
  onAddressSelected?: (selected:Address) => void;
}

const AddressField = (props: IAddressFieldProps) => {
  const {t} = useTranslation();
  const {onAddressSelected, initialValueString} = props;
  const [selectedAddress, setSelectedAddress] = useState<Address>(() => props.initialValue ? props.initialValue : {} as Address);

  // On user select a suggestion, create an address object of the selection
  const onSuggestSelect = (suggest: any) => {
    if (suggest && onAddressSelected)
    {
      const getAddressComponent = (componentType:string, name: string = 'long_name'): string => {
        const e = suggest.gmaps.address_components.find((el: any) => el.types.includes(componentType));
        return e && e.hasOwnProperty(name) ? e[name] as string : '';
      };

      const addressObj: Address = {
        addressLine1: getAddressComponent('route'),
        postalcode: getAddressComponent('postal_code'),
        streetNumber: getAddressComponent('street_number'),
        city: {
          name: getAddressComponent('postal_town'),
          subdivision: {
            name: getAddressComponent('administrative_area_level_1'),
            country: {
              name: getAddressComponent('country'),
              iso: getAddressComponent('country', 'short_name')
            }
          }
        },
        location: {
          latitude: suggest.location.lat,
          longitude: suggest.location.lng,
        },
        jsonData: JSON.stringify(suggest)
      };

      setSelectedAddress(addressObj);
      onAddressSelected(addressObj);
    }
  };

  const label = props.label ? props.label : t('common:address.label');
  const placeholder = props.placeholder ? props.placeholder : t('common:address.placeholder');

  const selectedAddressString = addressString(selectedAddress);
  const initialValue =
    selectedAddressString
      ? selectedAddressString
      : initialValueString
        ? initialValueString
        : undefined;

  return <div className='addressfield__wrapper'>
    <Label required={props.required} htmlFor={props.id}>{label}</Label>

    <Geosuggest
      id={props.id}
      placeholder={placeholder}
      initialValue={initialValue}
      types={['geocode']}
      onSuggestSelect={(suggest) => onSuggestSelect(suggest)}
       />
  </div>
};

AddressField.defaultProps = {
  id: 'address-geosuggest',
  required: false
};

export default AddressField;
