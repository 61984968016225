// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {
  IStackTokens,
  Stack,
  Text,
} from "@fluentui/react";
import {Device} from "../../graphql/types";
import {useTranslation} from "react-i18next";
import { useKeycloak } from '@react-keycloak/web';
import SubHeading from "../../components/layout/subHeading";

/* Local */

// ----------------------------------------------------------------------------

interface IDevicePropertiesProps {
  device: Device
}

interface IPropertyProps {
  localizationKey: string,
  property?: any
}

const themedSmallStackTokens: IStackTokens = {
  childrenGap: 's1',
  padding: 's1'
};

const DeviceProperties = (props: IDevicePropertiesProps) => {
  const {device} = props;
  const {t} = useTranslation();
  const { keycloak } = useKeycloak();

  const Property = ({localizationKey, property}: IPropertyProps) => {
    return <>
      {property &&
      <Text><strong>{t(localizationKey)}</strong>: {property.toString()}</Text>
      }
    </>
  };

  return <Stack tokens={themedSmallStackTokens} verticalAlign='start'>
        <SubHeading heading={t('devices:deviceProperties.header')}/>

        {device.organization &&
        <Property localizationKey='devices:property.organizationName' property={device.organization.name}/>
        }

        {device.address &&
        <Property localizationKey='devices:property.addressLine1' property={device.address.addressLine1}/>
        }
        <Property localizationKey='devices:property.placement' property={device.placement}/>

        <Property localizationKey='devices:property.model' property={device.model}/>
        {keycloak.hasRealmRole('developer') &&
        <>
            <Property localizationKey='devices:property.id' property={device.id}/>
            <Property localizationKey='devices:property.ioTDeviceId' property={device.ioTDeviceId}/>
        </>
        }
      </Stack>
};

export default DeviceProperties;
