// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {CommandBar, ICommandBarItemProps, Slider, Stack,} from "@fluentui/react";

/* Local */

// ----------------------------------------------------------------------------

interface IPageEditorCommandBarProps {
  onChangeViewScale: (scale: number) => void,
  defaultViewScale: number
}

const layerItems: ICommandBarItemProps[] = [
  {
    key: '1',
    ariaLabel: 'Bring to front',
    disabled: true,
    iconProps: {
      iconName: 'ArrangeBringToFront'
    }
  },
  {
    key: '2',
    ariaLabel: 'Send to back',
    disabled: true,
    iconProps: {
      iconName: 'ArrangeSendToBack'
    }
  },
  {
    key: '3',
    ariaLabel: 'Bring forward',
    disabled: true,
    iconProps: {
      iconName: 'ArrangeBringForward'
    }
  },
  {
    key: '4',
    ariaLabel: 'Send backward',
    disabled: true,
    iconProps: {
      iconName: 'ArrangeSendBackward'
    }
  },
];

const alignHorizontalItems: ICommandBarItemProps[] = [
  {
    key: '1',
    ariaLabel: 'Align left',
    disabled: true,
    iconProps: {
      iconName: 'AlignHorizontalLeft'
    }
  },
  {
    key: '2',
    ariaLabel: 'Align center',
    disabled: true,
    iconProps: {
      iconName: 'AlignHorizontalCenter'
    }
  },
  {
    key: '3',
    ariaLabel: 'Align right',
    disabled: true,
    iconProps: {
      iconName: 'AlignHorizontalRight'
    }
  }
];

const alignVerticalItems: ICommandBarItemProps[] = [
  {
    key: '1',
    ariaLabel: 'Align top',
    disabled: true,
    iconProps: {
      iconName: 'AlignVerticalTop'
    }
  },
  {
    key: '2',
    ariaLabel: 'Align center',
    disabled: true,
    iconProps: {
      iconName: 'AlignVerticalCenter'
    }
  },
  {
    key: '3',
    ariaLabel: 'Align bottom',
    disabled: true,
    iconProps: {
      iconName: 'AlignVerticalBottom'
    }
  },
];

const PageEditorCommandBar = (props: IPageEditorCommandBarProps) => {
  const {onChangeViewScale, defaultViewScale} = props;

  return  <Stack>
    <Stack horizontal horizontalAlign={'space-evenly'}>
      <CommandBar items={layerItems}/>
      <CommandBar items={alignHorizontalItems}/>
      <CommandBar items={alignVerticalItems}/>
    </Stack>
    <Slider
      min={0.1}
      max={3}
      step={0.1}
      defaultValue={defaultViewScale}
      onChanged={(event, value) => onChangeViewScale(value)}
    />
  </Stack>
};

export default PageEditorCommandBar;
