import {DeviceActionTypes, DevicesState, LINK_DEVICE, LOAD_DEVICES, UNLINK_DEVICE, UPDATE_DEVICE,} from "./types";
import {AppState} from "../index";
import {AsyncActionStatus} from "../asyncAction/types";

const initialState: DevicesState = {
  entities: [],
  asyncStatus: AsyncActionStatus.UNSTARTED,
  error: undefined
};

// See https://dev.to/markusclaus/fetching-data-from-an-api-using-reactredux-55ao
export function deviceReducer(
  state = initialState,
  action: DeviceActionTypes
): DevicesState {
  switch (action.type) {
    case LOAD_DEVICES:
      switch (action.status) {
        case AsyncActionStatus.SUCCEEDED:
          return {
            ...state,
            asyncStatus: action.status,
            entities: action.payload
          };
        case AsyncActionStatus.FAILED:
          return {
            ...state,
            asyncStatus: action.status,
            error: action.payload
          };
        default:
          return {
            ...state,
            asyncStatus: action.status
          };
      }
    case LINK_DEVICE:
      switch (action.status) {
        case AsyncActionStatus.SUCCEEDED:
          return {
            ...state,
            asyncStatus: action.status,
            entities: [...state.entities, action.payload]
          };
        case AsyncActionStatus.FAILED:
          return {
            ...state,
            asyncStatus: action.status,
            error: action.payload
          };
        default:
          return {
            ...state,
            asyncStatus: action.status
          };
      }
    case UPDATE_DEVICE:
      switch (action.status) {
        case AsyncActionStatus.SUCCEEDED:
          return {
            ...state,
            asyncStatus: action.status,
            entities: [...state.entities.filter(o => o.id !== action.payload.id), action.payload]
          };
        case AsyncActionStatus.FAILED:
          return {
            ...state,
            asyncStatus: action.status,
            error: action.payload
          };
        default:
          return {
            ...state,
            asyncStatus: action.status
          };
      }
    case UNLINK_DEVICE:
      switch (action.status) {

        case AsyncActionStatus.SUCCEEDED:
          return {
            ...state,
            asyncStatus: action.status,
            entities: state.entities.filter(o => o.id !== action.payload.id)
          };
        case AsyncActionStatus.FAILED:
          return {
            ...state,
            asyncStatus: action.status,
            error: action.payload
          };
        default:
          return {
            ...state,
            asyncStatus: action.status
          };
      }
    default:
      return state;
  }
}

// Selectors
export const getDevices = (state:AppState) => state.devices.entities;
export const getDevicesAsyncStatus = (state:AppState) => state.devices.asyncStatus;
export const getDevicesError = (state:AppState) => state.devices.error;
