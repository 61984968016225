// NPM
import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  TextField
} from "@fluentui/react";
import {registerSchema} from "class-validator";

// Local
import {OrganizationInput} from "../../graphql/types";
import {addOrganization} from "../../store/organization/actions";
import {AddOrganizationValidationSchema} from "../../data/validation/addOrganizationSchema";
import MutatePanel from "../../components/mutatePanel";
import {getOrganizationsAsyncStatus, getOrganizationsError} from "../../store/organization/reducers";

registerSchema(AddOrganizationValidationSchema);

interface AddOrganizationPanelProps {
  isOpen: boolean;
  onDismiss: any;
}

const AddOrganizationPanel = (props: AddOrganizationPanelProps) => {
  const { t } = useTranslation();

  // Validation and api call status objects
  const [input, setInput] = useState<OrganizationInput>();

  // Form values
  const [organizationName, setOrganizationName] = useState('');

  // Whenever a form value changes, update our input object
  useEffect(() => {
    setInput({
      name: organizationName
    })
  }, [organizationName]);

  return (
    <MutatePanel
      headerText={t('organizations:addorganizationpanel.headertext')}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      input={input}
      createAction={addOrganization}
      validationSchema={AddOrganizationValidationSchema}
      errorSelector={getOrganizationsError}
      statusSelector={getOrganizationsAsyncStatus}
     >
      <TextField
        required
        autoFocus={true}
        label={t('organizations:addorganizationpanel.namelabel')}
        placeholder={t('organizations:addorganizationpanel.nameplaceholder')}
        value={organizationName}
        onChange={(e) => setOrganizationName((e.target as HTMLInputElement).value)}
      />
    </MutatePanel>
  )
};

export default AddOrganizationPanel;
