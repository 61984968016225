// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import React, {
  useEffect,
  useState
} from "react";
import {
  DetailsList,
  DetailsListLayoutMode,
  Link,
  SelectionMode,
  Stack
} from "@fluentui/react";

/* Local */
import {usePixonApi} from "../../components/api/api";
import SubHeading from "../../components/layout/subHeading";
import ClipBoardButton from "../../components/layout/clipBoardButton";
import Permission from "../../components/auth/permission";
import {useApplicationInformationQuery} from "../../graphql/types";
import DebugEditorView from "./debugEditorView";
import { useTranslation  } from 'react-i18next';
// ----------------------------------------------------------------------------

const items = [
  {
    key: 'apiBaseUrl',
    name: 'API_BASE_URL',
    value: window.API_BASE_URL
  },
  {
    key: 'appInsightsKey',
    name: 'APPLICATION_INSIGHTS_KEY',
    value: window.APPLICATION_INSIGHTS_KEY
  },
  {
    key: 'keyCloakBaseUrl',
    name: 'KEYCLOAK_BASE_URL',
    value: window.KEYCLOAK_BASE_URL
  },
  {
    key: 'keyCloakClientId',
    name: 'KEYCLOAK_CLIENT_ID',
    value: window.KEYCLOAK_CLIENT_ID
  },
  {
    key: 'keyCloakRealm',
    name: 'KEYCLOAK_REALM',
    value: window.KEYCLOAK_REALM
  },
];

const columns = [
  {key: 'column1', name: 'Name', fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true},
  {key: 'column2', name: 'Value', fieldName: 'value', minWidth: 100, maxWidth: 400, isResizable: true}
];

const tokens = {
  themedMedium: {
    childrenGap: 'm'
  }
};

const ApplicationInformation = () => {
  const {get} = usePixonApi();
  const { t } = useTranslation();
  const [apiConfig, setConfig] = useState<string>((t('debug:appinfo.confignotretrieved')));
  const {data} = useApplicationInformationQuery();
  const applicationInfo = data && data.application;

  const getApiConfiguration = async (): Promise<string> => {
    try {
      return (await get('system/config'))!.json();
    } catch (error) {
      console.error(error);
      return t('debug:appinfo.errorfetchingconfig');
    }
  };

  useEffect(() => {
    const init = async () => {
      const config = await getApiConfiguration();
      setConfig(config);
    };
    init();
    // eslint-disable-next-line
  }, []);

  return (
    <Stack tokens={tokens.themedMedium}>

      <Stack.Item>
        <div style={{display: 'flex', alignItems: 'stretch', height: '40px'}}>
          <SubHeading heading={t('debug:appinfo.clientapplicationconfig.headline') }/>
          <ClipBoardButton value={JSON.stringify(items, null, 2)}/>
        </div>
        <p>
          {t('debug:appinfo.clientapplicationconfig.description')}
        </p>
        <DetailsList
          items={items}
          columns={columns}
          setKey="set"
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.fixedColumns}
        />
      </Stack.Item>

      <DebugEditorView heading={t('debug:appinfo.apiapplicationconfig.headline') } value={apiConfig}>
        <p>
          {t('debug:appinfo.apiapplicationconfig.description') } <Link
          href="https://manage.auth0.com/dashboard/eu/rduce/roles">{t('debug:appinfo.apiapplicationconfig.permission') }</Link>&nbsp;
          <strong>{Permission.ReadApiConfiguration}</strong>
        </p>
      </DebugEditorView>

      <DebugEditorView heading={t('debug:appinfo.apiapplicationassemblyinformation.headline') } value={applicationInfo}>
        <p>
          {t('debug:appinfo.apiapplicationassemblyinformation.description') }
        </p>
      </DebugEditorView>

    </Stack>
  )
};

export default ApplicationInformation;
