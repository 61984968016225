// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useState,useEffect} from "react";
import {useTranslation} from 'react-i18next';
import {
  ContextualMenu,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IContextualMenuProps,
  mergeStyleSets, MessageBar, MessageBarType,
  Selection,
  SelectionMode
} from "@fluentui/react";
/* Local */
import {Asset, AssetQueryInput, useAssetsQuery} from "../../graphql/types";
import {fileExt, humanFileSize, isEditableUsingTableEditor, isEditableUsingTextEditor} from "../../components/api/util";
import {EditorType} from "./editTextAssetPanel";
import Loading from "../../components/layout/loading";

// ----------------------------------------------------------------------------

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px'
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden'
      }
    }
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px'
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px'
  },
  selectionDetails: {
    marginBottom: '20px'
  }
});


export interface IAssetListProps {
  filter?: AssetQueryInput,
  onEditAsset: (asset: Asset) => void
  onEditAssetContent: (asset: Asset, editorType: EditorType) => void
  selection?: Selection,
  onRemoveAsset: () => void,
  lastUpdated: Date,
}

const AssetList = (props: IAssetListProps) => {
  const {onEditAsset, onEditAssetContent, onRemoveAsset, filter, selection} = props;
  const {t} = useTranslation();
  const [contextMenu, setContextMenu] = useState<IContextualMenuProps>();

  // Queries
  const {loading, data, error, refetch} = useAssetsQuery({
    variables: {
      input: filter
    }
  });

  // Refresh on changes
  useEffect(() => {
    refetch();
  }, [filter, props.lastUpdated, refetch]);

  // Data fetching
  if (loading) {
    return <Loading/>
  }

  if (error) {
    return <MessageBar messageBarType={MessageBarType.error}>{t('assets:assetList.loadErrorMessage')}</MessageBar>
  }

  const assets = data?.assets as Asset[];

  const defaultColumns: IColumn[] = [
    {
      key: 'fileType',
      name: t('assets:fields.fileType'),
      className: classNames.fileIconCell,
      iconClassName: classNames.fileIconHeaderIcon,
      iconName: 'Page',
      isIconOnly: true,
      fieldName: 'extension',
      minWidth: 16,
      maxWidth: 16,
      onRender: (item: Asset) => {
        const ext = fileExt(item.filename);
        const cn = `fiv-viv fiv-icon-${ext} fiv-size-md`;
        return <span className={cn}></span>;
      }
    },
    {
      key: 'displayName',
      name: t('assets:fields.displayName'),
      fieldName: 'displayName',
      minWidth: 100,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      isPadded: true
    },
    {
      key: 'filename',
      name: t('assets:fields.filename'),
      fieldName: 'filename',
      minWidth: 210,
      maxWidth: 350,
      isRowHeader: false,
      isResizable: true,
      data: 'string',
      isPadded: true
    },
    {
      key: 'owner',
      name: t('assets:fields.owner'),
      fieldName: 'owner',
      minWidth: 210,
      maxWidth: 350,
      isResizable: true,
      data: 'string',
      onRender: (item: Asset) => {
        return <span>{item.organization!.name}</span>;
      },
      isPadded: true
    },
    {
      key: 'assetType',
      name: t('assets:fields.assetType'),
      fieldName: 'assetType',
      minWidth: 70,
      maxWidth: 90,
      isRowHeader: false,
      isResizable: true,
      data: 'string',
      isPadded: true
    },

    {
      key: 'mimeType',
      name: t('assets:fields.mimeType'),
      fieldName: 'mimeType',
      minWidth: 120,
      maxWidth: 210,
      isRowHeader: false,
      isResizable: true,
      data: 'string',
      isPadded: true
    },
    {
      key: 'fileSize',
      name: t('assets:fields.fileSize'),
      fieldName: 'size',
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
      isCollapsible: true,
      data: 'number',
      onRender: (item: Asset) => {
        return <span>{humanFileSize(item.size)}</span>;
      }
    }
  ];

  const  _onItemContextMenu = (item: any, index?: number, ev?: Event): boolean => {
    const mouseEvent = ev! as MouseEvent;
    const asset = item as Asset;

    const contextualMenuProps: IContextualMenuProps = {
      target: { x: mouseEvent.x, y: mouseEvent.y }, //ev!.target as HTMLElement,
      items: [
        {
          key: 'edit',
          iconProps: {
            iconName: 'Edit',
          },
          text: t('common:menu.editSelected'),
          onClick: () => onEditAsset(asset),
        },
        {
          key: 'editContent',
          iconProps: {
            iconName: 'PageEdit',
          },
          text: t('common:menu.editContentSelected'),
          onClick: () =>  onEditAsset(asset),
          subMenuProps: {
            items: [
              {
                key: 'editWithTextEditor',
                text: t('common:menu.editWithTextEditor'),
                disabled: !isEditableUsingTextEditor(asset),
                iconProps: {
                  iconName: 'EditNote',
                },
                onClick: () =>  onEditAssetContent(asset, EditorType.Text),
              },
              {
                key: 'editWithTableEditor',
                text: t('common:menu.editWithTableEditor'),
                disabled: !isEditableUsingTableEditor(asset),
                iconProps: {
                  iconName: 'Table',
                },
                onClick: () =>  onEditAssetContent(asset, EditorType.Table),
              }
            ]
          },
        },
        {
          key: 'delete',
          iconProps: {
            iconName: 'Delete',
            style: {
              color: 'salmon'
            }
          },
          onClick: onRemoveAsset,
          text: t('common:menu.removeSelected'),

        }],
      onDismiss: () => {
        setContextMenu(undefined);
      }
    };

    if (index! > -1) {
      setContextMenu(contextualMenuProps);
    }

    return false;
  };

  return (
    <>
      <DetailsList
        items={assets}
        compact={false}
        columns={defaultColumns}
        selectionMode={SelectionMode.multiple}
        selection={selection}
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
        selectionPreservedOnEmptyClick={true}
        enterModalSelectionOnTouch={true}
        onItemContextMenu={_onItemContextMenu}
      />

      {contextMenu &&
      <ContextualMenu {...contextMenu} />
      }
    </>
  );
};

export default AssetList;
