import * as React from 'react';
import {ReactNode, useState} from 'react';
import {IStackTokens, MessageBar, MessageBarType, Pivot, PivotItem, PrimaryButton, Stack} from "@fluentui/react";
import {useTranslation} from "react-i18next";


export interface IWizardStep {
  title: string,
  children?: ReactNode
  onValidate?: () => boolean,
  validationErrorMessage?: string
}

export interface IWizard {
  defaultSelectedKey?: string,
  showTabIcons?: boolean,
  addNumbering?: boolean,
  children: IWizardStep[]
}

const tokens: IStackTokens = {
  childrenGap: 'm',
  padding: 'm'
};

const Wizard = (props: IWizard) => {
  const {
    defaultSelectedKey = '0',
    showTabIcons = false,
    addNumbering = false,
    children
  } = props;
  const {t} = useTranslation();

  const [selectedKey, setSelectedKey] = useState(defaultSelectedKey);
  const [stepIsValid, setStepIsValid] = useState(true);

  const getIcon = (value: boolean) => showTabIcons
    ? value ? "CheckMark" : "Cancel"
    : undefined;

  const getTitle = (step: IWizardStep, index: number) => addNumbering
    ? `${index+1}. ${step.title}`
    : step.title;

  return <div className={'wizard'}>
    <Pivot selectedKey={selectedKey}>
      { (children || []).map((step,index) =>
          <PivotItem
            headerText={getTitle(step, index)}
            key={index.toString()}
            itemIcon={getIcon(selectedKey > index.toString())}
          >
            <Stack tokens={tokens}>
              {step.children}

              {!stepIsValid &&
                <MessageBar messageBarType={MessageBarType.blocked}>
                  {step.validationErrorMessage ? step.validationErrorMessage : t('common:wizardDefaultStepValidationError') }
                </MessageBar>
              }

              <Stack horizontal tokens={tokens} horizontalAlign={'end'}>
                <PrimaryButton
                  disabled={selectedKey === '0'}
                  onClick={() => {
                    setSelectedKey((index-1).toString())
                  }}
                >
                  {t('common:wizardPreviousButtonLabel')}
                </PrimaryButton>

                <PrimaryButton
                  disabled={selectedKey === (children.length - 1).toString()}
                  onClick={() => {
                    let isValid = true;

                    if (step.onValidate)
                    {
                      isValid = step.onValidate();
                    }
                    setStepIsValid(isValid);

                    if (isValid)
                      setSelectedKey((index+1).toString())
                  }}
                >
                  {t('common:wizardNextButtonLabel')}
                </PrimaryButton>
              </Stack>
            </Stack>
          </PivotItem>
        )
      }
    </Pivot>
  </div>
};

export default Wizard;
