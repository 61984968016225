// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import React, {useEffect, useState} from "react";

import {
  CommandBar,
  Pivot,
  PivotItem,
  Stack,
  Separator, MessageBar, MessageBarType
} from "@fluentui/react";

/* Local */
import UserInformation from "./userInformation";
import ApplicationInformation from "./applicationInformation";
import { useKeycloak } from '@react-keycloak/web'
import {usePixonApi} from "../../components/api/api";
import { useTranslation  } from 'react-i18next';
import AuditTrail from "./auditTrail";
// ----------------------------------------------------------------------------

const DebugPageIndex = () => {
  const [selectedPivot, setSelectedPivotItem] = useState("userInfo");
  const {keycloak} = useKeycloak();
  const {get} = usePixonApi();
  const { t } = useTranslation();
  const [apiStatusCode, setApiResponseCode] = useState(0);
  const [showMessageBar, setShowMessageBar] = useState(false);

  const [syncingUser, setSyncingUser] = useState(false);

  useEffect(() => {
    async function synchronizeAuth0UserWithPixonDb() {
      try {
        const responseData = (await get('user/sync'))!.status;
        setApiResponseCode(responseData);
        setShowMessageBar(true);
      } catch (error) {
        console.error(error);
        setApiResponseCode(-1);
        setShowMessageBar(true);
      }
      setSyncingUser(false);
    }

    if (syncingUser) {
      synchronizeAuth0UserWithPixonDb();
    }
  }, [get, syncingUser]);

  const isTestEnvironment = window.API_BASE_URL === 'https://testapi.pixon.app/';
  const messageBarType = apiStatusCode === 204 ? MessageBarType.success : MessageBarType.error;

  const environmentUrls = isTestEnvironment
    ? [
      'https://testapi.pixon.app/healthchecks-ui',
      'https://portal.azure.com/#blade/Microsoft_Azure_Monitoring_Logs/LogsBlade/resourceId/%2Fsubscriptions%2F361db64e-19cc-4eda-873b-0342929f780b%2FresourceGroups%2Fpixon-test-rg%2Fproviders%2Fmicrosoft.insights%2Fcomponents%2Fpixon-test-ai/source/AIExtension.AspNetOverviewV3',
      'https://portal.azure.com/#@rduce.net/resource/subscriptions/361db64e-19cc-4eda-873b-0342929f780b/resourceGroups/pixon-test-rg/providers/microsoft.insights/components/pixon-test-ai/overview',
      'https://testapi.pixon.app/'
    ]
    : [
      'https://api.pixon.app/healthchecks-ui',
      'https://portal.azure.com/#blade/Microsoft_Azure_Monitoring_Logs/LogsBlade/resourceId/%2Fsubscriptions%2Fdfa1e878-e2ee-47b9-af16-d050349465d3%2FresourceGroups%2Fpixon-prod-rg%2Fproviders%2Fmicrosoft.insights%2Fcomponents%2Fpixon-prod-ai/source/AIExtension.AspNetOverviewV3',
      'https://portal.azure.com/#@rduce.net/resource/subscriptions/dfa1e878-e2ee-47b9-af16-d050349465d3/resourceGroups/pixon-prod-rg/providers/microsoft.insights/components/pixon-prod-ai/overview',
      'https://api.pixon.app/'
    ];

  const commandBarItems = (urls: string[]) => {
    const userItems = [
      {
        key: 'syncUser',
        name:  t('debug:commandbar.synchronizeuser'),
        cacheKey: 'syncUser',
        iconProps: {
          iconName: 'UserSync'
        },
        ariaLabel: t('debug:commandbar.synchronizeuser'),
        onClick: () => setSyncingUser(true)
      },
      {
        key: 'healthCheck',
        name: t('debug:commandbar.healthcheck'),
        cacheKey: 'syncUser', //
        iconProps: {
          iconName: 'Health'
        },
        ariaLabel: t('debug:commandbar.healthcheck'),
        href: urls[0],
        target: '_blank'
      },
      {
        key: 'graphqlApi',
        name: t('debug:commandbar.graphql'),
        cacheKey: 'graphqlApi',
        iconProps: {
          iconName: 'AzureAPIManagement'
        },
        ariaLabel: t('debug:commandbar.graphql'),
        href: urls[3],
        target: '_blank'
      }
    ];

    const adminItems = [
      {
        key: 'appInsightsLogs',
        name: t('debug:commandbar.logs'),
        cacheKey: 'appInsightsLogs',
        iconProps: {
          iconName: 'RawSource'
        },
        ariaLabel: t('debug:commandbar.logs'),
        href: urls[1],
        target: '_blank'
      },
      {
        key: 'appInsights',
        name: t('debug:commandbar.surveillance'),
        cacheKey: 'appInsights',
        iconProps: {
          iconName: 'AnalyticsView'
        },
        ariaLabel: t('debug:commandbar.surveillance'),
        href: urls[2],
        target: '_blank'
      }
    ];

    // Show admin items if user have more permissions
    return keycloak?.hasRealmRole('developer')
      ? userItems.concat(adminItems)
      : userItems;
  };

  const handleLinkClick = (item?: (PivotItem | undefined)) => {
    setSelectedPivotItem(item?.props.itemKey!);
  };

  return (
    <Stack styles={{
      root: {
        height: '100%',
        width: '100%',
      }
    }}>
      <Stack horizontal horizontalAlign="start">

        <Pivot linkFormat={"links"} linkSize={"normal"} onLinkClick={handleLinkClick}>
          <PivotItem headerText={t('debug:pivot.userinformation')} itemKey="userInfo"/>

          {keycloak?.hasRealmRole('developer') &&
            <PivotItem headerText={t('debug:pivot.appinformation')} itemKey="appInfo"/>
          }

          {keycloak?.hasRealmRole('developer') &&
            <PivotItem headerText={t('debug:pivot.auditTrail')} itemKey="auditTrail"/>
          }

        </Pivot>

        <Separator vertical/>

        <CommandBar  items={commandBarItems(environmentUrls)} />
      </Stack>

      <Stack.Item>
        &nbsp;
      </Stack.Item>

      {showMessageBar &&
        <>
          <MessageBar messageBarType={messageBarType} onDismiss={() => setShowMessageBar(false)} dismissButtonAriaLabel="Close">
            {t('debug:message.synchronizeduser')} {apiStatusCode}
          </MessageBar>
          <Stack.Item>
            &nbsp;
          </Stack.Item>
        </>
      }

      {selectedPivot === 'userInfo' &&
        <UserInformation />
      }

      {selectedPivot === 'appInfo' &&
        <ApplicationInformation />
      }

      {selectedPivot === 'auditTrail' &&
        <AuditTrail />
      }

    </Stack>
  )
};

export default DebugPageIndex;
