// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {
  Stack,
  Dropdown, IDropdownOption,
  TextField, DefaultButton
} from "@fluentui/react";
import {useTranslation} from "react-i18next";
import IdField from "../../../components/idField";
import {
  IPixonDataSourceConfiguration,
  IPixonOAuthConfiguration,
  PixonOAuthCredentialsDeliveryMethod,
  PixonOAuthGrantType
} from "pixon-component-core";
import {IDataSourceEditorProps, useDataSourceConfiguration} from "./dataSourceEditor";
import {DataSource} from "../../../graphql/types";

/* Local */

interface IDefaultOAuthEditorGroupProps extends IDataSourceEditorProps {
  dataSource: DataSource,
}

const grantTypeOptions =
Object.values(PixonOAuthGrantType)
.map<IDropdownOption>(o => ({
  key: o,
  text: o
}));

const credentialsOptions =
Object.values(PixonOAuthCredentialsDeliveryMethod)
.map<IDropdownOption>(o => ({
  key: o,
  text: o
}));

// ----------------------------------------------------------------------------
function DefaultOAuthEditorGroup<T extends IPixonDataSourceConfiguration>(props: React.PropsWithChildren<IDefaultOAuthEditorGroupProps>) {
  const {
    dataSource,
    onChange,
  } = props;

  const {t} = useTranslation();
  const [configuration, updateConfigurationProperty] = useDataSourceConfiguration<T>(onChange, dataSource);

  const onOauthChange = (partialUpdate: Partial<IPixonOAuthConfiguration>) => {
    updateConfigurationProperty({
      ...configuration,
          oAuth:{
            ...configuration?.oAuth,
            ...partialUpdate
          }
        }
    );
  };

  const openAuthWindow = () => {
    const w = 800;
    const h = 600;
    const x = window.outerWidth/2-(w/2);
    const y = window.outerHeight/2-(h/2);
    const o = configuration.oAuth;
    if(!o)
      return;
    const authenticationUrl =  `${o.authorizationUrl}?scope=${o.scope}&state=${o.state}&client_id=${o.clientId}&redirect_uri=${o.callbackUrl}&response_type=code`;

    window.open(authenticationUrl,'Authenticate',"resizable=no,toolbar=no,scrollbars=no,menubar=no,status=no,directories=n o,width=" + w + ",height=" + h + ",left="+ x +",top="+ y +"");
  }

  // Do whatever you want with the props.
  return <>
    <Dropdown
      label={t('common:oauthEditor.grantTypeLabel')}
      placeholder={t('common:oauthEditor.grantTypePlaceholder')}
      options={grantTypeOptions}
      selectedKey={configuration?.oAuth?.grantType}
      onChange={(e, option) => {
        onOauthChange({  grantType : option?.key! as PixonOAuthGrantType})
      }}
    />
    <TextField
      label={t('common:oauthEditor.authorizationUrlLabel')}
      placeholder={t('common:oauthEditor.authorizationUrlPlaceholder')}
      autoFocus
      defaultValue={configuration?.oAuth?.authorizationUrl}
      onChange={(e, v) => {
        onOauthChange({  authorizationUrl : v})
      }}/>
    <TextField
      label={t('common:oauthEditor.accessTokenUrlLabel')}
      placeholder={t('common:oauthEditor.accessTokenPlaceholder')}
      autoFocus
      defaultValue={configuration?.oAuth?.accessTokenUrl}
      onChange={(e, v) => {
        onOauthChange({ accessTokenUrl : v})
      }}/>
    <TextField
      label={t('common:oauthEditor.clientIdLabel')}
      placeholder={t('common:oauthEditor.clientIdPlaceholder')}
      autoFocus
      defaultValue={configuration?.oAuth?.clientId}
      onChange={(e, v) => {
        onOauthChange({clientId : v})
      }}/>
    <TextField
      label={t('common:oauthEditor.clientSecretLabel')}
      placeholder={t('common:oauthEditor.clientSecretPlaceholder')}
      autoFocus
      defaultValue={configuration?.oAuth?.clientSecret}
      onChange={(e, v) => {
        onOauthChange({clientSecret: v})
      }}/>
    <TextField
      label={t('common:oauthEditor.redirectUrlLabel')}
      placeholder={t('common:oauthEditor.redirectUrlPlaceholder')}
      autoFocus
      defaultValue={configuration?.oAuth?.callbackUrl}
      onChange={(e, v) => {
        onOauthChange({callbackUrl: v})
      }}/>
    <TextField
      label={t('common:oauthEditor.stateLabel')}
      placeholder={t('common:oauthEditor.stateUrlPlaceholder')}
      autoFocus
      defaultValue={configuration?.oAuth?.state}
      onChange={(e, v) => {
        onOauthChange({state: v})
      }}/>
    <Dropdown
      label={t('common:oauthEditor.credentialsLabel')}
      placeholder={t('common:oauthEditor.credentialsPlaceholder')}
      options={credentialsOptions}
      selectedKey={configuration?.oAuth?.deliveryMethod}
      onChange={(e, option) => {
        onOauthChange({ deliveryMethod : option?.key! as PixonOAuthCredentialsDeliveryMethod})
      }}
    />
    <TextField
      label={t('common:oauthEditor.audienceLabel')}
      placeholder={t('common:oauthEditor.audiencePlaceholder')}
      autoFocus
      defaultValue={configuration?.oAuth?.audience}
      onChange={(e, v) => {
        onOauthChange({audience: v})
      }}/>
    <TextField
      label={t('common:oauthEditor.resourceLabel')}
      placeholder={t('common:oauthEditor.resourcePlaceholder')}
      autoFocus
      defaultValue={configuration?.oAuth?.resource}
      onChange={(e, v) => {
        onOauthChange({resource: v})
      }}/>
    <TextField
      label={t('common:oauthEditor.scopeLabel')}
      placeholder={t('common:oauthEditor.scopePlaceholder')}
      autoFocus
      defaultValue={configuration?.oAuth?.scope ?? 'openid profile user'}
      onChange={(e, v) => {
        onOauthChange({scope: v})
      }}/>
    <Stack>
      <IdField
        label={t('common:oauthEditor.refreshTokenLabel')}
      />
      <IdField
        label={t('common:oauthEditor.accessTokenLabel')}
      />
      <DefaultButton onClick={() => openAuthWindow()} text={t('common:oauthEditor.fetchTokensButtonText')}/>
    </Stack>
  </>
}


export default DefaultOAuthEditorGroup;
