// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import React, { CSSProperties, forwardRef, RefForwardingComponent, useImperativeHandle, useMemo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from "react-dropzone";

import {
  Text,
} from "@fluentui/react";

/* Local */
import {
  Asset, AssetType,
  CreateOrganizationAssetsDocument,
  CreateOrganizationAssetsMutation,
  CreateOrganizationAssetsMutationVariables
} from "../graphql/types";
import { PixonApiContext } from "../components/api/api";
import { UploadAsset } from "./api/util";

// ----------------------------------------------------------------------------

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};


export interface UploadAssetHandles {
  open(): void;
}

interface IUploadAssetComponentProps {
  organizationId: string,
  acceptedMimeTypes?: string,
  showAcceptReject?: boolean,
  message?: string,
  acceptMessage?: string
  uploadFinishedCallback?: (asset: Asset, file: File) => void,
  display?: string
}

const UploadAssetComponent: RefForwardingComponent<UploadAssetHandles, IUploadAssetComponentProps> = (props, ref) => {
  const { t } = useTranslation();
  const {
    showAcceptReject = false,
    acceptedMimeTypes = 'image/jpeg, image/png, image/svg+xml, video/mp4, application/json, text/csv, .handlebars, .hbs',
    message = t('common:fileupload.message'),
    acceptMessage = t('common:fileupload.acceptedfiles'),
    organizationId,
    uploadFinishedCallback,
    display = 'flex'
  } = props;

  const context = useContext(PixonApiContext);

  const baseStyleMemo = useMemo(() => ({
    flex: 1,
    display: display,
    // see https://stackoverflow.com/questions/48087015/typescript-error-during-inline-fontweight-style-in-react
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  } as CSSProperties), [display]);

  const getAssetType = (file: any) => {
    if (file.type.startsWith('image')) {
      return AssetType.Image;
    }

    if (file.type.startsWith('video')) {
      return AssetType.Video;
    }
    if (file.name.endsWith('.handlebars') || file.name.endsWith('.hbs')) {
      return AssetType.HandlebarsTemplate;
    }

    return AssetType.Data;
  };

  const onDrop = useCallback(async files => {

    console.debug('creating assets...');
    // Create assets in db and retrieve write SAS tokens for blob storage
    var result = await context?.client.mutate<CreateOrganizationAssetsMutation, CreateOrganizationAssetsMutationVariables>({
      mutation: CreateOrganizationAssetsDocument,
      variables: {
        input: {
          id: organizationId,
          assets: files.map((file: any) => {
            console.debug(file);
            return {
              filename: file.name,
              size: file.size,
              assetType: getAssetType(file),
              mimeType: file.type
            }
          })
        }
      }
    });
    const assets = result?.data!.createOrganizationAssets!.assets as Array<Asset>;
    console.debug(assets);

    files.forEach(async (file: any) => {
      console.debug(file);
      const asset = assets.find((a: Asset | null) => a!.filename === file.name)!;

      await UploadAsset(asset, file, file.type);

      // Notify parent
      if (uploadFinishedCallback) {
        uploadFinishedCallback(asset, file);
      }

    });
  }, [context, organizationId, uploadFinishedCallback]);

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open } = useDropzone({
      onDrop: onDrop,
      accept: acceptedMimeTypes,
    });

  // See: https://stackoverflow.com/questions/37949981/call-child-method-from-parent
  useImperativeHandle(ref, () => ({
    open: () => {
      open();
    }
  }), [open]);

  const style = useMemo(() => ({
    ...baseStyleMemo,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragAccept,
    isDragReject,
    baseStyleMemo
  ]);

  const mapfile = (file: File) => <li key={file.name}>
    {file.name} - {file.size} bytes
  </li>

  const acceptedFilesItems = acceptedFiles.map(file => mapfile(file));

  const rejectedFilesItems = fileRejections.map(({ file }) => mapfile(file));

  return (
    <>
      <div {...getRootProps({ style })} >
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Drop the files here ...</p> :
            <>
              <p>{message}</p>
              <em>{acceptMessage}</em>
            </>
        }
      </div>

      {showAcceptReject &&
        <aside>
          <Text>Accepted files</Text>
          <ul>
            {acceptedFilesItems}
          </ul>
          <Text>Rejected files</Text>
          <ul>
            {rejectedFilesItems}
          </ul>
        </aside>
      }
    </>
  );
};

export default forwardRef(UploadAssetComponent);
