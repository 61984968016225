// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useTranslation} from "react-i18next";

/* Local */
import {IComponentPropertyEditorProps} from "./propertyEditor";
import DefaultComponentEditorGroup from "./defaultComponentEditorGroup";
import { DataSourceTypeEnum} from "../../../graphql/types";

// ----------------------------------------------------------------------------


const TransitTimesComponentEditorGroup = (props: IComponentPropertyEditorProps) => {
  const {t} = useTranslation();

  return <>

    <DefaultComponentEditorGroup
      showDataSourceEditor={true}
      dataSourceTypes={[DataSourceTypeEnum.TransitStop]}
      showCssEditor={true}
      showTemplateAssetEditor={true}
      heading={t('component:transitTimesComponentEditorGroup.heading')}
      {...props}>

    </DefaultComponentEditorGroup>

  </>
};

export default TransitTimesComponentEditorGroup;
