// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useEffect, useState} from "react";
import {
  IStackTokens, ITag,
  Label,
  Stack,
  TagPicker,
  TextField
} from "@fluentui/react";
import {types} from "mime-types";
import {useTranslation} from "react-i18next";
import _ from "lodash";

/* Local */
import {
  AssetQueryInput, AssetType, Organization,
} from "../../graphql/types";
import OrganizationPicker from "../../components/organizationPicker";

// ----------------------------------------------------------------------------
const stackTokens: IStackTokens = {
  childrenGap: 'm'
};

const AssetTypeTags: ITag[] =
  Object.entries(AssetType)
    .map( ([k,v]) => ({key: v, name: k}));

const MimeTypeTags: ITag[] =
  _.uniqWith(Object.values(types), _.isEqual)
    .map( (v) => ({key: v, name: v}));

export interface IAssetsFilterProps {
  onFilterUpdated: (filter?: AssetQueryInput) => void
}

const AssetsFilter = (props: IAssetsFilterProps) => {
  const {t} = useTranslation();
  const {onFilterUpdated} = props;

  // State
  const [filter, setFilter] = useState<AssetQueryInput>( );

  useEffect(() => {
    onFilterUpdated(filter);
  }, [filter, onFilterUpdated]);

  // Actions
  const onResolveSuggestions = (filterText: string, items: ITag[], selectedItems?: ITag[]) => {
    return filterText ? items.filter(tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0) : []
  };

  const onItemSelected = (item?:ITag) => {
    // Prevent duplicates here if wanted
    return item || null;
  };

  return <Stack tokens={stackTokens} horizontal >

    <TextField
      label={t('assets:assetsFilter.displayNameLabel')}
      onChange={(event, newValue) =>
        setFilter({
          ...filter,
          displayNames: (newValue && newValue.length > 0) ? [ newValue ] : undefined
        })
      }
    />

    <TextField
      label={t('assets:assetsFilter.filenameLabel')}
      onChange={(event, newValue) =>
        setFilter({
          ...filter,
          filenames: (newValue && newValue.length > 0) ? [ newValue ] : undefined
        })
      }
    />


    <div>
      <Label htmlFor='owners'>{t('assets:assetsFilter.ownersLabel')}</Label>
      <OrganizationPicker onChange={ (items:Organization[]) => {
        setFilter({
          ...filter,
          ownedBy: items && items.length > 0 ? items.map(i => i.id!) : undefined
        })}} />
    </div>

    <div>
      <Label htmlFor='assetTypes'>{t('assets:assetsFilter.assetTypeLabel')}</Label>
      <TagPicker
        onResolveSuggestions={ (ft, s) => onResolveSuggestions(ft, AssetTypeTags, s) }
        inputProps={{ id: 'assetTypes'}}
        onItemSelected={onItemSelected}
        defaultSelectedItems={filter && filter.assetTypes ? AssetTypeTags.filter(i => filter.assetTypes!.indexOf(i.key as AssetType) > -1) : undefined}
        onChange={(items) => {
          setFilter({
            ...filter,
            assetTypes: items ? items.map(i => i.key as AssetType) : undefined
          })
        }}
      />
    </div>

    <div>
      <Label htmlFor='mimeTypes'>{t('assets:assetsFilter.mimeTypeLabel')}</Label>
      <TagPicker
        onResolveSuggestions={ (ft, s) => onResolveSuggestions(ft, MimeTypeTags, s) }
        inputProps={{ id: 'mimeTypes'}}
        onItemSelected={onItemSelected}
        defaultSelectedItems={filter && filter.mimeTypes ? MimeTypeTags.filter(i => filter.mimeTypes!.indexOf(i.name) > -1) : undefined}
        onChange={(items) => {
          setFilter({
            ...filter,
            mimeTypes: items ? items.map(i => i.name) : undefined
          })
        }}
      />
    </div>

  </Stack>
};

export default AssetsFilter;
