import React, { createContext, useContext, ReactNode } from 'react';
import { StripeProvider, injectStripe, Elements } from 'react-stripe-elements';

const StripeContext = createContext({ stripe: {} as stripe.Stripe });

interface StripeWrapped {
  stripe: stripe.Stripe;
}

interface StripeHookProviderInnerWrapperProps {
  stripe?: stripe.Stripe;
  stripeData: StripeWrapped;
  children: ReactNode;
}

interface StripeHookProviderProps {
  apiKey: string;
  children: ReactNode;
}

export const StripeHookProvider: React.FC<StripeHookProviderProps> = (props) => {
  const stripeData = {
    stripe: {} as stripe.Stripe,
  };
  return (
    <StripeProvider apiKey={props.apiKey} >
      <Elements locale={'sv'}>
        <StripeInjectedWrapper stripeData={stripeData}>{props.children}</StripeInjectedWrapper>
      </Elements>
    </StripeProvider>
  );
};

const StripeHookProviderInnerWrapper = (props: StripeHookProviderInnerWrapperProps) => {
  const updatedStripeData = { stripe: { ...props.stripeData.stripe, ...props.stripe } };

  return <StripeContext.Provider value={updatedStripeData}>{props.children}</StripeContext.Provider>;
};

const StripeInjectedWrapper = injectStripe(StripeHookProviderInnerWrapper);

export const useStripe = () => {
  const stripeData = useContext(StripeContext);

  return stripeData || { stripe: {} as stripe.Stripe };
};
