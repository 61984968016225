import * as React from 'react';

import {
  Dropdown, IDropdownOption
} from "@fluentui/react";

import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getOrganizations, getOrganizationsAsyncStatus} from "../store/organization/reducers";
import {useEffect, useState} from "react";
import {AsyncActionStatus} from "../store/asyncAction/types";
import {loadOrganizationsAction} from "../store/organization/actions";
import {Organization} from "../graphql/types";

export interface IOrganizationsDropDownProps {
  onChange: (selected:Organization) => void;
  required?: boolean
  selectedOrganizationId?: string,
  selectFirstIfAvailable?: boolean
}

const OrganizationsDropDown = (props: IOrganizationsDropDownProps) => {
  const {
    onChange,
    selectedOrganizationId,
    selectFirstIfAvailable = false
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organizations = useSelector(getOrganizations);
  const loadStatus = useSelector(getOrganizationsAsyncStatus);
  const [selected, setSelected] = useState<IDropdownOption>();

  useEffect(() => {
    if (loadStatus === AsyncActionStatus.UNSTARTED)
    {
      dispatch(loadOrganizationsAction());
    }
  });

   // Populate dropdown
  const options = organizations.map<IDropdownOption>((o,i) => ({
    key: o.id!,
    text: o.name
  }));

  useEffect(() => {
    if (organizations && organizations.length > 0 && !selected)
    {
      if (selectedOrganizationId)
      {
        const found = organizations.filter(o => o.id === selectedOrganizationId)[0];
        setSelected({
          key: found.id!,
          text: found.name
        });
        onChange(found);
      } else if (selectFirstIfAvailable)  {
        const first = organizations[0];
        setSelected({
          key: first.id!,
          text: first.name
        });
        onChange(first);
      }
    }
  }, [organizations, onChange, selected, selectedOrganizationId, selectFirstIfAvailable]);

  return <Dropdown
    required={props.required}
    label={t('common:organizationsDropdown.label')}
    placeholder={t('common:organizationsDropdown.placeholder')}
    options={options}
    selectedKey={selected ? selected.key : undefined}
    onChange={(e, option) => {
      const found = organizations.filter(o => o.id === option?.key)[0];
      setSelected({
        key: found.id!,
        text: found.name
      });
      onChange(found)
    }}
  />

};

export default OrganizationsDropDown;
