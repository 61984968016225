// NPM
import React, {useEffect, useRef, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {
  CommandBar,
  DefaultButton,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Text
} from "@fluentui/react";
// Local
import {Asset, AssetType, useAssetQuery, useNotifyAssetChangedMutation,} from "../../graphql/types";
import MonacoEditor from "react-monaco-editor";
import ReactDataGrid from 'react-data-grid';
import {getAssetUrl, UploadAsset} from "../../components/api/util";
import Loading from "../../components/layout/loading";

export enum EditorType {
  Text = 1,
  Table
}

export interface TenantRegistryItem {
  entrance: string;
  floor: number;
  name: string;
  apartment_number: string,
  national_land_survey_number: string
  resident_type: "Person" | "Företag";
}

interface EditTextAssetPanelProps {
  isOpen: boolean;
  onDismiss: () => void;
  onCancel: () => void;
  selectedId: string,
  editorType?: EditorType
}

const defaultColumnProperties = {
  editable: true,
};

const sample = [
  {
    "entrance": "A",
    "floor": 1,
    "name": "Mr Smith",
    "apartment_number": "1",
    "national_land_survey_number": "A1100",
    "resident_type": "Person"
  },
  {
    "entrance": "B",
    "floor": 1,
    "name": "Ms Smith",
    "apartment_number": "2",
    "national_land_survey_number": "B1100",
    "resident_type": "Person"
  }
] as TenantRegistryItem[];


function getLanguage(assetType?: AssetType | null) {
  if (!assetType)
    return "json";

  switch (assetType) {
    case AssetType.HandlebarsTemplate:
      return "handlebars";
    case AssetType.ResidentRegistry:
    default:
      return "json";
  }
}

const EditTextAssetPanel = (props: EditTextAssetPanelProps) => {
  const {
    isOpen,
    selectedId,
    onDismiss,
    onCancel,
    editorType
  } = props;
  const {t} = useTranslation();
  const [showValidationError, setShowValidationError] = useState<boolean>();
  const [content, setContent] = useState<any>();
  const [selected, setSelected] = useState<Asset>();
  const [editor, setEditor] = useState(editorType);

  const [selectedCoordinates, setSelectedCoordinates] = useState<any>();
  const grid = useRef() as React.MutableRefObject<ReactDataGrid<{}>>;

  // Queries & mutations
  const [notifyAssetChangedMutation] = useNotifyAssetChangedMutation();
  const {loading, data, error} = useAssetQuery({
    variables: {
      input: selectedId
    }
  });

  useEffect(() => {
    if (!data || !data.asset)
      return;

    const fetchedAsset = data.asset as Asset;
    setSelected(fetchedAsset);

    if (!editor) {
      setEditor(fetchedAsset.assetType === AssetType.ResidentRegistry ? EditorType.Table : EditorType.Text);
    }

    const fetchData = async () => {
      const result = await
        fetch(getAssetUrl(fetchedAsset))
          .then(r => r.text());

      if (editor === EditorType.Text) {
        setContent(result);
      } else {
        const casted = JSON.parse(result) as TenantRegistryItem[];
        setContent(casted);
      }
    };

    if (fetchedAsset) {
      fetchData();
    }
  }, [data, editor]);

  if (loading) {
    return <Loading/>
  }

  if (error) {
    return <MessageBar messageBarType={MessageBarType.error}>{t('assets:assetList.loadErrorMessage')}</MessageBar>
  }

  const columns = [
    {
      key: 'entrance',
      name: t('dataSources:editJsonDataSourceComponent.entranceColumnName'),
      width: 80
    },
    {
      key: 'floor',
      name: t('dataSources:editJsonDataSourceComponent.floorColumnName'),
      width: 120
    },
    {
      key: 'name',
      name: t('dataSources:editJsonDataSourceComponent.sortColumnName'),
    },
    {
      key: 'apartment_number',
      name: t('dataSources:editJsonDataSourceComponent.sortColumnApartmentNumber'),
      width: 140
    },
    {
      key: 'national_land_survey_number',
      name: t('dataSources:editJsonDataSourceComponent.sortColumnLandSurveyNumber'),
      width: 140
    },
    {
      key: 'resident_type',
      name: t('dataSources:editJsonDataSourceComponent.residentTypeColumnName'),
      width: 120
    },
  ].map(c => ({...c, ...defaultColumnProperties}));

  const saveChanges = async () => {
    if (!selected)
      return;

    const arrayData = editorType === EditorType.Text
      ? new TextEncoder().encode(content)
      : new TextEncoder().encode(JSON.stringify(content, null, 2));

    await UploadAsset(selected, arrayData, selected.mimeType);

    // Notify all devices that use the asset of the change
    await notifyAssetChangedMutation({
      variables: {
        input: selected.id!
      }
    });

    onDismiss();
  };

  const deleteRow = (rowIdx:number) => {
    const nextRows = [...content];
    nextRows.splice(rowIdx, 1);
    setContent(nextRows);
  };

  const insertRow = (rowIdx:number) =>  {
    if (!content)
      return;

    const newRow = {
      entrance: '',
      floor: 1,
      name: '',
      apartment_number: '',
      national_land_survey_number: 'A1100',
      resident_type: 'Person'
    } as TenantRegistryItem;
    const nextRows = [...content];
    nextRows.splice(rowIdx, 0, newRow);
    setContent(nextRows);
  };

  const getItems = () => {
    return [
      {
        key: 'add',
        name: t('common:menu.addNew'),
        iconProps: {
          iconName: 'Add'
        },
        onClick: () => {
          if(selectedCoordinates) {
            insertRow(selectedCoordinates.rowIdx+1);
          } else {
            insertRow(0);
          }
        }
      },
      {
        key: 'delete',
        name: t('common:menu.removeSelected'),
        iconProps: {
          iconName: 'Delete',
          style: {
            color: 'salmon'
          }
        },
        onClick: () => {
          if(selectedCoordinates) {
            deleteRow(selectedCoordinates.rowIdx);
          }
        }
      },
    ];
  };

  const validate = () => {
    if (selected && editor === EditorType.Text && selected.assetType === AssetType.ResidentRegistry)
    {
      try {
        const parsed = JSON.parse(content) as TenantRegistryItem[];
        return parsed && parsed.length > -1;
      } catch {
        return false
      }
    }

    return true;
  };

  return (
    <Panel
      type={PanelType.large}
      headerText={t('assets:editTextAssetPanel.headerText')}
      closeButtonAriaLabel={t('common:button.close')}
      isOpen={isOpen}
      onDismiss={onDismiss}
      layerProps={
        {eventBubblingEnabled: true}
      }
      isBlocking={false}
      styles={{

        root: {
          height: '100%',
          width: '100%',
          minHeight: '500px',
        },

        scrollableContent: {
          height: '100%',
          width: '100%',
          minHeight: '500px',
        },

        content: {
          height: '100%',
          width: '100%',
          minHeight: '500px',
          boxSizing: 'border-box'
        }
      }}
    >
      <Stack tokens={{childrenGap: 10}} styles={{
        root: {
          height: '100%',
          width: '100%',
          minHeight: '500px',
        }
      }}>
        {content && editor === EditorType.Text && typeof content === 'string' &&

        <MonacoEditor
            width="100%"
            height={showValidationError ? 'calc(100% - 400px)' : '100%'}
            language={getLanguage(data?.asset?.assetType)}
            theme="vs-light"
            value={content}
            onChange={value => setContent(value)}
        />

        }

        {content && editor === EditorType.Table &&
        <>
            <CommandBar items={getItems()}/>
            <Text>{t('assets:editTextAssetPanel.tableEditorDescription')}</Text>
            <ReactDataGrid
                ref={grid}
                columns={columns}
                rowGetter={i => content[i]}
                rowsCount={content.length}
                enableRowSelect={undefined}
                rowScrollTimeout={undefined}
                enableCellSelect={true}
                onCellSelected={c => setSelectedCoordinates(c)}
                onGridRowsUpdated={({fromRow, toRow, updated}) => {
                  const rows = content.slice();
                  for (let i = fromRow; i <= toRow; i++) {
                    rows[i] = {...rows[i], ...updated};
                  }
                  setContent(rows);
                }}
            />
        </>
        }

        {showValidationError &&
        <MessageBar messageBarType={MessageBarType.warning}>
            <Trans i18nKey="assets:editTextAssetPanel.textEditorValidationError" >
                Invalid tenant registry data, the data must be valid json and be an array of Tenant Registry Items, example:
                <pre>
                  {JSON.stringify(sample, null, 2) }
                </pre>
            </Trans>

        </MessageBar>
        }

        <Stack horizontal tokens={{childrenGap: 10}} horizontalAlign="end">
          <DefaultButton onClick={() => {
            // Close
            onCancel();
          }}>
            {t('common:button.cancel')}
          </DefaultButton>
          <PrimaryButton onClick={() => {
            if (!validate())
            {
              setShowValidationError(true);
            } else {
              saveChanges();
            }
          }}>
            {t('common:button.update')}
          </PrimaryButton>
        </Stack>
      </Stack>
    </Panel>
  )
};

export default EditTextAssetPanel;
