// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";

/* Local */
import {
  AuditEntry, useAuditEntryQuery
} from "../../graphql/types";
import Loading from "../../components/layout/loading";
import {useEffect} from "react";
import {MonacoDiffEditor} from "react-monaco-editor";

// ----------------------------------------------------------------------------

export interface IAuditEntryDiffProps {
  entry?: AuditEntry,
}

const AuditEntryDiff = (props: IAuditEntryDiffProps) => {
  const {entry} = props;

  const {loading, data, error, refetch} = useAuditEntryQuery({
    variables: {
      input: entry ?  entry.auditEntryId.toString() : "0"
    }
  });

  useEffect(() => {
    refetch();
  }, [entry, refetch]);

  if (loading) {
    return <Loading/>
  }

  let oldValues = '';
  let newValues = '';

  if (!error) {
    const auditEntry = data!.auditEntry as AuditEntry;

    auditEntry.properties!.forEach((p:any) => {
      oldValues += p.propertyName + ': ' +  p.oldValue + '\n';
      newValues += p.propertyName + ': ' +  p.newValue + '\n';
    });

    newValues = newValues.trim();
    oldValues = oldValues.trim();
  }

  return (
    <>
      <MonacoDiffEditor
        width="100%"
        height="100%"
        language="text"
        theme="vs-light"
        original={oldValues}
        value={newValues}
      />
    </>
  );
};

export default AuditEntryDiff;
