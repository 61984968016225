// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import { useCallback } from 'react'
import logo from "../../assets/img/logo-text.svg";

// ----------------------------------------------------------------------------

// Layout sections
import { DefaultButton, Image, Link } from "@fluentui/react";
import { RouteComponentProps } from "react-router";
import { withRouter, Redirect, useLocation } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web'

interface LandingPageIndexProps extends RouteComponentProps {
}

// See example at: https://github.com/react-keycloak/react-keycloak-examples/blob/master/examples/react-router/src/pages/Login.tsx

// Images
const LandingPageIndex = (props: LandingPageIndexProps) => {

  const location = useLocation<{ [key: string]: unknown }>()
  const currentLocationState = location.state || {
    from: { pathname: '/home' },
  }

  const { keycloak } = useKeycloak()

  const login = useCallback(() => {
    keycloak?.login()
  }, [keycloak])

  if (keycloak?.authenticated)
    return <Redirect to={currentLocationState?.from as string} />

  return <div className='center'>
    <Image src={logo} />
    <div style={{
      textAlign: "center",
      padding: "2em"
    }}>
      <DefaultButton id='loginButton' text='Logga in/registrera' onClick={login} />
    </div>
    <div style={{
      textAlign: "center",
      padding: "2em"
    }}>
      <Link href="https://rduce.net/legal/privacy">Privacy policy</Link> | <Link href="https://rduce.net/legal/terms">Terms of use</Link>
    </div>
  </div>
};

export default withRouter(LandingPageIndex);
