// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";

/* Local */
import {IComponentPropertyEditorProps, useComponentConfiguration} from "./propertyEditor";
import DefaultComponentEditorGroup from "./defaultComponentEditorGroup";
import { DataSourceTypeEnum} from "../../../graphql/types";
import {useTranslation} from "react-i18next";
import {Slider, TextField, Link} from "@fluentui/react";

// ----------------------------------------------------------------------------
interface IDeviceEventActionComponentConfiguration {
  eventReceivedShowSelector?: string
  eventReceivedHideSelector?: string

  timeoutShowSelector?: string
  timeoutHideSelector?: string

  timeoutInSeconds?: number
}

const DeviceEventActionComponentEditorGroup = (props: IComponentPropertyEditorProps) => {
  const {pageComponent, onChange} = props;
  const {t} = useTranslation();
  const component = pageComponent.component!;
  const [configuration, updateConfigurationProperty] = useComponentConfiguration<IDeviceEventActionComponentConfiguration>(onChange, component);

  return <>

    <DefaultComponentEditorGroup
      showDataSourceEditor={true}
      dataSourceTypes={[DataSourceTypeEnum.DeviceEvent]}
      showCssEditor={false}
      showTemplateAssetEditor={false}
      heading={t('component:deviceEventActionComponentEditorGroup.heading')}
      {...props}>

      <Slider
        label={t('component:deviceEventActionComponentEditorGroup.timeoutInSecondsLabel')}
        min={1}
        max={60}
        step={1}
        defaultValue={configuration ? configuration.timeoutInSeconds : 5}
        showValue={true}
        valueFormat={(value: number) => `${value}`}
        onChange={(value: number) => updateConfigurationProperty({timeoutInSeconds: value})}
      />

      <TextField
        label={t('component:deviceEventActionComponentEditorGroup.eventReceivedShowSelectorLabel')}
        placeholder={t('component:deviceEventActionComponentEditorGroup.eventReceivedShowSelectorPlaceholder')}
        value={configuration ? configuration.eventReceivedShowSelector : '.items-to-show-on-receive'}
        onChange={(e, v) => updateConfigurationProperty({
          eventReceivedShowSelector: v
        })}
      />

      <TextField
        label={t('component:deviceEventActionComponentEditorGroup.eventReceivedHideSelectorLabel')}
        placeholder={t('component:deviceEventActionComponentEditorGroup.eventReceivedHideSelectorPlaceholder')}
        value={configuration ? configuration.eventReceivedHideSelector : '.items-to-hide-on-receive'}
        onChange={(e, v) => updateConfigurationProperty({
          eventReceivedHideSelector: v
        })}
      />

      <TextField
        label={t('component:deviceEventActionComponentEditorGroup.timeoutShowSelectorLabel')}
        placeholder={t('component:deviceEventActionComponentEditorGroup.timeoutShowSelectorPlaceholder')}
        value={configuration ? configuration.timeoutShowSelector : '.items-to-show-on-timeout'}
        onChange={(e, v) => updateConfigurationProperty({
          timeoutShowSelector: v
        })}
      />

      <TextField
        label={t('component:deviceEventActionComponentEditorGroup.timeoutHideSelectorLabel')}
        placeholder={t('component:deviceEventActionComponentEditorGroup.timeoutHideSelectorPlaceholder')}
        value={configuration ? configuration.timeoutHideSelector : '.items-to-hide-on-timeout'}
        onChange={(e, v) => updateConfigurationProperty({
          timeoutHideSelector: v
        })}
      />

      <Link href='https://www.w3schools.com/cssref/css_selectors.asp' target='_blank'>
        {t('component:deviceEventActionComponentEditorGroup.selectorsReadMore')}
      </Link>

      <Link href='https://handlebarsjs.com/guide/' target='_blank'>
        {t('component:deviceEventActionComponentEditorGroup.templatesReadMore')}
      </Link>


    </DefaultComponentEditorGroup>

  </>
};

export default DeviceEventActionComponentEditorGroup;
