import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {Route, RouteProps} from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web'
import { KeycloakLogoutOptions } from "keycloak-js";

const PrivateRoute = ( routeProps: RouteProps ) => {
  const { keycloak } = useKeycloak();
  const isAuthenticated = keycloak?.authenticated ?? false;

  useEffect(() => {
    const fn = async () => {
      if (!isAuthenticated) {
        let params = {
          redirectUri: window.location.origin,
          //(appState: { targetUrl: routeProps.path }
        } as KeycloakLogoutOptions;

        await keycloak?.login(params);
      }
    };
    fn();
  }, [keycloak, isAuthenticated, routeProps.path]);

  if (!keycloak?.authenticated)
  {
    return null;
  }

  return <Route {...routeProps} />
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired
};

export default PrivateRoute;
