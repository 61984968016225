// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from "react";
import {useTranslation} from 'react-i18next';
import {
  Selection,
  DetailsList,
  IColumn,
  DetailsListLayoutMode,
  SelectionMode,
  MessageBar,
  MessageBarType, IContextualMenuProps, ContextualMenu
} from "@fluentui/react";

/* Local */
import {
  AuditEntriesQueryVariables,
  AuditEntry, useAuditEntriesQuery
} from "../../graphql/types";
import Loading from "../../components/layout/loading";
import {useEffect, useState} from "react";

// ----------------------------------------------------------------------------

export interface IAuditTrailListProps {
  lastUpdated: Date,
  filter?: AuditEntriesQueryVariables,
  onSelectEntry: (entry: AuditEntry) => void
}

const AuditTrailList = (props: IAuditTrailListProps) => {
  const {onSelectEntry, filter} = props;
  const {loading, data, error, refetch} = useAuditEntriesQuery({
    variables: filter
  });
  const {t} = useTranslation();
  const [contextMenu, setContextMenu] = useState<IContextualMenuProps>();
  const [selection] = useState<Selection>( new Selection({
    onSelectionChanged: () => {
      onSelectEntry(selection.getSelection()[0] as AuditEntry);
    }
  }));

  useEffect(() => {
    refetch();
  }, [filter, props.lastUpdated, refetch]);

  const defaultColumns: IColumn[] = [
    {
      key: 'auditEntryId',
      name: t('audit:fields.auditEntryId'),
      fieldName: 'auditEntryId',
      minWidth: 60,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      data: 'number',
      isPadded: true
    },
    {
      key: 'entitySetName',
      name: t('audit:fields.entitySetName'),
      fieldName: 'entitySetName',
      minWidth: 210,
      maxWidth: 350,
      isResizable: true,
      data: 'string',
      isPadded: true
    },
    {
      key: 'entityTypeName',
      name: t('audit:fields.entityTypeName'),
      fieldName: 'entityTypeName',
      minWidth: 210,
      maxWidth: 350,
      isResizable: true,
      data: 'string',
      isPadded: true
    },
    {
      key: 'stateName',
      name: t('audit:fields.stateName'),
      fieldName: 'stateName',
      minWidth: 210,
      maxWidth: 350,
      isResizable: true,
      data: 'string',
      isPadded: true
    },
    {
      key: 'createdBy',
      name: t('audit:fields.createdBy'),
      fieldName: 'createdBy',
      minWidth: 210,
      maxWidth: 350,
      isResizable: true,
      data: 'string',
      isPadded: true
    },
    {
      key: 'createdDate',
      name: t('audit:fields.createdDate'),
      fieldName: 'createdDate',
      minWidth: 210,
      maxWidth: 350,
      isResizable: true,
      data: 'string',
      isPadded: true
    }
  ];

  if (loading) {
    return <Loading/>
  }

  if (error) {
    return <MessageBar messageBarType={MessageBarType.error}>{t('audit:auditTrailList.loadErrorMessage')}</MessageBar>
  }

  const  _onItemContextMenu = (item: any, index?: number, ev?: Event): boolean => {
    const mouseEvent = ev! as MouseEvent;
    const selectedItem = item as AuditEntry;

    const contextualMenuProps: IContextualMenuProps = {
      target: { x: mouseEvent.x, y: mouseEvent.y },
      items: [
        {
          key: 'showSelected',
          text: t('common:menu.showSelected'),
          iconProps: {
            iconName: 'Edit'
          },
          onClick: () => {
            onSelectEntry(selectedItem)
          }
        }],
      onDismiss: () => {
        setContextMenu(undefined);
      }
    };

    if (index! > -1) {
      setContextMenu(contextualMenuProps);
    }

    return false;
  };

  const entries = data?.auditEntries as AuditEntry[];

  return (
    <>
      <DetailsList
        items={entries}
        compact={false}
        columns={defaultColumns}
        selectionMode={SelectionMode.single}
        selection={selection}
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
        selectionPreservedOnEmptyClick={true}
        enterModalSelectionOnTouch={true}
        onItemContextMenu={_onItemContextMenu}
        styles={{
          root: {
            height: 400
          }
        }}

      />
      {contextMenu &&
      <ContextualMenu {...contextMenu} />
      }
    </>
  );
};

export default AuditTrailList;
