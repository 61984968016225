import {DataSource} from "../../../graphql/types";
import {Dispatch, SetStateAction, useEffect, useState} from "react";

export interface IDataSourceEditorProps {
  dataSource: DataSource,
  onChange: (partiallyUpdatedDataSource: Partial<DataSource>) => void
}

type PartialUpdateAction<T> = (update: Partial<T>) => void;
type PartialDataSourceUpdateAction = PartialUpdateAction<DataSource>;

export function useDataSourceConfiguration<T>(onChange: PartialDataSourceUpdateAction, dataSource?: DataSource | null )
  : [T, PartialUpdateAction<T>, Dispatch<SetStateAction<T>>] {
  const [configuration, setConfiguration] = useState<T>(
    dataSource && dataSource.configuration ? JSON.parse(dataSource.configuration) : undefined);

  useEffect(() => {
    if (!configuration)
      return;

    const serialized = JSON.stringify(configuration, null, 2);
    onChange({
      configuration: serialized
    });
  }, [onChange, configuration]);

  const updateConfigurationProperty = (partialUpdate: Partial<T>) => {
    setConfiguration((prevState:T) => {
      return {
        ...prevState,
        ...partialUpdate
      }
    });
  };

  return [configuration, updateConfigurationProperty, setConfiguration];
}
